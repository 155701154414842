// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tds-img-div {
    display: flex;
    gap: 5px;
}

.cryp-icon {
    width: 24px;
    height: 24px;
}

.tds-blue {
    color: blue;
}

.tds-red {
    color: red;
}

.tds-green {
    color: green;
}`, "",{"version":3,"sources":["webpack://./src/Componets/Dispute_Profile/TdsProfile/TdsProfile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ;AACZ;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".tds-img-div {\r\n    display: flex;\r\n    gap: 5px;\r\n}\r\n\r\n.cryp-icon {\r\n    width: 24px;\r\n    height: 24px;\r\n}\r\n\r\n.tds-blue {\r\n    color: blue;\r\n}\r\n\r\n.tds-red {\r\n    color: red;\r\n}\r\n\r\n.tds-green {\r\n    color: green;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
