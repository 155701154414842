import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Assets/All-Css/Purchase/Purchas.css';

import Reciptimg from '../../Assets/Login-img/Recharge-recipt.png';

import Acceptimg from '../../Assets/Login-img/Accept-pop.png'
import Rejectsub from '../../Assets/Login-img/Reject-submit.png'
import { getAuthToken, getTokenDuration } from '../../util/Auth';
import TimeoutPop from '../../Componets/TimeoutPop/TimeoutPop';




const Purchase = () => {
const navigate = useNavigate();

  // TOKEN EXPIRATION  
  const [timeoutPop, setTimeoutPop] = useState(false); 
  const token = getAuthToken();
  useEffect(() => {
    if(!token) { 
      navigate('/loginform');
      return; 
    }
    if (token === 'EXPIRED') {
      navigate('/loginform');
      return; 
    }
    const tokenDuration = getTokenDuration(); 
    setTimeout(() => {
      navigate('/loginform'); 
    }, tokenDuration);     // 1*60*60*1000   
  }, [token]);  

  useEffect(() => {
    const tokenDuration = getTokenDuration(); 
    const timer = setTimeout(() => {
      setTimeoutPop(true);
    }, (tokenDuration - 15*60*1000));
    return () => clearTimeout(timer);
  }, []);
  // TOKEN EXPIRATION END

 
   // table popups buttons
   const [isReceiptPopupOpen, setReceiptPopupOpen] = useState(false);
   const [isPurchasePopupOpen, setPurchasePopupOpen] = useState(false);

  // popup buttons
  const [isAcceptPopupOpen, setAcceptPopupOpen] = useState(false);
  const [isAcceptConfirmPopupOpen, setAcceptConfirmPopupOpen] = useState(false);

  const [isRejectPopupOpen, setRejectPopupOpen] = useState(false);
  const [isRejectSubmitPopupOpen, setRejectSubmitPopupOpen] = useState(false);

 

  // enable the button only when the checkbox is checked 
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);




  // receipt close
  const closeReceiptPopup = () => {
    setReceiptPopupOpen(false);
  };

  // Purchase close
  const closePurchasePopup = () => {
    setPurchasePopupOpen(false);
  };


  
  // Accept close
  const closeAcceptPopup = () => {
    setAcceptPopupOpen(false);
  };

    // Accept close
    const closeAcceptConfirmPopup = () => {
      setAcceptConfirmPopupOpen(false);
    };
  
  // Rejection close
  const closeRejectionPopup = () => {
    setRejectPopupOpen(false);
  };

    // Rejection close
    const closeRejectSubmitPopup = () => {
      setRejectSubmitPopupOpen(false);
    };









  return (

    <section className="Purchase-Management-container">

  {/* @@@@@@@@@@@@@@@@@@@@_____top Heading____@@@@@@@@@@@@@@@@@@@@@ */}

      <div className="Purchase-mange-top-heading">
        <h1><i className='bx bx-link'></i>Purchase Requests</h1>
      </div>


  {/* @@@@@@@@@@@@@@@@@@@@_____top filler search, back btn, date____@@@@@@@@@@@@@@@@@@@@@ */}

   {/* Back Button */}
      <div className="Purchase-mange-Fillter-bar">


      <a className="Purchase-button" href="#"  onClick={()=>{navigate('/purchaseReport')}} >
      
      <span className="Purchase-button__icon-wrapper">
        <svg width="10" className="Purchase-button__icon-svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 15">
          <path fill="currentColor" d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"></path>
        </svg>
        
        <svg className="Purchase-button__icon-svg Purchase-button__icon-svg--copy" xmlns="http://www.w3.org/2000/svg" width="10" fill="none" viewBox="0 0 14 15">
          <path fill="currentColor" d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"></path>
        </svg>
      </span>
     Report
    </a>



    {/* enable the button only when the checkbox is checked  */}


<div>
  <button className={`Purchase-custom-btn approve ${!isCheckboxChecked ? 'disabled' : ''}`}
    onClick={() => {
      if (isCheckboxChecked) {
        setPurchasePopupOpen(true);
      }
    }}
    disabled={!isCheckboxChecked}> Accept</button>


  <button
    className={`Purchase-custom-btn edit ${!isCheckboxChecked ? 'disabled' : ''}`}
    onClick={() => {
      if (isCheckboxChecked) {
        setRejectPopupOpen(true);
      }
    }}
    disabled={!isCheckboxChecked}> Reject</button>
</div>


        
      </div>

      <table>
        <thead>
          <tr>
            <th>* Check All <input type="checkbox"onChange={(e) => setIsCheckboxChecked(e.target.checked)}/></th>
            <th>Sl No.</th>
            <th>Member ID </th>
            <th>Priority</th>
            <th>Date</th>
            <th>Time</th>
            <th>Remaing Time</th>
            <th>Payment Address</th>
            <th>Transactioin iD</th>
            <th>Receipt</th>
           
           
          </tr>
        </thead>
        <tbody>
          {/* Your table rows go here, I'm just copying one row for reference */}
          <tr>
            <td>
            <input type="checkbox"onChange={(e) => setIsCheckboxChecked(e.target.checked)}/>
            </td>
            <td>1</td>
            <td>VT-321654</td>
            <td>High</td>
            <td>03/01/2024</td>
            <td>11:44:53</td>
            <td>00:24:53</td>
            <td>abc@oksbi</td>
            <td>qwu0ihj13i912390</td>
            <td>
              <button className="btn-popup address-btn" onClick={()=>{setReceiptPopupOpen(true)}}>View</button>
            </td>
           
          </tr>



      





        </tbody>
      </table>

      {/*----##################-------- All POPUPS HERE -----------################--*/}





 {/*--@@@@@@@@@@@@@@ Receipt view @@@@@@@@@--*/}
 {isReceiptPopupOpen && (
   <>
    <div className="Purchase-overlay"></div>
    <div className="Purchase-popup">
      <div className="Purchase-popup-content">
        <h2>Recipt</h2>
        <img src={Reciptimg} alt="View Front Image" width="300" height="200" />
        <button className="Purchase-close-btn" onClick={closeReceiptPopup}>
          <i className="fas fa-times"></i>
        </button>
       
      </div>
      <button className="Purchase-done-btn" onClick={closeReceiptPopup}>
          Done
        </button>
    </div>
  </>
)}



  {/*----##################-------- All Top BUTTONS POPUPS HERE -----------################--*/}


 {/*--@@@@@@@@@@@@@@  Accept button Popup @@@@@@@@@--*/}
 {isPurchasePopupOpen && (
  <>
    <div className="Purchase-overlay"></div>
    <div className="Purchase-popup">

      <div class="Accept-Purchase-popup-content">
          <h2>Purchase Request Summary</h2>
          <div class="Purchase-top">Booking Time Price <span>₹ 1012.01</span></div>
          <div className="Purchase-main">
      <div className="Purchase-box1">
        <div className="priority">Priority: <span>High</span></div>
        <div className="Purchase-box">
          <div>VTCN Purchased</div>
          <div>100.00</div>
        </div>
        <div className="Purchase-box">
          <div>Coupon (EHDCGAWW)</div>
          <div>10.00</div>
        </div>
        <div className="Purchase-box">
          <div>Total Coins to Send</div>
          <div>110.00</div>
        </div>
        <div className="Purchase-boxend">
          <div>Total Amount Paid</div>
          <div>₹ 54,651,465.00</div>
        </div>
      </div>

      <div className="Purchase-box2">
        <div className="priority">User Request Information</div>
        <div className="Purchase-box">
          <div>Member ID</div>
          <div>VT - 123456</div>
        </div>
        <div className="Purchase-box">
          <div>Request Date</div>
          <div>01-01-2023</div>
        </div>
        <div className="Purchase-box">
          <div>Remaining</div>
          <div>00:25:22</div>
        </div>
        <div className="Purchase-box">
          <div>Txn. ID</div>
          <div>114556474578744</div>
        </div>
      </div>
    </div>

          <button class="Purchase-btn-Accept Accept" onClick={()=>{setAcceptPopupOpen(true);  setPurchasePopupOpen(false)}} >Confirm</button>
        
          <button class="Purchase-btn-Accept Cancel" onClick={closePurchasePopup} >Cancel</button>
         
          <button className="Purchase-close-btn" onClick={closePurchasePopup}>
          <i className="fas fa-times"></i>
        </button>
       </div>
    
    </div>

  </>
)}

 {/*--@@@@@@@@@@@@@@  Accept button Popup @@@@@@@@@--*/}
 {isAcceptPopupOpen && (
  <>
    <div className="Purchase-overlay"></div>
    <div className="Purchase-popup">

      <div class="Accept-Purchase-popup-content">
          <h2>Confirmation</h2>
          <p>Are you sure you want to Accept Purchase Request ?</p>

          <button class="Purchase-btn-Accept Accept" onClick={()=>{setAcceptConfirmPopupOpen(true); setAcceptPopupOpen(false)}} >Confirm</button>

          <button class="Purchase-btn-Accept Cancel" onClick={closeAcceptPopup} >Cancel</button>
         
          <button className="Purchase-close-btn" onClick={closeAcceptPopup}>
          <i className="fas fa-times"></i>
        </button>
       </div>
    
    </div>

  </>
)}


{/*--@@@@@@@@@@@@@@  Accept Confirm @@@@@@@@@--*/}

{isAcceptConfirmPopupOpen && (
  <>
    <div className="Purchase-overlay"></div>
    <div className="Purchase-popup">

      <div class="Accept-Purchase-popup-content">
      <img src={Acceptimg} alt="View Front Image" width="100" height="100" />
          <h2> Request Verified</h2>
         
          <button className="Purchase-close-btn" onClick={closeAcceptConfirmPopup}>
          <i className="fas fa-times"></i>
        </button>
       </div>
    
    </div>

  </>
)}




 {/*--@@@@@@@@@@@@@@ Reject Reason @@@@@@@@@--*/}
 {isRejectPopupOpen && (
  <>
    <div className="Purchase-overlay"></div>
    <div className="popup">
      <div className="Purchase-popup-content">
        <h2>Reason For Rejection</h2>
        {/* Replace the image with a textarea */}
        <textarea
          className="rejection-reason-textarea"
          placeholder="Type here..........."
          // You may want to bind this textarea to a state variable to capture the input
        ></textarea>
        <button className="Purchase-popup-content" onClick={closeRejectionPopup}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <button className="Purchase-done-btn"onClick={()=>{setRejectSubmitPopupOpen(true); setRejectPopupOpen(false)}} >
        Submit
      </button>
    </div>
  </>
)}



{/*--@@@@@@@@@@@@@@ Reject Submit popup @@@@@@@@@--*/}

{isRejectSubmitPopupOpen && (
  <>
    <div className="Purchase-overlay"></div>
    <div className="popup">

      <div class="Accept-Purchase-popup-content">
      <img src={Rejectsub} alt="View Front Image" width="100" height="100" />
          <h2 style={{color:'red'}}>Request Rejected</h2>
         
          <button className="Purchase-popup-content" onClick={closeRejectSubmitPopup}>
          <i className="fas fa-times"></i>
        </button>
       </div>
    
    </div>

  </>
)}


    {timeoutPop && <TimeoutPop
      setTimeoutPop={setTimeoutPop}
    />}


    </section>
  
  );
};

export default Purchase ;
