import React, { useEffect, useState } from 'react';
import './TdsProfile.css';

import API from '../../../api/Api';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Pagination from '../../Pagination/Pagination';
import moment from 'moment';

const TdsProfile = ({ userId }) => {
    const [listTds, setListTds] = useState([]); 
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const tdsProfileMethod = () => {
        setLoading(true);
        API.get("/staff/market/purchase/report", {
          params: { 
            userId: userId,
            page: currentPage+1,
            limit: itemsPerPage,
        }
        }).then((res) => {
          setListTds(res.data.data.docs); 
          setTotalRecords(res.data.data.totalDocs); 
        }).catch((err) => {
          // toast.error("Something went wrong.", {position:"top-center"}); 
        }).finally(() => {
            setLoading(false);
        });
    }

    const handlePageChange = ({ selected }) => { 
        setCurrentPage(selected);
    };
    const pageCount = Math.ceil(totalRecords / itemsPerPage);

    useEffect(() => {
        tdsProfileMethod();
    }, [currentPage]); 


  return (
    <div className="activity-table-container">
      <table className="activity-table">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th>Asset</th>
            <th>Asset Price(USDT)</th>
            <th>Transaction Method</th>
            <th>Platform Charges(%)</th>
            {/* <th>TDS(%)</th> */}
            {/* <th>Crypto GST %(India Only)</th> */}
            {/* <th>Total Tax(USDT)</th> */}
            <th>Grand Total(USDT)</th>
          </tr>
        </thead>

        <tbody>
        {loading && <LoadingSpinner />}
        {listTds.map((data, index) => {
        return(
          <tr key={index}>
            <td>{index+1}</td>
            <td>
                <div className="tds-img-div">
                    <img src={data.cryptoSymbolUrl} alt="logo" className='cryp-icon' />
                    <p>{data.cryptoSymbol}</p>
                </div>
            </td>
            <td>{parseFloat(data.amountUSDT).toFixed(2)}</td>
            
            <td 
            className={`
                ${data.type === 'CRYPTO-EXCHANGE' && 'tds-blue'}
                ${data.type === 'CRYPTO-SELL' && 'tds-red'}
                ${data.type === 'CRYPTO-BUY' && 'tds-green'}
            `}
            >
                {data.type}
            </td>

            <td>{parseFloat(data.platformCharges).toFixed(2)}</td>
            {/* <td>1</td> */}
            {/* <td>30</td> */}
            {/* <td>2000.00</td> */}
            <td>{parseFloat(data.finalAmount).toFixed(2)}</td>
          </tr>
        )})}

        </tbody>
      </table>

      <Pagination 
        pageCount={pageCount} 
        onPageChange={handlePageChange}    
      />
    </div>
  );
};

export default TdsProfile;
