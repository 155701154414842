// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stk-black {
    color: #4B5563;
}

.stk-red {
    color: red;
}

.stk-green {
    color: #0de589;
}

.stk-grey {
    color: #B45309;
}
`, "",{"version":3,"sources":["webpack://./src/Componets/StakeReport/StakeReport.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".stk-black {\r\n    color: #4B5563;\r\n}\r\n\r\n.stk-red {\r\n    color: red;\r\n}\r\n\r\n.stk-green {\r\n    color: #0de589;\r\n}\r\n\r\n.stk-grey {\r\n    color: #B45309;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
