import React from 'react';
import '../Sell-Open/Sell-Open.css'; 
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import moment from 'moment';

const Sellopen = ({listOrderSell, loading, pagingCounter}) => {

    return (
        <div className="Sell-Open-TABLE-Table-updated">
          <table className="Sell-Open-TABLE-Tpage-table">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Sell Crypto</th>
                <th>Got</th>
                <th>From</th>
                <th>Payment</th>
                <th>Date</th>
                <th>Time</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {loading && <LoadingSpinner />}
              {listOrderSell.map((data, index) => {
              return(
              <tr key={index}>
                <td>{index+pagingCounter}</td>
                <td>{parseFloat(data.cryptoAmount).toFixed(2)} {data.cryptoSymbol}</td>
                <td>{parseFloat(data.amount).toFixed(2)}</td> 
                <td>{data.from}</td>
                <td>{data.payment.upi}</td>
                <td>{moment(data.updatedAt).format('DD-MM-YYYY')}</td>
                <td>{moment(data.updatedAt).format('hh:mm:ss')}</td>
                <td>Pending</td>
              </tr>
              )})}
            </tbody>
          </table>
        </div>
    );
  }


export default Sellopen;
