import React, { useState } from 'react'; 
import './OtpVerifyPop.css'; 
import crossIcon from '../../Assets/Member-img/crossIcon.png'; 
import mobIcon from '../../Assets/Member-img/mobIcon.png'; 
import mailIcon from '../../Assets/Member-img/mailIcon.png'; 
import radio1 from '../../Assets/Member-img/radio1.png'; 
import radio2 from '../../Assets/Member-img/radio2.png';  

const OtpVerifyPop = ({setShowOtpVerifyPop, singleTransaction, onSendEmail, onSendMobile}) => {
  const [check1, setCheck1] = useState('CHECK1'); 
  const [check2, setCheck2] = useState('CHECK2');
  // const [email, setEmail] = useState(null);
  // const [mobile, setMobile] = useState(null);
 
  function addFormSubmit(event) { 
    event.preventDefault();
    if(check1 === 'EMAIL') {
      onSendEmail(singleTransaction.email);
    }
    if(check2 === 'MOBILE') {
      onSendMobile(singleTransaction.mobile); 
    }
    setShowOtpVerifyPop(false); 
  }

  return (
    <article className='OtpVerifyPop'>
        <h6 className='pop-title'>OTP Verification</h6>
        <img className='pop-cross' src={crossIcon} alt="Cross" onClick={()=>setShowOtpVerifyPop(false)} />
        <form onSubmit={(e) => addFormSubmit(e)}>
          <p className='title-receive'>Select method to receive OTP</p>
          <div className='input-main2'>
            <div className='input-div2 input-div3'>
              <label htmlFor="title">Email ID</label>
              <input 
                type="email" 
                name="title" 
                placeholder='Email ID' 
                value={singleTransaction?.email}
                readOnly
                // onChange={(e)=> setEmail(e.target.value)}
                // disabled={check1 === 'EMAIL' ? false : true}
                // required
              /> 
            </div>

            <div className='input-div2 input-div3'>
              <label htmlFor="title">Mobile Number</label> 
              <input 
                type="number" 
                name="title" 
                placeholder='Mobile Number' 
                value={singleTransaction?.mobile}
                readOnly
                // onChange={(e)=> setMobile(e.target.value)}
                // disabled={check2 === 'MOBILE' ? false : true}
                // required
              />
            </div>
          </div>

          <img src={mailIcon} alt="icon" className='mail-icon'/>
          <img src={mobIcon} alt="icon" className='mob-icon'/>

          {check1 === 'CHECK1' && 
            <img src={radio1} alt="icon" className='radio1-icon' onClick={()=> {setCheck1('EMAIL'); setCheck2('CHECK2');}}/>
          }
          {check1 === 'EMAIL' && 
            <img src={radio2} alt="icon" className='radio1-icon'/>
          }

          {check2 === 'CHECK2' && 
            <img src={radio1} alt="icon" className='radio2-icon' onClick={()=> {setCheck2('MOBILE'); setCheck1('CHECK1');}}/>
          }
          {check2 === 'MOBILE' && 
            <img src={radio2} alt="icon" className='radio2-icon'/>
          }

          <div className='input-main'>
            {(check1 !== 'EMAIL' && check2 !== 'MOBILE') && 
              <button type='button' className='btn-otp' disabled>Send OTP</button>
            }
            {(check1 === 'EMAIL' || check2 === 'MOBILE') && 
              <button type='submit' className='btn-cnf'>Send OTP</button>
            }

            <button type='button' className='btn-cncl' onClick={()=>setShowOtpVerifyPop(false)}>Cancel</button>
          </div>
        </form>
    </article>
  )
}

export default OtpVerifyPop; 