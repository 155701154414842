import axios from 'axios';
import { getAuthToken } from '../util/Auth';

const accessToken = getAuthToken(); 
// const accessToken = "";

// https://vtcncoin.com
// http://62.72.58.157:9001

const API = axios.create({
    baseURL: "https://vtcncoin.com",
    timeout: 120000,
    headers: {
        "Authorization": accessToken,
        "Content-Type": "application/json;charset=utf-8", 
        "Accept": "application/json",
	    "Access-Control-Allow-Origin": '*',
    }
});

const APIMEDIA = axios.create({
    baseURL: "https://vtcncoin.com", 
    timeout: 120000,
    headers: {
        "Content-Type": "multipart/form-data",
        "Accept": "application/json", 
        "Authorization": accessToken,
	    "Access-Control-Allow-Origin": '*',
    }
});

export default API; 
export { APIMEDIA };  
