import React, { useState } from 'react';
import '../../Assets/All-Css/KYC/Kyc_Verification.css';

import '../../Assets/style.css';
import profilepng from '../../Assets/Login-img/Component 1.png';
import { useEffect } from 'react';

import fontimg from '../../Assets/Login-img/addhaa-font.jpg';
import backimg from '../../Assets/Login-img/Aadhaar-back.png';
import panfont from '../../Assets/Login-img/Pan-card.png';
import addressproof from '../../Assets/Login-img/votercard.png';
import spamdelete from '../../Assets/Login-img/spam-delete.gif';
import spamblock from '../../Assets/Login-img/spam-block.gif';
import acceptimg from '../../Assets/Login-img/Accept-pop.png';
import rejectsub from '../../Assets/Login-img/Reject-submit.png'; 

import API from '../../api/Api';
import LoadingSpinner from '../../Componets/LoadingSpinner/LoadingSpinner';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '../../Componets/Pagination/Pagination';
import { useNavigate } from "react-router-dom"; 
import { SessionTimer } from '../../util/UtilMethod';
import { getAuthToken, getTokenDuration } from '../../util/Auth';
import TimeoutPop from '../../Componets/TimeoutPop/TimeoutPop';


const Kycverification = () => {
  const navigate = useNavigate();

  // TOKEN EXPIRATION
  const [timeoutPop, setTimeoutPop] = useState(false);           
  const token = getAuthToken();
  useEffect(() => {
    if(!token) { 
      navigate('/loginform');
      return; 
    }
    if (token === 'EXPIRED') {
      navigate('/loginform');
      return; 
    }
    const tokenDuration = getTokenDuration(); 
    setTimeout(() => {
      navigate('/loginform'); 
    }, tokenDuration);     // 1*60*60*1000   
  }, [token]);  

  useEffect(() => {
    const tokenDuration = getTokenDuration();  
    const timer = setTimeout(() => {
      setTimeoutPop(true);
    }, (tokenDuration - 15*60*1000));
    return () => clearTimeout(timer); 
  }, []);
  // TOKEN EXPIRATION END


  const [isViewFrontPopupOpen, setViewFrontPopupOpen] = useState(false);      
  const [isViewBackPopupOpen, setViewBackPopupOpen] = useState(false);
  const [isTaxDocPopupOpen, setTaxDocPopupOpen] = useState(false);
  const [isTaxDocBackPopupOpen, setTaxDocBackPopupOpen] = useState(false);
  const [isAddressPopupOpen, setAddressPopupOpen] = useState(false);

  const [isAcceptPopupOpen, setAcceptPopupOpen] = useState(false);
  const [isAcceptComformPopupOpen, setAcceptComformPopupOpen] = useState(false);

  const [isRejectPopupOpen, setRejectPopupOpen] = useState(false);
  const [isRejectSubmitPopupOpen, setRejectSubmitPopupOpen] = useState(false);

  const [isSpamPopupOpen, setSpamPopupOpen] = useState(false);
  const [isSpamDeletePopupOpen, setSpamDeletePopupOpen] = useState(false);
  const [isSpamBlockPopupOpen, setSpamBlockPopupOpen] = useState(false);

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false); 
  const [isCheckboxAccept, setIsCheckboxAccept] = useState(false); 

  const closeViewFrontPopup = () => {
    setViewFrontPopupOpen(false);
  };

  const closeViewBackPopup = () => {
    setViewBackPopupOpen(false);
  };

  const closeTaxDocPopup = () => {
    setTaxDocPopupOpen(false);
  };

  const closeTaxDocBackPopup = () => {
    setTaxDocBackPopupOpen(false);
  };

  const closeAddressPopup = () => {
    setAddressPopupOpen(false);
  };

  const closeAcceptPopup = () => {
    setAcceptPopupOpen(false);
  };

  const closeAcceptComformPopup = () => { 
    setAcceptComformPopupOpen(false);
  };

  const closeRejectionPopup = () => {
    setRejectPopupOpen(false);
  };

  const closeRejectSubmitPopup = () => {
    setRejectSubmitPopupOpen(false);
  };

  const closeSpamPopup = () => {
    setSpamPopupOpen(false);
  };

  const closeSpamDeletePopup = () => {
    setSpamDeletePopupOpen(false);
  };

  const closeSpamBlockPopup = () => {
    setSpamBlockPopupOpen(false);
  };


  const [sidebarHidden, setSidebarHidden] = useState(false);  
  const [allDropdown, setAllDropdown] = useState([]);

  // State to manage session time
  const [sessionTime, setSessionTime] = useState(0);

  const nav = useNavigate ();


  useEffect(() => {
    setAllDropdown(document.querySelectorAll('#sidebar .side-dropdown'));
  }, []);

  useEffect(() => {
    // Attach event listeners for dropdown elements
    allDropdown.forEach((dropdown) => {
      const parentLink = dropdown.closest('li');
      parentLink.addEventListener('click', (e) => handleMenuClick(e, dropdown));
    });

    // Set an interval to update the session time every second
    const timerInterval = setInterval(() => {
      setSessionTime((prevTime) => prevTime + 1);
    }, 1000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(timerInterval);

      // Remove event listeners
      allDropdown.forEach((dropdown) => {
        const parentLink = dropdown.closest('li');
        parentLink.removeEventListener('click', (e) => handleMenuClick(e, dropdown));
      });
    };
  }, [allDropdown]);

  const handleDropdownClick = (e, dropdown) => {
    e.preventDefault();

    const isDropdownActive = e.currentTarget.classList.contains('active');

    // Close all dropdowns
    allDropdown.forEach((item) => {
      const aLink = item.parentElement.querySelector('a:first-child');
      aLink.classList.remove('active');
      item.classList.remove('show');
    });

    // Toggle the clicked dropdown
    e.currentTarget.classList.toggle('active', !isDropdownActive);
    dropdown.classList.toggle('show', !isDropdownActive);
  };

  const handleToggleSidebar = () => {
    setSidebarHidden((prevSidebarHidden) => !prevSidebarHidden);
  };

  const handleProfileClick = () => {
    const profileLink = document.querySelector('.profile-link');
    profileLink.classList.toggle('show');
  };

  const handleMenuClick = (e, dropdown) => {
    e.preventDefault();
    dropdown.classList.toggle('show');
  };

  // Convert seconds to HH:MM:SS format
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const pad = (num) => (num < 10 ? '0' + num : num);

    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  };


  // API DATA //////////////////////////////////////
  const sessionTimer = SessionTimer();
  const [loading, setLoading] = useState(false);
  const [kycList, setKycList] = useState([]);
  const [singleKyc, setSingleKyc] = useState([]);
  const [idFrontInput, setIdFrontInput] = useState(null);
  const [taxFrontInput, setTaxFrontInput] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10); 
  const [pagingCounter, setPagingCounter] = useState(1); 
  const [rejectNote, setRejectNote] = useState('--');

  const [arrId, setArrId] = useState([]);
  const [resMessage, setResMessage] = useState(null); 
  const [errMessage, setErrMessage] = useState(null);

  const [pagePermission, setPagePermission] = useState([]); 
  const pagePermissionMethod = () => { 
    API.get("/staff/account/role/allow-pages").then((res) => {
      setPagePermission(res.data.data); 
    }).catch((err) => { 
    }).finally(() => {
    });  
  }

  const listKycMethod = () => {
    setLoading(true);
    API.get("/staff/kyc/list/in-process", {
      params: {
        page: currentPage+1,
        limit: itemsPerPage,
      }
    }).then((res) => {
      setKycList(res.data.data.docs); 
      setTotalRecords(res.data.data.totalDocs);  
      setPagingCounter(res.data.data.pagingCounter);
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }).finally(() => {
      setLoading(false);
    });  
  }

  const handlePageChange = ({ selected }) => { 
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(totalRecords / itemsPerPage);


  const acceptKycMethod = () => {
    // const body = {
    //   id: singleKyc._id
    // };
    // API.put("/staff/kyc/verify/approve", body).then((res) => { 
    //   setIsCheckboxAccept(false);
    //   listKycMethod(); 
    //   toast.success("Kyc approved successfully.", {position:"top-center"});
    // }).catch((err) => {
    //   toast.error("Something went wrong.", {position:"top-center"});
    // });   

    for(var i = 0;  i < arrId.length;  i++) {
      API.put("/staff/kyc/verify/approve", { id: arrId[i] }).then((res) => { 
        setIsCheckboxAccept(false);
        setIsCheckboxChecked(false);
        // listKycMethod(); 
        // toast.success("Kyc approved successfully.", {position:"top-center"});
        setResMessage(res.data.message);
      }).catch((err) => {
        // toast.error("Something went wrong.", {position:"top-center"});
        setErrMessage(err.response.data.message);
      });   
    }

    // if(resMessage) {
    //   // toast.success("Kyc approved successfully.", {position:"top-center"});
    //   setAcceptComformPopupOpen(true);
    // }
    // if(errMessage) {
    //   toast.error("Something went wrong.", {position:"top-center"}); 
    //   return;
    // }
    setAcceptComformPopupOpen(true);
    listKycMethod(); 
  }

  const spamKycMethod = () => {
    const body = {
      id: singleKyc._id
    };
    API.put("/staff/kyc/verify/spam", body).then((res) => { 
      listKycMethod(); 
      toast.success("Kyc marked as spam successfully.", {position:"top-center"});
    }).catch((err) => {
      toast.error("Something went wrong.", {position:"top-center"});
    });   
  }

  const rejectKycMethod = () => {
    const body = {
      id: singleKyc._id,
      note: rejectNote
    };
    API.put("/staff/kyc/verify/reject", body).then((res) => { 
      listKycMethod(); 
      toast.success("Kyc rejected successfully.", {position:"top-center"});
    }).catch((err) => {
      toast.error("Something went wrong.", {position:"top-center"});
    });   
  }

  const verifyIdFrontMethod = () => { 
    const body = {
      id: singleKyc._id,
      idNumber: idFrontInput
    };

    if(singleKyc.country.country === 'India') {
      let regex = new RegExp(/^\d{12}$/);

      if (regex.test(idFrontInput) == true) {
        API.put("/staff/kyc/verify/id/number", body).then((res) => { 
          if(res.data.data.kyc.idNumberVerification === "MISMATCH") {
            toast.warning('Entered Id number does not match.', {position:"top-center"});
          }else {
            toast.success(res.data.message, {position:"top-center"});
          }
          listKycMethod(); 
        }).catch((err) => {
          toast.error(err.response.data.message, {position:"top-center"});
        }); 
      }
      else {
        toast.warning('Please enter valid id number.', {position:"top-center"});
      }
    }else {
      API.put("/staff/kyc/verify/id/number", body).then((res) => { 
        if(res.data.data.kyc.idNumberVerification === "MISMATCH") {
          toast.warning('Entered Id number does not match.', {position:"top-center"});
        }else {
          toast.success(res.data.message, {position:"top-center"});
        }
        listKycMethod(); 
      }).catch((err) => {
        toast.error(err.response.data.message, {position:"top-center"});
      }); 
    }

    closeViewFrontPopup(); 
    closeViewBackPopup(); 
  }

  const verifyTaxFrontMethod = () => {
    const body = {
      id: singleKyc._id,
      panNumber: taxFrontInput
    };

    if(singleKyc.country.country === 'India') {
      let regex = new RegExp(/^[a-zA-Z0-9]{10}$/);

      if (regex.test(taxFrontInput) == true) {
        API.put("/staff/kyc/verify/pan/number", body).then((res) => { 
          if(res.data.data.kyc.panNumberVerification === "MISMATCH") {
            toast.warning('Entered Tax document number does not match.', {position:"top-center"});
          }else {
            toast.success(res.data.message, {position:"top-center"});
          }
          listKycMethod(); 
        }).catch((err) => {
          toast.error(err.response.data.message, {position:"top-center"});
        });  
      }
      else {
        toast.warning('Please enter valid tax document number.', {position:"top-center"});
      }
    }else {
      API.put("/staff/kyc/verify/pan/number", body).then((res) => { 
        if(res.data.data.kyc.panNumberVerification === "MISMATCH") {
          toast.warning('Entered Tax document number does not match.', {position:"top-center"});
        }else {
          toast.success(res.data.message, {position:"top-center"});
        }
        listKycMethod(); 
      }).catch((err) => {
        toast.error(err.response.data.message, {position:"top-center"});
      }); 
    }

    closeTaxDocPopup(); 
    closeTaxDocBackPopup(); 
  }

  useEffect(() => {
    listKycMethod();
  }, [currentPage]);  

  useEffect(() => {
    pagePermissionMethod();  
  }, []); 


return (
<>
<section id="sidebar" className={sidebarHidden ? 'hide' : ''}> 
    <a href="#" className="brand">
      <img src={profilepng} alt="Profile Image" className="profile-image" />
      Support
    </a> 

    <ul className="side-menu">
      {pagePermission.some(el => el === 'dashboard') && 
      <li>
        <a href='#' className="active" onClick={()=>{navigate('/dashboard')}} >
          <i className="fas fa-tachometer-alt icon"></i> Dashboard
        </a>
      </li>}

      <li className="divider" data-text="main">
        Main
      </li>

       {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Manage Request----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'ManageRequests') && 
        <a href="#" >
          <i className="fas fa-folder icon fa-fw"></i> Manage Requests
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverification')}}>
              <i className="fas fa-id-card"></i> KYC Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderverification')}}>
              <i className="fas fa-briefcase"></i> Trader Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'recharge') && 
          <li>
            <a href="#" onClick={()=>{navigate('/recharge')}}>
              <i className="fas fa-phone"></i> Recharge Request
            </a> 
          </li>}

          {pagePermission.some(el => el === 'c2c') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2c')}}>
              <i className="fas fa-exchange-alt"></i> C2C Request
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputesattlement') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputesattlement')}}> 
              <i className="fas fa-ban"></i> Dispute Settlement
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'investotp') &&
      <li>
        <a href='#' onClick={()=>{navigate('/investotp')}}>
          <i className="fas fa-fingerprint icon fa-fw"></i>Invest Via OTP
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {/* --@@@@@@@@@@@@@@@@@@@@@@@@---View Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

     
      <li>
        {pagePermission.some(el => el === 'ViewReports') && 
        <a href="#" >
          <i className="fas fa-file-alt icon fa-fw"></i> View Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverifyedreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverifyedreport')}}>
              <i className="fas fa-id-card"></i> KYC Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderreport')}}>
              <i className="fas fa-briefcase"></i> Trader Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'rechargereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/rechargereport')}}>
              <i className="fas fa-donate"></i> Recharge Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'c2creport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2creport')}}>
              <i className="fas fa-money-check-alt"></i> C2C Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputereport')}}>
              <i className="fas fa-handshake"></i> Dispute Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'utrreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/utrreport')}}>
              <i className="fas fa-money-check"></i> UTR Status Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'purchaseReport') && 
          <li>
            <a href="#"onClick={()=>{navigate('/purchaseReport')}}>
              <i className="fas fa-gift"></i> Purchase Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'partnerreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/partnerreport')}}>
              <i className="fas fa-handshake"></i> Partner Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'ViewReports') && 
          <li>
            <a href="#" onClick={()=>{navigate('/noninvestedreport')}}>
              <i className="fas fa-file-alt"></i> Non Invested Report
            </a>
          </li>}

        </ul>
      </li>


    {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Send Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'SendReports') && 
        <a href="#" >
          <i className="fas fa-paper-plane icon fa-fw"></i> Send Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'sendecharge') && 
          <li>
            <a href='#' onClick={()=>{navigate('/sendecharge')}}>
              <i className="fas fa-file-alt"></i> Coin2Cash
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'memberManage') &&
      <li>
        <a href="#" onClick={()=>{nav('/memberManage')}} >
          <i className="fas fa-users icon fa-fw"></i> Manage Members
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'blockuser') &&
      <li>
        <a href="#" onClick={()=>{nav('/blockuser')}} >
          <i className="fas fa-user-circle icon fa-fw"></i> Block User
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'enquirymanage') &&
      <li>
        <a href="#" onClick={()=>{navigate('/enquirymanage')}}>
          <i className="fas fa-briefcase icon fa-fw"></i> Manage Enquiry
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'update') &&
      <li>
        <a href="#" onClick={()=>{navigate('/update')}}>
          <i className="fas fa-tools icon fa-fw"></i> Update Manager
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}
      
      {pagePermission.some(el => el === 'ChatSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/ChatSupport')}}>
          <i className="fas fa-comment-alt icon fa-fw"></i>Chat Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'TicketSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/TicketSupport')}}>
          <i className="fas fa-chalkboard-teacher icon fa-fw"></i>Ticket Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'feedback') &&
      <li>
        <a href='#' onClick={()=>{navigate('/feedback')}}> 
          <i className="fas fa-comment-medical icon fa-fw"></i>Feedbacks
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      <li className="divider" data-text="Exit">
        Exit
      </li>

      <div className="ads">
        <button className="Btn" onClick={()=>{navigate('/loginform')}}>
          <div className="sign">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text">Logout</div>
        </button>
      </div>
    </ul> 
  </section> 


{/* MAIN CONTENT */}
  <section id="content">
    <nav>
      <i className="bx bx-menu toggle-sidebar" onClick={handleToggleSidebar}></i>
      <form action="#">
        <div className="form-group">
          <span>Session Time: {formatTime(sessionTimer)}</span>  
        </div>
      </form>
       
      <div className='notify'>
       <i className="fas fa-bell"></i>
     </div>


      <span className="divider"></span>
{/* --@@@@@@@@@@@@@@@@@@@@@@@@---Profile----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 
      <div className="profile">
        <img
          src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-File.png"
          onClick={handleProfileClick}
          alt="Profile"
        />
        <ul className="profile-link">
          <li>
            <a href="" onClick={()=>{navigate('/selfprofile')}}>
              <i className="bx bxs-user-circle icon"></i> Profile
            </a>
          </li>
 {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Logout----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 

          <li>
            <a href="#" onClick={()=>{navigate('/loginform')}}>
              <i className="bx bxs-log-out-circle"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>

        <main>
          {/* {props.passedComponent} */}
          <section className="Kyc-Ver-Management-container">

{/* @@@@@@@@@@@@@@@@@@@@_____top Heading____@@@@@@@@@@@@@@@@@@@@@ */}

    <div className="kyc-ver-mange-top-heading">
      <h1><i className='bx bx-link'></i>Kyc Verification </h1>
    </div>


{/* @@@@@@@@@@@@@@@@@@@@_____top filler search, back btn, date____@@@@@@@@@@@@@@@@@@@@@ */}

 {/* Back Button */}
    <div className="Kyc-ver-mange-Fillter-bar">

  {pagePermission.some(el => el === 'kycverifyedreport') && 
   <a className="kyc-ver-button" href="#" onClick={()=>{navigate('/kycverifyedreport')}}>
    <span className="kyc-ver-button__icon-wrapper">
      <svg width="10" className="button__icon-svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 15">
        <path fill="currentColor" d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"></path>
      </svg>
      
      <svg className="kyc-ver-button__icon-svg kyc-ver-button__icon-svg--copy" xmlns="http://www.w3.org/2000/svg" width="10" fill="none" viewBox="0 0 14 15">
        <path fill="currentColor" d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"></path>
      </svg>
    </span>
   Report
  </a>}



  {/* <div>
    <button  className="custom-btn approve"onClick={()=>{setAcceptPopupOpen(true)}} >Accept</button>
    <button  className="custom-btn block"onClick={()=>{setSpamPopupOpen(true)}}>Spam</button>
    <button className="custom-btn edit"  onClick={()=>{setRejectPopupOpen(true)}}>Reject</button>
  </div> */}


<div>


  
{/* {pagePermission.some(el => el === 'kycverificationBtnAccept') &&
<button 
  className={`kyc-ver-custom-btn kyc-ver-approve ${!isCheckboxChecked ? 'disabled' : ''}`}
  onClick={() => {
    if (isCheckboxChecked) {
      setAcceptPopupOpen(true);
    }
  }}
  disabled={!isCheckboxChecked}
> Accept</button>} */}

{pagePermission.some(el => el === 'kycverificationBtnAccept') &&
<button 
  className={`kyc-ver-custom-btn kyc-ver-approve 
    ${(!isCheckboxAccept) && 'disabled'}
  `}
  onClick={() => {
    if (isCheckboxChecked && singleKyc.kyc.idNumberVerification === "MATCH" && singleKyc.kyc.panNumberVerification === "MATCH") {
      setAcceptPopupOpen(true);
    }
  }}
  disabled={!isCheckboxAccept}
>Accept</button>}



{pagePermission.some(el => el === 'kycverificationBtnSpam') &&
<button className={`kyc-ver-custom-btn kyc-ver-block ${!isCheckboxChecked ? 'disabled' : ''}`}
  onClick={() => {
    if (isCheckboxChecked) {
      setSpamPopupOpen(true);
    }
  }}
  disabled={!isCheckboxChecked}> Spam</button>}

{pagePermission.some(el => el === 'kycverificationBtnReject') &&
<button
  className={`kyc-ver-custom-btn kyc-ver-edit ${!isCheckboxChecked ? 'disabled' : ''}`}
  onClick={() => {
    if (isCheckboxChecked) {
      setRejectPopupOpen(true);
    }
  }}
  disabled={!isCheckboxChecked}> Reject</button>}

</div>
      
    </div>

    <table>
      <thead>
        <tr>
          <th>
            Check 
            {/* <input type="checkbox" onChange={(e) => setIsCheckboxChecked(e.target.checked)}/> */}
          </th>
          <th>Sl No.</th>
          <th>Member Name</th>
          <th>Country</th>
          <th>ID Title</th>
          <th>ID Number</th>
          <th>ID Front </th>
          <th>ID Back</th>
          <th>Tax Doc.Title</th>
          <th>Tax Doc.Number</th>
          <th>Tax Doc.Front</th>
          <th>Tax Doc.Back</th>
          <th>Address Proof</th>
          <th>Submit Count</th>
          <th>Status</th>
        </tr>
      </thead>

      <tbody>
        {loading && <LoadingSpinner />}
        {kycList.map((data, index) => {
        return(
        <tr key={index}>
          <td>
            <input 
              type="checkbox"
              onChange={(e) => setIsCheckboxChecked(e.target.checked)}
              onClick={() => {
                setSingleKyc(data);
                if (data.kyc.idNumberVerification === "MATCH" && data.kyc.panNumberVerification === "MATCH") {
                  setIsCheckboxAccept(true);
                  setArrId((prev) => [...prev, data._id]);
                }
              }}
            />
          </td>
          <td>{pagingCounter+index}</td>
          <td>{data.name}</td>
          <td>{data.country.country}</td>
          <td>{data.country.idDocument.idDocumentTitle}</td>
          <td>{data.kyc.documentNumber}</td>

          <td>
            {(data.kyc.idNumberVerification === "PENDING" || data.kyc.idNumberVerification === "MISMATCH") && <button 
              type='button'
              className="btn-popup view-front-btn" 
              onClick={()=>{setViewFrontPopupOpen(true); setSingleKyc(data);}} 
            >Verify</button>}

            {data.kyc.idNumberVerification === "MATCH" && <span>Verified <i class="fa-regular fa-circle-check" style={{color: '#0de589', fontSize: '16px'}}></i></span>}
          </td>

          <td>
            {(data.kyc.idNumberVerification === "PENDING" || data.kyc.idNumberVerification === "MISMATCH") && <button 
              type='button'
              className="btn-popup view-back-btn" 
              onClick={()=>{setViewBackPopupOpen(true); setSingleKyc(data);}}
            >Verify</button>}

            {data.kyc.idNumberVerification === "MATCH" && <span>Verified <i class="fa-regular fa-circle-check" style={{color: '#0de589', fontSize: '16px'}}></i></span>}
          </td>

          <td>{data.country.tax.taxDocumentTitle}</td>
          <td>{data.kyc.panDocumentNumber}</td>

          <td>
            {(data.kyc.panNumberVerification === "PENDING" || data.kyc.panNumberVerification === "MISMATCH") && <button 
              type='button'
              className="btn-popup tax-doc-btn" 
              onClick={()=>{setTaxDocPopupOpen(true); setSingleKyc(data);}}
            >Verify</button>}

            {data.kyc.panNumberVerification === "MATCH" && <span>Verified <i class="fa-regular fa-circle-check" style={{color: '#0de589', fontSize: '16px'}}></i></span>}
          </td>
          
          <td>
            {(data.kyc.panNumberVerification === "PENDING" || data.kyc.panNumberVerification === "MISMATCH") && <button 
              type='button'
              className="btn-popup tax-back-btn" 
              onClick={()=>{setTaxDocBackPopupOpen(true); setSingleKyc(data);}}
            >Verify</button>}

            {data.kyc.panNumberVerification === "MATCH" && <span>Verified <i class="fa-regular fa-circle-check" style={{color: '#0de589', fontSize: '16px'}}></i></span>}
          </td>

          <td>
            <button 
              type='button'
              className="btn-popup address-btn" 
              onClick={()=>{setAddressPopupOpen(true); setSingleKyc(data);}}
            >View</button>
          </td> 

          <td>{data.kyc?.submitCount}</td>
          <td style={{
            color: data.kyc?.status === 'IN-PROCESS' ? '#e8600a' : '#06b66c'
          }}>
            {data.kyc?.status}
          </td>
        </tr>
        )})} 
      </tbody>
    </table>
    <Pagination 
      pageCount={pageCount} 
      onPageChange={handlePageChange}    
    />

    {/*----##################-------- All POPUPS HERE -----------################--*/}

{/*--@@@@@@@@@@@@@@ Aadhaar font view @@@@@@@@@--*/}
{isViewFrontPopupOpen && (
 <>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">
    <div className="kyc-ver-popup-content">
      <h2>ID Front</h2>

      <div className='kycImgDiv'>
        <img src={singleKyc.kyc.documentImage} alt="View Front Image" width="300" height="200" /> 
      </div>

      <input 
        type="tel" 
        placeholder='Enter ID Number' 
        name='idDocFront' 
        onChange={(e) => setIdFrontInput(e.target.value)}
      />

      <button className="kyc-ver-close-btn" onClick={closeViewFrontPopup}>
        <i className="fas fa-times"></i>
      </button>
     
    </div>
    
    {pagePermission.some(el => el === 'kycverificationBtnVerify') &&
    <button className="done-btn" onClick={()=> {verifyIdFrontMethod();}}>
      Verify
    </button>}

  </div>
</>
)}


{/*@@@@@@@@@@@@@ Aadhaar back view @@@@@@@@@@@-*/}

{isViewBackPopupOpen && (
 <>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">
    <div className="kyc-ver-popup-content">
      <h2>ID Back</h2>

      <div className='kycImgDiv'>
        <img src={singleKyc.kyc.documentImageBack} alt="View Back Image" width="300" height="200" />
      </div>

      <input 
        type="tel" 
        placeholder='Enter ID Number' 
        onChange={(e) => setIdFrontInput(e.target.value)}
      />

      <button className="kyc-ver-close-btn" onClick={closeViewBackPopup}>
        <i className="fas fa-times"></i>
      </button>
     
    </div>

    {pagePermission.some(el => el === 'kycverificationBtnVerify') &&
      <button className="done-btn"  onClick={()=> {verifyIdFrontMethod();}}>
        Verify
      </button>}

  </div>
</>
)}


{/*--@@@@@@@@@@@@@@ Pan-card font view @@@@@@@@@--*/}
{isTaxDocPopupOpen && (
 <>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">
    <div className="kyc-ver-popup-content">
      <h2>Tax Doc Front</h2>

      <div className='kycImgDiv'>
        <img src={singleKyc.kyc.panDocumentName} alt="View Front Image" width="300" height="200" />
      </div>

      <input 
        type="tel" 
        placeholder='Enter Tax doc number' 
        onChange={(e) => setTaxFrontInput(e.target.value)}
      />

      <button className="kyc-ver-close-btn" onClick={closeTaxDocPopup}>
        <i className="fas fa-times"></i>
      </button>
     
    </div>

    {pagePermission.some(el => el === 'kycverificationBtnVerify') &&
     <button className="done-btn" onClick={()=> {verifyTaxFrontMethod();}}>
        Verify
      </button>}

  </div>
</>
)}


{/*--@@@@@@@@@@@@@@ Pan-card back view @@@@@@@@@--*/}
{isTaxDocBackPopupOpen && (
 <>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">
    <div className="kyc-ver-popup-content">
      <h2>Tax Doc Back</h2>

      <div className='kycImgDiv'>
        <img src={singleKyc.kyc.panDocumentImageBack} alt="View Back Image" width="300" height="200" />
      </div>

      <input 
        type="tel" 
        placeholder='Enter Tax doc number' 
        onChange={(e) => setTaxFrontInput(e.target.value)}
      />

      <button className="kyc-ver-close-btn" onClick={closeTaxDocBackPopup}>
        <i className="fas fa-times"></i>
      </button>
     
    </div>

    {pagePermission.some(el => el === 'kycverificationBtnVerify') &&
      <button className="done-btn" onClick={()=> {verifyTaxFrontMethod();}}>
        Verify
      </button>}
      
  </div>
</>
)}


{/*--@@@@@@@@@@@@@@ Address view @@@@@@@@@--*/}
{isAddressPopupOpen && (
 <>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">
    <div className="kyc-ver-popup-content">
      <h2>Address Proof</h2>

      <div className='kycImgDiv'>
        <img src={singleKyc.kyc.addressProof} alt="View Front Image" width="300" height="200" />
      </div>

      <button className="kyc-ver-close-btn" onClick={closeAddressPopup}>
        <i className="fas fa-times"></i>
      </button>
     
    </div>
    <button className="done-btn" onClick={closeAddressPopup}>
        Done
      </button>
  </div>
</>
)}



{/*----##################-------- All Top BUTTONS POPUPS HERE -----------################--*/}


{/*--@@@@@@@@@@@@@@  Accept button Popup @@@@@@@@@--*/}
{isAcceptPopupOpen && (
<>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">

    <div class="Accept-kyc-ver-popup-content">
        <h2>Confirmation</h2>
        <p>Are you sure want to Accept and Verify KYC?</p>

        <button class="kyc-ver-btn-Accept Accept" onClick={()=>{setAcceptPopupOpen(false); acceptKycMethod()}} >Comform</button>

        <button class="kyc-ver-btn-Accept Cancel" onClick={closeAcceptPopup} >Cancel</button>
       
        <button className="kyc-ver-close-btn" onClick={closeAcceptPopup}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>

</>
)}



{/*--@@@@@@@@@@@@@@  Accept Comform @@@@@@@@@--*/}

{isAcceptComformPopupOpen && (
<>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">

    <div class="Accept-kyc-ver-popup-content">
      <img src={acceptimg} alt="View Front Image" width="100" height="100" />
      <h2>KYC Verified</h2>
       
      <button className="kyc-ver-close-btn" onClick={closeAcceptComformPopup}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>
</>
)}




{/*--@@@@@@@@@@@@@@ Reject Reason @@@@@@@@@--*/}
{isRejectPopupOpen && (
<>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">
    <div className="kyc-ver-popup-content">
      <h2>Reason For Rejection</h2>
      {/* Replace the image with a textarea */}
      <textarea
        className="rejection-reason-textarea"
        placeholder="Type here..........."
        onChange={(e)=> setRejectNote(e.target.value)}
      ></textarea>
      <button className="kyc-ver-close-btn" onClick={closeRejectionPopup}>
        <i className="fas fa-times"></i>
      </button>
    </div>
    <button className="done-btn"onClick={()=>{setRejectSubmitPopupOpen(true); setRejectPopupOpen(false); rejectKycMethod();}} >
      Submit
    </button>
  </div>
</>
)}



{/*--@@@@@@@@@@@@@@ Reject Submit popup @@@@@@@@@--*/}

{isRejectSubmitPopupOpen && (
<>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">

    <div class="Accept-kyc-ver-popup-content">
    <img src={rejectsub} alt="View Front Image" width="100" height="100" />
        <h2 style={{color:'red'}}>KYC Rejected</h2>
       
        <button className="kyc-ver-close-btn" onClick={closeRejectSubmitPopup}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>

</>
)}







{/*--@@@@@@@@@@@@@@  Spam button @@@@@@@@@--*/}
{isSpamPopupOpen && (
<>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">

    <div class="Spam-kyc-ver-popup-content">
        <h2>Spam Detected</h2>
        <p>Are you sure want to Delete or Block Profile?</p>
        <button class="kyc-ver-btn-spam Delete" onClick={()=>{setSpamDeletePopupOpen(true); setSpamPopupOpen(false); spamKycMethod()}}>Delete</button>
        <button class="kyc-ver-btn-spam Block"  onClick={()=>{setSpamBlockPopupOpen(true); setSpamPopupOpen(false); spamKycMethod()}}>Block</button>
        <button className="kyc-ver-close-btn" onClick={closeSpamPopup}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>

</>
)}

{/*--@@@@@@@@@@@@@@  Spam delete @@@@@@@@@--*/}

{isSpamDeletePopupOpen && (
<>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">

    <div class="Spam-kyc-ver-popup-content">
    <img src={spamdelete} alt="View Front Image" width="100" height="100" />
        <h2>Spammer Profile Deleted</h2>
       
        <button className="kyc-ver-close-btn" onClick={closeSpamDeletePopup}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>

</>
)}



{/*--@@@@@@@@@@@@@@  Spam Block @@@@@@@@@--*/}

{isSpamBlockPopupOpen && (
<>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">

    <div class="Spam-kyc-ver-popup-content">
    <img src={spamblock} alt="View Front Image" width="100" height="100" />
        <h2>Spammer Profile Blocked</h2>
       
        <button className="kyc-ver-close-btn" onClick={closeSpamBlockPopup}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>

</>
)}

  </section>
    
          
        </main>
      </section>

      {timeoutPop && <TimeoutPop 
        setTimeoutPop={setTimeoutPop}
      />}

 
    </> 
  );
};

export default Kycverification;
