import React from 'react'; 
import { Navigate, redirect} from 'react-router-dom';

const LogoutRoute = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('expiration'); 
    localStorage.removeItem('reloadCount');  
    localStorage.removeItem('sessionTime'); 
    // return redirect('/loginform');  
    // return <Navigate to={'/login'} />;
    return <Navigate to={'/'} />;
}

export default LogoutRoute;