import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


import '../../Assets/All-Css/Enquiry/Enquiry_View.css';
import Enquiryimg from '../../Assets/Login-img/enquery-1.png';

import '../../Assets/style.css';
import profilepng from '../../Assets/Login-img/Component 1.png';
import { useEffect } from 'react';

import API from '../../api/Api';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { SessionTimer } from '../../util/UtilMethod';
import { getAuthToken, getTokenDuration } from '../../util/Auth';
import TimeoutPop from '../../Componets/TimeoutPop/TimeoutPop';


const Enquiryview = () => {
const navigate = useNavigate();

  // TOKEN EXPIRATION   
  const [timeoutPop, setTimeoutPop] = useState(false);   
  const token = getAuthToken();
  useEffect(() => {
    if(!token) { 
      navigate('/loginform');
      return; 
    }
    if (token === 'EXPIRED') {
      navigate('/loginform');
      return; 
    }
    const tokenDuration = getTokenDuration(); 
    setTimeout(() => {
      navigate('/loginform'); 
    }, tokenDuration);     // 1*60*60*1000   
  }, [token]);  

  useEffect(() => {
    const tokenDuration = getTokenDuration(); 
    const timer = setTimeout(() => {
      setTimeoutPop(true);
    }, (tokenDuration - 15*60*1000));
    return () => clearTimeout(timer);
  }, []);
  // TOKEN EXPIRATION END

const location = useLocation();
const [userId, setUserId] = useState(location.state?.enqId);  


const [isInquiryPopupOpen, setInqueryPopupOpen] = useState(false);  

   // Rejection close
   const closeInqueryPopup = () => {
    setInqueryPopupOpen(false);
  };


  const [sidebarHidden, setSidebarHidden] = useState(false);
  const [allDropdown, setAllDropdown] = useState([]);
  
  // State to manage session time
  const [sessionTime, setSessionTime] = useState(0);
  
  const nav = useNavigate ();
  
  useEffect(() => {
    setAllDropdown(document.querySelectorAll('#sidebar .side-dropdown'));
  }, []);
  
  
  
  useEffect(() => {
    // Attach event listeners for dropdown elements
    allDropdown.forEach((dropdown) => {
      const parentLink = dropdown.closest('li');
      parentLink.addEventListener('click', (e) => handleMenuClick(e, dropdown));
    });
  
    // Set an interval to update the session time every second
    const timerInterval = setInterval(() => {
      setSessionTime((prevTime) => prevTime + 1);
    }, 1000);
  
    // Clean up the interval on component unmount
    return () => {
      clearInterval(timerInterval);
  
      // Remove event listeners
      allDropdown.forEach((dropdown) => {
        const parentLink = dropdown.closest('li');
        parentLink.removeEventListener('click', (e) => handleMenuClick(e, dropdown));
      });
    };
  }, [allDropdown]);
  
  const handleDropdownClick = (e, dropdown) => {
    e.preventDefault();
  
    const isDropdownActive = e.currentTarget.classList.contains('active');
  
    // Close all dropdowns
    allDropdown.forEach((item) => {
      const aLink = item.parentElement.querySelector('a:first-child');
      aLink.classList.remove('active');
      item.classList.remove('show');
    });
  
    // Toggle the clicked dropdown
    e.currentTarget.classList.toggle('active', !isDropdownActive);
    dropdown.classList.toggle('show', !isDropdownActive);
  };
  
  const handleToggleSidebar = () => {
    setSidebarHidden((prevSidebarHidden) => !prevSidebarHidden);
  };
  
  const handleProfileClick = () => {
    const profileLink = document.querySelector('.profile-link');
    profileLink.classList.toggle('show');
  };
  
  const handleMenuClick = (e, dropdown) => {
    e.preventDefault();
    dropdown.classList.toggle('show');
  };
  
  // Convert seconds to HH:MM:SS format
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
  
    const pad = (num) => (num < 10 ? '0' + num : num);
  
    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  };



  // API DATA //////////////////////////////////////
  const sessionTimer = SessionTimer();
  const [enquiryDetail, setEnquiryDetail] = useState([]);
  const [userDetail, setUserDetail] = useState([]);
  const [staffDetail, setStaffDetail] = useState([]);

  const [pagePermission, setPagePermission] = useState([]); 
  const pagePermissionMethod = () => { 
    API.get("/staff/account/role/allow-pages").then((res) => {
      setPagePermission(res.data.data); 
    }).catch((err) => { 
    }).finally(() => {
    });  
  }

  const enquiryDetailMethod = () => {
    API.get("/staff/support/inquiry/get", { 
      params: { id: userId }
    }).then((res) => {
      setEnquiryDetail(res.data.data); 
      setUserDetail(res.data.data.user); 
      setStaffDetail(res.data.data.reply.user);
    }).catch((err) => {
      //toast.error("Something went wrong.", {position:"top-center"}); 
    }); 
  }

  const [formData, setFormData] = useState({
    id: userId,
    subject: '',
    body: '',
  });

  function inputHandler(event) {
    const { name, value } = event.target;     
    setFormData({
      ...formData,
      [name]: value, 
    }); 
  }

  const sendReplyMethod = () => {
    API.post("/staff/support/inquiry/reply", formData).then((res) => {
      setInqueryPopupOpen(false); 
      enquiryDetailMethod();
      toast.success(res.data.message, {position:"top-center"});
      // navigate('/enquirymanage');
    }).catch((err) => {
      setInqueryPopupOpen(false); 
      toast.error("Something went wrong.", {position:"top-center"}); 
    });  
  }

  useEffect(() => {
    pagePermissionMethod(); 
  }, []); 

  useEffect(() => {
    enquiryDetailMethod();
  }, []); 


return (
<>
<section id="sidebar" className={sidebarHidden ? 'hide' : ''}> 
    <a href="#" className="brand">
      <img src={profilepng} alt="Profile Image" className="profile-image" />
      Support
    </a> 

    <ul className="side-menu">
      {pagePermission.some(el => el === 'dashboard') && 
      <li>
        <a href='#' className="active" onClick={()=>{navigate('/dashboard')}} >
          <i className="fas fa-tachometer-alt icon"></i> Dashboard
        </a>
      </li>}

      <li className="divider" data-text="main">
        Main
      </li>

       {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Manage Request----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'ManageRequests') && 
        <a href="#" >
          <i className="fas fa-folder icon fa-fw"></i> Manage Requests
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverification')}}>
              <i className="fas fa-id-card"></i> KYC Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderverification')}}>
              <i className="fas fa-briefcase"></i> Trader Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'recharge') && 
          <li>
            <a href="#" onClick={()=>{navigate('/recharge')}}>
              <i className="fas fa-phone"></i> Recharge Request
            </a> 
          </li>}

          {pagePermission.some(el => el === 'c2c') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2c')}}>
              <i className="fas fa-exchange-alt"></i> C2C Request
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputesattlement') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputesattlement')}}> 
              <i className="fas fa-ban"></i> Dispute Settlement
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'investotp') &&
      <li>
        <a href='#' onClick={()=>{navigate('/investotp')}}>
          <i className="fas fa-fingerprint icon fa-fw"></i>Invest Via OTP
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {/* --@@@@@@@@@@@@@@@@@@@@@@@@---View Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

     
      <li>
        {pagePermission.some(el => el === 'ViewReports') && 
        <a href="#" >
          <i className="fas fa-file-alt icon fa-fw"></i> View Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverifyedreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverifyedreport')}}>
              <i className="fas fa-id-card"></i> KYC Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderreport')}}>
              <i className="fas fa-briefcase"></i> Trader Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'rechargereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/rechargereport')}}>
              <i className="fas fa-donate"></i> Recharge Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'c2creport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2creport')}}>
              <i className="fas fa-money-check-alt"></i> C2C Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputereport')}}>
              <i className="fas fa-handshake"></i> Dispute Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'utrreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/utrreport')}}>
              <i className="fas fa-money-check"></i> UTR Status Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'purchaseReport') && 
          <li>
            <a href="#"onClick={()=>{navigate('/purchaseReport')}}>
              <i className="fas fa-gift"></i> Purchase Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'partnerreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/partnerreport')}}>
              <i className="fas fa-handshake"></i> Partner Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'ViewReports') && 
          <li>
            <a href="#" onClick={()=>{navigate('/noninvestedreport')}}>
              <i className="fas fa-file-alt"></i> Non Invested Report
            </a>
          </li>}

        </ul>
      </li>


    {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Send Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'SendReports') && 
        <a href="#" >
          <i className="fas fa-paper-plane icon fa-fw"></i> Send Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'sendecharge') && 
          <li>
            <a href='#' onClick={()=>{navigate('/sendecharge')}}>
              <i className="fas fa-file-alt"></i> Coin2Cash
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'memberManage') &&
      <li>
        <a href="#" onClick={()=>{nav('/memberManage')}} >
          <i className="fas fa-users icon fa-fw"></i> Manage Members
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'blockuser') &&
      <li>
        <a href="#" onClick={()=>{nav('/blockuser')}} >
          <i className="fas fa-user-circle icon fa-fw"></i> Block User
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'enquirymanage') &&
      <li>
        <a href="#" onClick={()=>{navigate('/enquirymanage')}}>
          <i className="fas fa-briefcase icon fa-fw"></i> Manage Enquiry
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'update') &&
      <li>
        <a href="#" onClick={()=>{navigate('/update')}}>
          <i className="fas fa-tools icon fa-fw"></i> Update Manager
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}
      
      {pagePermission.some(el => el === 'ChatSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/ChatSupport')}}>
          <i className="fas fa-comment-alt icon fa-fw"></i>Chat Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'TicketSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/TicketSupport')}}>
          <i className="fas fa-chalkboard-teacher icon fa-fw"></i>Ticket Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'feedback') &&
      <li>
        <a href='#' onClick={()=>{navigate('/feedback')}}> 
          <i className="fas fa-comment-medical icon fa-fw"></i>Feedbacks
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      <li className="divider" data-text="Exit">
        Exit
      </li>

      <div className="ads">
        <button className="Btn" onClick={()=>{navigate('/loginform')}}>
          <div className="sign">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text">Logout</div>
        </button>
      </div>
    </ul> 
  </section> 


{/* MAIN CONTENT */} 
  <section id="content">
    <nav>
      <i className="bx bx-menu toggle-sidebar" onClick={handleToggleSidebar}></i>
      <form action="#">
        <div className="form-group">
          <span>Session Time: {formatTime(sessionTimer)}</span>
        </div>
      </form>
       
      <div className='notify'>
       <i className="fas fa-bell"></i>
     </div>


      <span className="divider"></span>
{/* --@@@@@@@@@@@@@@@@@@@@@@@@---Profile----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 
      <div className="profile">
        <img
          src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-File.png"
          onClick={handleProfileClick}
          alt="Profile"
        />
        <ul className="profile-link">
          <li>
            <a href="" onClick={()=>{navigate('/selfprofile')}}>
              <i className="bx bxs-user-circle icon"></i> Profile
            </a>
          </li>
 {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Logout----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 

          <li>
            <a href="#" onClick={()=>{navigate('/loginform')}}>
              <i className="bx bxs-log-out-circle"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>

        <main>
          {/* {props.passedComponent} */}
 
          <div className="Enquiry-view-main-updated">
        <section className="Enquiry-view-back_btn-updated" >
            <div><a href="#" onClick={()=>{navigate('/enquirymanage')}}><span><i className="fa fa-chevron-left"></i></span>Back</a></div>
        </section>

        {/* <!--# SECTION --> */}
        <section className="Enquiry-view-section-updated">
            <div className="Enquiry-view-Page_container-updated">
                <div className="Enquiry-view-off-updated">

                    <div className="Enquiry-view-off-updated">
                        <div className="Enquiry-view-image-updated"><img src={Enquiryimg} alt="this is an image" /></div>
                        <div className="Enquiry-view-image-updated"><h3>Enquiry Manager</h3> </div>
                    </div>

                </div>

                <div className="Enquiry-view-table-content">
                    <div className="Enquiry-view-table-content-1">
        
                        <div className="Enquiry-view-login-section">
        
                            <div className="Enquiry-view-content">
                               <label for="username" id="username">User ID</label>
                               <input type="text" name="username" value={userDetail.referralCode} id="username" disabled/>
                            </div>
             
                            <div className="Enquiry-view-content">
                             <label for="email" id="email">Email ID</label>
                             <input type="email" name="email" value={userDetail.email} id="email" disabled/>
                           </div>
             
                           <div className="Enquiry-view-content">
                             <label for="mobiel-no" id="mobiel-no">Mobile No</label>
                             <input type="number" name="mobiel-no" value={userDetail.mobile} id="mobiel-no" disabled/>
                           </div>
             
                           <div className="Enquiry-view-content">
                             <label for="date" id="date">Date</label>
                             <input 
                              type="date" 
                              name="date" 
                              id="date"
                              value={moment(enquiryDetail.createdAt).format('YYYY-MM-DD')}
                              disabled
                            />
                           </div>
             
                           <div className="Enquiry-view-content">
                             <label for="time" id="username">Time</label>
                             <input 
                              type="time" 
                              name="time" 
                              id="time" 
                              value={moment(enquiryDetail.createdAt).format('hh:mm:ss')}
                              disabled
                            />
                           </div>
                             
                         </div>
        
                         <div className="Enquiry-view-subject-section">
                            <label for="name">Subject</label>
                            <input 
                              type="text" 
                              value={enquiryDetail.title}
                              disabled
                            />
                        </div>  
        
                        <div className="description-section">
                            <label for="description">Description</label>
                            <textarea 
                              name="description" 
                              id="" cols="110" rows="8" 
                              placeholder={enquiryDetail.message}
                              disabled
                            ></textarea>
                        </div>
                        
                        {/* STAFF REPLIED */}
                        {pagePermission.some(el => el === 'enquiryviewReplied') &&
                        <div className="description-section">
                            <label for="description" style={{position:'relative', top:'0rem'}}>Replied</label> 

                            {enquiryDetail.reply && 
                            <span style={{position:'relative', right: '0rem', top: '0rem'}}>
                              {moment(enquiryDetail.updatedAt).format('YYYY-MM-DD')}&nbsp;&nbsp; {moment(enquiryDetail.updatedAt).format('hh:mm:ss')}
                            </span>
                            }
                            
                            {enquiryDetail.reply && 
                            <textarea 
                              name="description" 
                              id="" cols="110" rows="8" 
                              placeholder={enquiryDetail.reply?.body}
                              disabled
                            ></textarea>}

                        </div>}
                    </div>
        
                    <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                    {/* USER CREATED BY */}
                    <div className="Enquiry-view-table-content-2 create-reply-by-div">
                        <div className="Enquiry-view-title">
                            <h2>Create by</h2>
                        </div>
                        
                        <div className="Enquiry-view-date-time">
                            <span>{moment(enquiryDetail.createdAt).format('DD-MM-YYYY')}</span>
                            <span>{moment(enquiryDetail.createdAt).format('hh:mm:ss')}</span>
                        </div>
        
                        <table>
                            <tr>
                                <th><strong>Member Detail</strong></th>
                                <th></th>
                            </tr>

                            <tr>
                                <td><strong>Member ID:</strong></td>
                                <td>{userDetail.referralCode}</td>
                            </tr>
                            <tr>
                                <td><strong>Member Name:</strong></td>
                                <td>{userDetail.name}</td>
                            </tr>
                            <tr>
                                <td><strong>Mobiel No:</strong></td>
                                <td>{userDetail.mobile}</td>
                            </tr>
                            <tr>
                                <td><strong>Email ID:</strong></td>
                                <td>{userDetail.email}</td>
                            </tr>
                        </table>
                    </div>


                    {/* STAFF REPLIED BY::: style={{position: 'absolute', bottom: '4rem', right: '3.8rem', width: '430px'}} ::: */}
                    {(pagePermission.some(el => el === 'enquiryviewReplied') &&  enquiryDetail.reply) && 
                    <div className="Enquiry-view-table-content-2 create-reply-by-div">
                        <div className="Enquiry-view-title">
                            <h2>Replied by</h2>
                        </div>
                        
                        <div className="Enquiry-view-date-time">
                            <span>{moment(enquiryDetail.updatedAt).format('DD-MM-YYYY')}</span>
                            <span>{moment(enquiryDetail.updatedAt).format('hh:mm:ss')}</span>
                        </div>
        
                        <table>
                            <tr>
                                <th><strong>Staff Detail</strong></th>
                                <th></th>
                            </tr>

                            <tr>
                                <td><strong>Staff ID:</strong></td>
                                <td>{staffDetail?.referralCode}</td>
                            </tr>
                            <tr>
                                <td><strong>Staff Name:</strong></td>
                                <td>{staffDetail?.name}</td>
                            </tr>
                            <tr>
                                <td><strong>Mobiel No:</strong></td>
                                <td>{staffDetail?.mobile}</td>
                            </tr>
                            <tr>
                                <td><strong>Email ID:</strong></td>
                                <td>{staffDetail?.email}</td>
                            </tr>
                        </table>
                    </div>
                    }
                    </div>


                  </div>

            </div>
        </section>

          {pagePermission.some(el => el === 'enquirymanageBtnReply') && 
          <div className="Enquiry-view-button" onClick={()=>{setInqueryPopupOpen(true);}}>
            <button>Reply</button>
          </div>}
        

    </div>




 {/*--@@@@@@@@@@@@@@ Inquery Popup @@@@@@@@@--*/}
{isInquiryPopupOpen && (
  <>
    <div className="Enquiry-view-overlay"></div>
    <div className="Enquiry-view-popup">
      <div className="Enquiry-view-popup-content">
        <div className='Enquiry-view-poptop'>
          <div>To : {enquiryDetail.email}</div>
          <div>Replied At : {moment(enquiryDetail.createdAt).format('DD-MM-YYYY')}    {moment(enquiryDetail.createdAt).format('hh:mm a')}</div>
        </div>

        {/* Additional Input Field */}
        <div className="Enquiry-view-additional-input">
          <label htmlFor="additionalInput">Subject:</label>
          <input
            type="text"
            id="additionalInput"
            placeholder="Subject 1"
            name="subject"
            onChange={inputHandler}
            required
          />
        </div>

        {/* Replace the image with a textarea */}
        <textarea
          className="Enquiry-view-rejection-reason-textarea"
          placeholder="Reply"
          name="body"
          onChange={inputHandler}
          required
        ></textarea>
        <button className="Enquiry-view-close-btn" onClick={closeInqueryPopup}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <button className="Enquiry-view-done-btn" type='button' onClick={()=> {sendReplyMethod()}}>
        Send
      </button>
    </div>
  </>
)}
          
        </main>
      </section>

      {timeoutPop && <TimeoutPop
        setTimeoutPop={setTimeoutPop}
      />}

</>

    
  );
};

export default Enquiryview;
