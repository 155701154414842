// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.star-rate-img {
    width: 120px;
    height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Feedback/Feedback.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,YAAY;AAChB","sourcesContent":["\r\n.star-rate-img {\r\n    width: 120px;\r\n    height: 50px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
