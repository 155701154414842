import React from 'react';
import '../Self-Transfer/Self_Transfer.css';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';
import moment from 'moment';

const Selftransfer = ({listTxnSelf, loading, pagingCounter, setInnerTab}) => { 
  return (
   <>
     <div className="self-table-content">      
             <div className="self-head-button">
                 <button className="self-head-button-1" onClick={()=> setInnerTab(1)}>Self Transfer</button>
                 <button className="self-head-button-2" onClick={()=> setInnerTab(2)}>Transfer Records</button>
             </div>
     
             <div className="self-table-content-1">   
                 <table>
                     <thead>
                         <tr>
                           <th>Sl.</th>
                            <th>Member ID</th>
                             <th>Coins</th>
                             <th>Type</th>
                             <th>TR</th>
                             <th>txn.ID</th>
                             <th>Date</th>
                            <th>Time</th>   
                         </tr>
                     </thead>

                     <tbody>
                        {loading && <LoadingSpinner />}
                        {listTxnSelf.map((data, index) => {
                        return(
                         <tr key={index}>
                            <td>{index+pagingCounter}</td>  
                            <td>{data.fromUser.referralCode}</td>
                            <td>{parseFloat(data.amount).toFixed(2)}</td>
                            <td>{data.type}</td>
                            <td
                            className={`${data.amount < 0 ? 'red-td' : 'Green-td'}`}
                            >
                              {data.amount < 0 ? 'DR.' : 'CR.'}
                            </td>
                            <td>--</td>
                            <td>{moment(data.createdAt).format('DD-MM-YYYY')}</td>
                            <td>{moment(data.createdAt).format('hh:mm:ss')}</td> 
                         </tr>
                        )})}
                     </tbody>
                 </table>
             </div> 
           </div>
           </>
  );
};

export default Selftransfer;
