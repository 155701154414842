import React from 'react';
import '../Sell-Trade/Sell-Trade.css';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Pagination from '../../Pagination/Pagination';
import moment from 'moment';

const SellTrade = ({listSell, loading, pagingCounter}) => {
    return (
        <div className="Sell-Trade-TABLE-Table-updated">
          <table className="Sell-Trade-TABLE-Tpage-table">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Sell Crypto</th>
                <th>Got</th>
                <th>From</th>
                <th>Payment</th>
                <th>Date</th>
                <th>Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {loading && <LoadingSpinner />}
              {listSell.map((data, index) => {
              return(
              <tr key={index}>
                <td>{index+pagingCounter}</td>
                <td>{parseFloat(data.cryptoAmount).toFixed(2)} {data.cryptoSymbol}</td>
                <td>{parseFloat(data.amount).toFixed(2)}</td>
                <td>{data.from}</td>
                <td>{data.payment ? data.payment.upi : '--'}</td>
                <td>{moment(data.updatedAt).format('DD-MM-YYYY')}</td>
                <td>{moment(data.updatedAt).format('hh:mm:ss')}</td>
                <td>Complete</td>
              </tr>
              )})}
            </tbody>
          </table>
        </div>
    );
  }


export default SellTrade;
