// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
    display: flex;
    text-decoration: none;
    margin-top: 10px;
}

.page-item {
    border: 0.5px solid #D9D9D9;
    color: #007BFF;
    font-weight: 500;
    padding: 2px 10px; 
    cursor: pointer;
}

.page-item a {
    text-decoration: none;
}

.page-item a:hover {
    color: #ffffff;
}

.page-item:hover {
    background-color: #007BFF;
    color: #ffffff;
}

.page-active {
    background-color: #007BFF;
    color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/Componets/Pagination/Pagination.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":[".pagination {\r\n    display: flex;\r\n    text-decoration: none;\r\n    margin-top: 10px;\r\n}\r\n\r\n.page-item {\r\n    border: 0.5px solid #D9D9D9;\r\n    color: #007BFF;\r\n    font-weight: 500;\r\n    padding: 2px 10px; \r\n    cursor: pointer;\r\n}\r\n\r\n.page-item a {\r\n    text-decoration: none;\r\n}\r\n\r\n.page-item a:hover {\r\n    color: #ffffff;\r\n}\r\n\r\n.page-item:hover {\r\n    background-color: #007BFF;\r\n    color: #ffffff;\r\n}\r\n\r\n.page-active {\r\n    background-color: #007BFF;\r\n    color: #ffffff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
