import React, { useState } from 'react'; 
import './InvestCoinPop.css'; 
import crossIcon from '../../Assets/Member-img/crossIcon.png'; 

const InvestCoinPop = ({setShowInvestCoinPop, setShowOtpVerifyPop, setInvestData}) => {
  const [formData, setFormData] = useState({
    amount: '',
  });

  function inputHandler(event) {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value, 
    }); 
  }
 
  function addFormSubmit(event) { 
    event.preventDefault(); 
    setInvestData((prev) => [...prev, formData]);   
    setShowInvestCoinPop(false);
    setShowOtpVerifyPop(true); 
  }

  return (
    <article className='InvestCoinPop'>
        <h6 className='pop-title'>Invest Coin</h6>
        <img className='pop-cross' src={crossIcon} alt="Cross" onClick={()=>setShowInvestCoinPop(false)} />
        <form onSubmit={(e) => addFormSubmit(e)}>
          <div className='input-main2'>
            <div className='input-div2'>
              <label htmlFor="title">Transaction Type</label>
              <input type="text" name="title" placeholder='Invest' readOnly/> 
            </div>

            <div className='input-div2'>
              <label htmlFor="title">Coin Quantity</label>
              <input 
                type="number" 
                name="amount" 
                placeholder='Enter Coin Quantity' 
                onChange={inputHandler}
                required
              />
            </div>
          </div>

          <div className='input-main input-main3'>
            <button type='submit' className='btn-trfr'>Invest</button>
            <button type='button' className='btn-cncl' onClick={()=>setShowInvestCoinPop(false)}>Cancel</button>
          </div>
        </form>
    </article>
  )
}

export default InvestCoinPop; 