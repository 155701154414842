import React, { useEffect, useState } from 'react';
import './TimeoutPop.css'; 
import horizonLine from '../../Assets/Dashboard-img/horizonLine.png';
import bellIcon from '../../Assets/Dashboard-img/Notification.png';
import okBtn from '../../Assets/Dashboard-img/okBtn.png';
import { useNavigate } from 'react-router-dom';


const TimeoutPop = ({setTimeoutPop}) => {
    const [minutes, setMinutes] = useState(14); 
    const [seconds, setSeconds] = useState(59);

    useEffect(() => {
        const interval = setInterval(() => { 
            if(seconds > 0) {
                setSeconds(seconds - 1); 
            }

            if(seconds === 0) {
                if(minutes === 0) {
                    clearInterval(interval); 
                    setTimeoutPop(false);
                } else {
                    setSeconds(59);
                    setMinutes(minutes -1); 
                }
            }
        }, 1000);

        return () => { clearInterval(interval); };
    }, [seconds]);


  return (
    <article className='TimeoutPop'>
        <h4 className='log-title1'>Logout Notification</h4>
        <img src={horizonLine} alt="line" />

        <form className='form-main'>
            <div className='bell-div'>
                <img src={bellIcon} alt="bell" />
                <h5>Your Session will be logged out in</h5>
                <div className='time-div'>
                    <div>00</div>&nbsp;&nbsp;<span>:</span>&nbsp;&nbsp;
                    <div>{minutes < 10 ? `0${minutes}` : minutes}</div>&nbsp;&nbsp;<span>:</span>&nbsp;&nbsp;
                    <div>{seconds < 10 ? `0${seconds}` : seconds}</div>
                </div>
                <img src={okBtn} alt="button" className='bell-btn' onClick={()=> setTimeoutPop(false)}/>
            </div>
        </form>
    </article>
  )
}

export default TimeoutPop;