// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-div1 {
    display: flex;
    gap: 1rem;
}

.mem-title {
    font-size: 14px !important;
    position: relative;
    bottom: 0.7rem;
    right: 0.6rem;
}

.radio-inn {
    width: 18px !important;
    box-shadow: none !important;
    cursor: pointer !important;
}

.expired-color {
    color: #ee243c !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/View_Report/NonInvestedReport.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,2BAA2B;IAC3B,0BAA0B;AAC9B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".radio-div1 {\r\n    display: flex;\r\n    gap: 1rem;\r\n}\r\n\r\n.mem-title {\r\n    font-size: 14px !important;\r\n    position: relative;\r\n    bottom: 0.7rem;\r\n    right: 0.6rem;\r\n}\r\n\r\n.radio-inn {\r\n    width: 18px !important;\r\n    box-shadow: none !important;\r\n    cursor: pointer !important;\r\n}\r\n\r\n.expired-color {\r\n    color: #ee243c !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
