import moment from "moment";
import { useEffect, useState } from "react";

export function getRemainingDaysHrMinSec(expiresOn) {
  const targetDate = moment(expiresOn);   // '2024-09-13T19:33:57.200Z'
  const now = moment();

  const difference = targetDate.diff(now);
  const duration = moment.duration(difference);

  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if(days < 0) {
    return 'Expired';
  }else {
    return (`${days}D ${hours}H:${minutes}M:${seconds}S`);
  }
}

export function getRemainingTime(createdAt, timeMin) {
    const endDate = new Date(createdAt);
    endDate.setMinutes(endDate.getMinutes() + timeMin);
    const endDateString = endDate.toISOString();

    const currentDate = new Date();
    const currentDateString = currentDate.toISOString(); 

    // const subsTime = Math.abs(new Date(currentDateString).getTime() - new Date(endDateString).getTime()).toString();
    // const remainTimeMin = Math.round(subsTime / 60000);
    // return remainTimeMin;
    
    // const timestamp = 1652850400000;
    // const processTime = moment.unix(timestamp).format("hh:mm:ss");
    // return processTime;

    const timeDiff = moment.utc(moment(currentDateString).diff(moment(endDateString))).format("hh:mm:ss");
    return timeDiff;
}

export function LevelStatus(level) {
    const statusValue = Math.pow(2, (+level + 1)) - 1;    // 2^(level + 1) - 1
    return statusValue;
}


  export const getRemainingMinutesOnly = (createdAt, timeMin) => {
    const endDate = new Date(createdAt);
    endDate.setMinutes(endDate.getMinutes() + timeMin);
    const endDateString = endDate.toISOString();

    const currentDate = new Date();
    const currentDateString = currentDate.toISOString();

    const subsTime = Math.abs(new Date(currentDateString).getTime() - new Date(endDateString).getTime()).toString();

    const remainTimeMin = Math.round(subsTime / 60000);
    return remainTimeMin;
  }

  export const SessionTimer = () => {
    const [sessionTime, setSessionTime] = useState(
      parseInt(localStorage.getItem('sessionTime')) || 0
    );
  
    useEffect(() => {
      const interval = setInterval(() => {
        setSessionTime(prevTime => {
          const updatedTime = prevTime + 1;
          localStorage.setItem('sessionTime', updatedTime.toString());
          return updatedTime;
        });
      }, 1000); // Update every 1000ms (1 second)
  
      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    }, []);

    return localStorage.getItem('sessionTime');
  }
