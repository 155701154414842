import React, { useState, useEffect } from 'react';
import '../Pages/Dashboard.css';
import profilepng from '../Assets/Login-img/Component 1.png';

import dash1 from '../../src/Assets/Dashboard-img/dash1.png';
import dash2 from '../../src/Assets/Dashboard-img/dash2.png';
import dash3 from '../../src/Assets/Dashboard-img/dash3.png';
import dash4 from '../../src/Assets/Dashboard-img/dash4.png';
import dash5 from '../../src/Assets/Dashboard-img/dash5.png';
import dash6 from '../../src/Assets/Dashboard-img/dash6.png';

import coin1 from '../../src/Assets/Dashboard-img/coin1.png';
import coin2 from '../../src/Assets/Dashboard-img/coin2.png';
import coin3 from '../../src/Assets/Dashboard-img/coin3.png';
import coin4 from '../../src/Assets/Dashboard-img/coin4.png';

import tickt1 from '../../src/Assets/Dashboard-img/Opening.png';
import tickt2 from '../../src/Assets/Dashboard-img/Waiting.png';
import tickt3 from '../../src/Assets/Dashboard-img/Close.png';
import enq1 from '../../src/Assets/Dashboard-img/Email.png';
import enq2 from '../../src/Assets/Dashboard-img/Reply.png';
import enq3 from '../../src/Assets/Dashboard-img/Message.png';


import API from '../api/Api';
import LoadingSpinner from '../Componets/LoadingSpinner/LoadingSpinner';  
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import { SessionTimer } from '../util/UtilMethod';
import { useNavigate } from 'react-router-dom';
import TimeoutPop from '../Componets/TimeoutPop/TimeoutPop';
import { getAuthToken, getTokenDuration } from '../util/Auth'; 

const Dashboard = () => { 
  // TOKEN EXPIRATION
  const navigate = useNavigate();   
  const [timeoutPop, setTimeoutPop] = useState(false);    
  const token = getAuthToken();
  useEffect(() => {
    if(!token) { 
      navigate('/loginform');
      return; 
    }
    if (token === 'EXPIRED') {
      navigate('/loginform');
      return; 
    }
    const tokenDuration = getTokenDuration();  
    setTimeout(() => {
      navigate('/loginform'); 
    }, tokenDuration);     // 1*60*60*1000   
  }, [token]);  

  useEffect(() => {
    const tokenDuration = getTokenDuration(); 
    const timer = setTimeout(() => {
      setTimeoutPop(true);
    }, (tokenDuration - (15*60*1000)));
    return () => clearTimeout(timer);
  }, []);
  // TOKEN EXPIRATION END 

  // API RELOAD START ////////////////
    const reloadCount = localStorage.getItem('reloadCount');
    useEffect(() => {
      if(reloadCount < 2) {
        localStorage.setItem('reloadCount', 2); 
        window.location.reload();
      } else {
        localStorage.setItem('reloadCount', 2); 
      }
    }, []);
  // API RELOAD END /////////////////
  
    const [sidebarHidden, setSidebarHidden] = useState(false);
    const [allDropdown, setAllDropdown] = useState([]);
  
    // State to manage session time
    const [sessionTime, setSessionTime] = useState(0);
  
    const nav = useNavigate();
  
    useEffect(() => {
      setAllDropdown(document.querySelectorAll('#sidebar .side-dropdown'));
    }, []);
  
  
  
    useEffect(() => {
      // Attach event listeners for dropdown elements 
      allDropdown.forEach((dropdown) => {
        const parentLink = dropdown.closest('li');
        parentLink.addEventListener('click', (e) => handleMenuClick(e, dropdown));
      });
  
      // Set an interval to update the session time every second
      const timerInterval = setInterval(() => {
        setSessionTime((prevTime) => prevTime + 1);
      }, 1000);
  
      // Clean up the interval on component unmount
      return () => {
        clearInterval(timerInterval);
  
        // Remove event listeners
        allDropdown.forEach((dropdown) => {
          const parentLink = dropdown.closest('li');
          parentLink.removeEventListener('click', (e) => handleMenuClick(e, dropdown));
        });
      };
    }, [allDropdown]);
  
    const handleDropdownClick = (e, dropdown) => {
      e.preventDefault();
  
      const isDropdownActive = e.currentTarget.classList.contains('active');
  
      // Close all dropdowns
      allDropdown.forEach((item) => {
        const aLink = item.parentElement.querySelector('a:first-child');
        aLink.classList.remove('active');
        item.classList.remove('show');
      });
  
      // Toggle the clicked dropdown
      e.currentTarget.classList.toggle('active', !isDropdownActive);
      dropdown.classList.toggle('show', !isDropdownActive);
    };
  
    const handleToggleSidebar = () => {
      setSidebarHidden((prevSidebarHidden) => !prevSidebarHidden);
    };
  
    const handleProfileClick = () => {
      const profileLink = document.querySelector('.profile-link');
      profileLink.classList.toggle('show');
    };
  
    const handleMenuClick = (e, dropdown) => {
      e.preventDefault();
      dropdown.classList.toggle('show');
    };
  
    // Convert seconds to HH:MM:SS format
    const formatTime = (seconds) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
  
      const pad = (num) => (num < 10 ? '0' + num : num);
  
      return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
    };


    // API DATA //////////////////////////////////////
    const sessionTimer = SessionTimer();
    const [loading, setLoading] = useState(false);
    const [kycCounts, setKycCounts] = useState([]);
    const [rechargeCounts, setRechargeCounts] = useState([]);
    const [c2cCounts, setC2cCounts] = useState([]);
    const [purchaseCounts, setPurchaseCounts] = useState([]);
    const [traderCounts, setTraderCounts] = useState([]);
    const [chatCounts, setChatCounts] = useState([]);
    const [disputeCounts, setDisputeCounts] = useState([]); 
    const [ticketCounts, setTicketCounts] = useState([]);
    const [inquiryCounts, setInquiryCounts] = useState([]);
    const [utrCounts, setUtrCounts] = useState([]);

    const [pagePermission, setPagePermission] = useState([]); 
    const pagePermissionMethod = () => { 
      API.get("/staff/account/role/allow-pages").then((res) => {
        setPagePermission(res.data.data); 
      }).catch((err) => { 
      }).finally(() => {
      });  
    }

    const listKycMethod = () => { 
      setLoading(true);
      API.get("/staff/counter/user/kyc").then((res) => {
        setKycCounts(res.data.data); 
      }).catch((err) => {
        // toast.error("Something went wrong.", {position:"top-center"}); 
      }).finally(() => {
        setLoading(false);
      });  
    }

    const listRechargeMethod = () => { 
      setLoading(true);
      API.get("/staff/counter/transactions/recharge").then((res) => {
        setRechargeCounts(res.data.data); 
      }).catch((err) => {
        // toast.error("Something went wrong.", {position:"top-center"}); 
      }).finally(() => {
        setLoading(false);
      });  
    }

    const listC2cMethod = () => { 
      setLoading(true);
      API.get("/staff/counter/transactions/withdraw").then((res) => {
        setC2cCounts(res.data.data); 
      }).catch((err) => {
        // toast.error("Something went wrong.", {position:"top-center"}); 
      }).finally(() => {
        setLoading(false);
      });  
    }

    const listPurchaseMethod = () => { 
      setLoading(true);
      API.get("/staff/counter/transactions/buy").then((res) => {
        setPurchaseCounts(res.data.data); 
      }).catch((err) => {
        // toast.error("Something went wrong.", {position:"top-center"}); 
      }).finally(() => {
        setLoading(false);
      });  
    }

    const listTraderMethod = () => { 
      setLoading(true);
      API.get("/staff/counter/trader/verification").then((res) => {
        setTraderCounts(res.data.data); 
      }).catch((err) => {
        // toast.error("Something went wrong.", {position:"top-center"}); 
      }).finally(() => {
        setLoading(false);
      });  
    }

    const listChatMethod = () => { 
      setLoading(true);
      API.get("/staff/chat/count/get").then((res) => {
        setChatCounts(res.data.data); 
      }).catch((err) => {
        // toast.error("Something went wrong.", {position:"top-center"}); 
      }).finally(() => {
        setLoading(false);
      });  
    }

    const listDisputeMethod = () => { 
      setLoading(true);
      API.get("/staff/counter/dispute").then((res) => {
        setDisputeCounts(res.data.data); 
      }).catch((err) => {
        // toast.error("Something went wrong.", {position:"top-center"}); 
      }).finally(() => {
        setLoading(false);
      });  
    }

    const listTicketMethod = () => { 
      setLoading(true);
      API.get("/staff/counter/support/ticket").then((res) => {
        setTicketCounts(res.data.data); 
      }).catch((err) => {
        // toast.error("Something went wrong.", {position:"top-center"}); 
      }).finally(() => {
        setLoading(false);
      });  
    }

    const listInquiryMethod = () => { 
      setLoading(true);
      API.get("/staff/counter/support/inquiry").then((res) => {
        setInquiryCounts(res.data.data); 
      }).catch((err) => {
        // toast.error("Something went wrong.", {position:"top-center"}); 
      }).finally(() => {
        setLoading(false);
      });  
    }

    const listUtrMethod = () => { 
      setLoading(true);
      API.get("/staff/counter/utr/status").then((res) => {
        setUtrCounts(res.data.data); 
      }).catch((err) => {
        // toast.error("Something went wrong.", {position:"top-center"}); 
      }).finally(() => {
        setLoading(false);
      });  
    }

    useEffect(() => {
      pagePermissionMethod(); 
      listKycMethod();
      listRechargeMethod();
      listC2cMethod();
      listPurchaseMethod(); 
      listTraderMethod();
      listChatMethod();
      listDisputeMethod(); 
      listTicketMethod();
      listInquiryMethod();
      listUtrMethod(); 
    }, []); 
    

  return (
  <>
  <section id="sidebar" className={sidebarHidden ? 'hide' : ''}> 
    <a href="#" className="brand">
      <img src={profilepng} alt="Profile Image" className="profile-image" />
      Support
    </a> 

    <ul className="side-menu">
      {pagePermission.some(el => el === 'dashboard') && 
      <li>
        <a href='#' className="active" onClick={()=>{navigate('/dashboard')}} >
          <i className="fas fa-tachometer-alt icon"></i> Dashboard
        </a>
      </li>}

      <li className="divider" data-text="main">
        Main
      </li>

       {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Manage Request----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'ManageRequests') && 
        <a href="#" >
          <i className="fas fa-folder icon fa-fw"></i> Manage Requests
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverification')}}>
              <i className="fas fa-id-card"></i> KYC Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderverification')}}>
              <i className="fas fa-briefcase"></i> Trader Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'recharge') && 
          <li>
            <a href="#" onClick={()=>{navigate('/recharge')}}>
              <i className="fas fa-phone"></i> Recharge Request
            </a> 
          </li>}

          {pagePermission.some(el => el === 'c2c') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2c')}}>
              <i className="fas fa-exchange-alt"></i> C2C Request
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputesattlement') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputesattlement')}}> 
              <i className="fas fa-ban"></i> Dispute Settlement
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'investotp') &&
      <li>
        <a href='#' onClick={()=>{navigate('/investotp')}}>
          <i className="fas fa-fingerprint icon fa-fw"></i>Invest Via OTP
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {/* --@@@@@@@@@@@@@@@@@@@@@@@@---View Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

     
      <li>
        {pagePermission.some(el => el === 'ViewReports') && 
        <a href="#" >
          <i className="fas fa-file-alt icon fa-fw"></i> View Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverifyedreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverifyedreport')}}>
              <i className="fas fa-id-card"></i> KYC Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderreport')}}>
              <i className="fas fa-briefcase"></i> Trader Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'rechargereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/rechargereport')}}>
              <i className="fas fa-donate"></i> Recharge Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'c2creport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2creport')}}>
              <i className="fas fa-money-check-alt"></i> C2C Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputereport')}}>
              <i className="fas fa-handshake"></i> Dispute Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'utrreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/utrreport')}}>
              <i className="fas fa-money-check"></i> UTR Status Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'purchaseReport') && 
          <li>
            <a href="#"onClick={()=>{navigate('/purchaseReport')}}>
              <i className="fas fa-gift"></i> Purchase Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'partnerreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/partnerreport')}}>
              <i className="fas fa-handshake"></i> Partner Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'ViewReports') && 
          <li>
            <a href="#" onClick={()=>{navigate('/noninvestedreport')}}>
              <i className="fas fa-file-alt"></i> Non Invested Report
            </a>
          </li>}

        </ul>
      </li>


    {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Send Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'SendReports') && 
        <a href="#" >
          <i className="fas fa-paper-plane icon fa-fw"></i> Send Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'sendecharge') && 
          <li>
            <a href='#' onClick={()=>{navigate('/sendecharge')}}>
              <i className="fas fa-file-alt"></i> Coin2Cash
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'memberManage') &&
      <li>
        <a href="#" onClick={()=>{nav('/memberManage')}} >
          <i className="fas fa-users icon fa-fw"></i> Manage Members
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'blockuser') &&
      <li>
        <a href="#" onClick={()=>{nav('/blockuser')}} >
          <i className="fas fa-user-circle icon fa-fw"></i> Block User
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'enquirymanage') &&
      <li>
        <a href="#" onClick={()=>{navigate('/enquirymanage')}}>
          <i className="fas fa-briefcase icon fa-fw"></i> Manage Enquiry
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'update') &&
      <li>
        <a href="#" onClick={()=>{navigate('/update')}}>
          <i className="fas fa-tools icon fa-fw"></i> Update Manager
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}
      
      {pagePermission.some(el => el === 'ChatSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/ChatSupport')}}>
          <i className="fas fa-comment-alt icon fa-fw"></i>Chat Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'TicketSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/TicketSupport')}}>
          <i className="fas fa-chalkboard-teacher icon fa-fw"></i>Ticket Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'feedback') &&
      <li>
        <a href='#' onClick={()=>{navigate('/feedback')}}> 
          <i className="fas fa-comment-medical icon fa-fw"></i>Feedbacks
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      <li className="divider" data-text="Exit">
        Exit
      </li>

      <div className="ads">
        <button className="Btn" onClick={()=>{navigate('/loginform')}}>
          <div className="sign">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text">Logout</div>
        </button>
      </div>
    </ul> 
  </section> 


  {/* MAIN CONTENT SECTION */} 
  <section id="content">
    <nav>
      <i className="bx bx-menu toggle-sidebar" onClick={handleToggleSidebar}></i>
      <form action="#">
        <div className="form-group">
          <span>Session Time: {formatTime(sessionTimer)}</span>
        </div>
      </form>
       
      <div className='notify'>
       <i className="fas fa-bell"></i>
     </div>


      <span className="divider"></span>
{/* --@@@@@@@@@@@@@@@@@@@@@@@@---Profile----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 
      <div className="profile">
        <img
          src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-File.png"
          onClick={handleProfileClick}
          alt="Profile"
        />
        <ul className="profile-link">
          <li>
            <a href="#" onClick={()=>{navigate('/selfprofile')}}>
              <i className="bx bxs-user-circle icon"></i> Profile
            </a>
          </li>
 {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Logout----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 

          <li>
            <a href="#" onClick={()=>{navigate('/loginform')}}>
              <i className="bx bxs-log-out-circle"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>

<main>
<div className="main-container">

{/* TRADER ACCOUNT VERIFICATION */}
<div className="container">
    <div className="top-title">
      <h2>Trader Account Statement Verification</h2>
    </div>

    <div className="box-container">
        <div className="content-box">
            <div className="content-box-1">
               <div className="content-1">
                   
                  <div className="box-img">
                      <img src={dash1} alt="#"/>
                   </div>

                  <div className="box-item">
                      <h3>Pending Statement Requests</h3>
                      <h1>{traderCounts.pending}</h1>
                      <p><span>{parseFloat(traderCounts.lastHourPercent?.pending).toFixed(2)}%</span> in Past Hour</p>
                  </div>  

               </div>

               <div className="content-1">
                   
                   <div className="box-img">
                      <img src={dash4} alt="#"/>
                   </div>

                   <div className="box-item">
                      <h3>Approved Statement Requests</h3>
                      <h1>{traderCounts.approved}</h1>
                      <p><span>{parseFloat(traderCounts.lastHourPercent?.approved).toFixed(2)}%</span> in Past Hour</p>
                  </div> 

               </div>

               <div className="content-1">
                   
                  <div className="box-img">
                     <img src={dash6} alt="#"/>
                  </div>

                  <div className="box-item">
                      <h3>Rejected Statement Requests</h3>
                      <h1>{traderCounts.rejected}</h1>
                      <p><span>{parseFloat(traderCounts.lastHourPercent?.rejected).toFixed(2)}%</span> in Past Hour</p>
                  </div> 

              </div>
            </div>

            {pagePermission.some(el => el === 'dashboardBtnTraderRequests') && 
            <div className="content-box-2">
                <div className="content-button">
                  <button onClick={()=>{navigate('/traderverification')}}>View Request</button>
                </div>
            </div>}

        </div>
    </div>
</div>


{/* KYC Verfication */}
<div className="container">
    <div className="top-title">
        <h2>KYC Verification</h2>
    </div>

    <div className="box-container">

        <div className="content-box">

            <div className="content-box-1">

               <div className="content-1">
                   
                  <div className="box-img">
                      <img src={dash1} alt="#"/>
                   </div>

                  <div className="box-item">
                      <h3>Pending KYC Requests</h3>
                      <h1>{kycCounts.inProcess}</h1>
                      <p><span>{parseFloat(kycCounts.lastHourPercent?.inProcess).toFixed(2)}%</span> in Past Hour</p>
                  </div>  

               </div>

               <div className="content-1">
                   
                   <div className="box-img">
                      <img src={dash4} alt="#"/>
                   </div>

                   <div className="box-item">
                      <h3>Approved KYC Requests</h3>
                      <h1>{kycCounts.approved}</h1>
                      <p><span>{parseFloat(kycCounts.lastHourPercent?.approved).toFixed(2)}%</span> in Past Hour</p>
                  </div> 

               </div>

               <div className="content-1">   
                  <div className="box-img">
                     <img src={dash6} alt="#"/>
                  </div>

                  <div className="box-item">
                      <h3>Rejected KYC Requests</h3>
                      <h1>{kycCounts.rejected}</h1>
                      <p><span>{parseFloat(kycCounts.lastHourPercent?.rejected).toFixed(2)}%</span> in Past Hour</p>
                  </div> 
              </div>
            </div>

            {pagePermission.some(el => el === 'dashboardBtnKycRequests') && 
            <div className="content-box-2">
                <div className="content-button">
                  <button onClick={()=>{navigate('/kycverification')}}>View Request</button>
                </div>
            </div>}

        </div>
    </div>
</div>

{/* Recharge Request */}
<div className="container">
    <div className="top-title">
        <h2>Recharge Requests</h2>
    </div>

    <div className="box-container">

        <div className="content-box">

            <div className="content-box-1">

               <div className="content-1">
                   
                  <div className="box-img">
                      <img src={dash2} alt="#"/>
                   </div>

                  <div className="box-item">
                      <h3>Pending Recharge Requests</h3>
                      <h1>{rechargeCounts.pending}</h1>
                      <p><span>{parseFloat(rechargeCounts.lastHourPercent?.pending).toFixed(2)}%</span> in Past Hour</p>
                  </div>  

               </div>

               <div className="content-1">
                   
                   <div className="box-img">
                      <img src={dash5} alt="#"/>
                   </div>

                   <div className="box-item">
                      <h3>Approved Recharge Requests</h3>
                      <h1>{rechargeCounts.completed}</h1>
                      <p><span>{parseFloat(rechargeCounts.lastHourPercent?.completed).toFixed(2)}%</span> in Past Hour</p>
                  </div> 

               </div>

               <div className="content-1">
                   
                  <div className="box-img">
                     <img src={dash6} alt="#"/>
                  </div>

                  <div className="box-item">
                      <h3>Rejected Recharge Requests</h3>
                      <h1>{rechargeCounts.rejected}</h1>
                      <p><span>{parseFloat(rechargeCounts.lastHourPercent?.rejected).toFixed(2)}%</span> in Past Hour</p>
                  </div> 

              </div>
            </div>

            {pagePermission.some(el => el === 'dashboardBtnRechargeRequests') && 
            <div className="content-box-2">
                <div className="content-button">
                    <button onClick={()=>{navigate('/recharge')}}>View Request</button>
                </div>
            </div>}

        </div>

    </div>
</div>


{/* C2C Requests */}
<div className="container">
    <div className="top-title">
        <h2>C2C Requests</h2>
    </div>

    <div className="box-container">

        <div className="content-box">

            <div className="content-box-1">

               <div className="content-1">
                   
                  <div className="box-img">
                      <img src={dash3} alt="#"/>
                   </div>

                  <div className="box-item">
                      <h3>Pending C2C Requests</h3>
                      <h1>{c2cCounts.pending}</h1>
                      <p><span>{parseFloat(c2cCounts.lastHourPercent?.pending).toFixed(2)}%</span> in Past Hour</p>
                  </div>  

               </div>

               <div className="content-1">
                   
                   <div className="box-img">
                      <img src={dash5} alt="#"/>
                   </div>

                   <div className="box-item">
                      <h3>Approved C2C Requests</h3>
                      <h1>{c2cCounts.completed}</h1>
                      <p><span>{parseFloat(c2cCounts.lastHourPercent?.completed).toFixed(2)}%</span> in Past Hour</p>
                  </div> 

               </div>

               <div className="content-1">
                   
                  <div className="box-img">
                     <img src={dash6} alt="#"/>
                  </div>

                  <div className="box-item">
                      <h3>Rejected C2C Requests</h3>
                      <h1>{c2cCounts.rejected}</h1>
                      <p><span>{parseFloat(c2cCounts.lastHourPercent?.rejected).toFixed(2)}%</span> in Past Hour</p>
                  </div> 

              </div>
            </div>

            {pagePermission.some(el => el === 'dashboardBtnC2cRequests') && 
            <div className="content-box-2">
                <div className="content-button">
                    <button onClick={()=>{navigate('/c2c')}}>View Request</button>
                </div>
            </div>}

        </div>

    </div>

</div>


{/* TICKET REQUESTS */}
<div className="container">
    <div className="top-title">
        <h2>Ticket Requests</h2>
    </div>

    <div className="box-container">

        <div className="content-box">

            <div className="content-box-1">

               <div className="content-1">
                   
                  <div className="box-img">
                      <img src={tickt1} alt="#"/>
                   </div>

                  <div className="box-item">
                      <h3>Open Ticket Requests</h3>
                      <h1>{ticketCounts.open}</h1>
                      <p><span>{parseFloat(ticketCounts.lastHourPercent?.open).toFixed(2)}%</span> in Past Hour</p>
                  </div>  

               </div>

               <div className="content-1">
                   
                   <div className="box-img">
                      <img src={tickt2} alt="#"/>
                   </div>

                   <div className="box-item">
                      <h3>Waiting Ticket Requests</h3>
                      <h1>{ticketCounts.waiting}</h1>
                      <p><span>{parseFloat(ticketCounts.lastHourPercent?.waiting).toFixed(2)}%</span> in Past Hour</p>
                  </div> 

               </div>

               <div className="content-1">
                   
                  <div className="box-img">
                     <img src={tickt3} alt="#"/>
                  </div>

                  <div className="box-item">
                      <h3>Closed Tickets</h3>
                      <h1>{ticketCounts.closed}</h1>
                      <p><span>{parseFloat(ticketCounts.lastHourPercent?.closed).toFixed(2)}%</span> in Past Hour</p>
                  </div> 

              </div>
            </div>

            {pagePermission.some(el => el === 'dashboardBtnTicketRequests') && 
            <div className="content-box-2">
                <div className="content-button">
                    <button onClick={()=>{navigate('/TicketSupport')}}>View Request</button>
                </div>
            </div>}

        </div>
    </div>
</div>


{/* CHAT REQUESTS */}
<div className="container">
    <div className="top-title">
        <h2>Chat Requests</h2>
    </div>

    <div className="box-container">

        <div className="content-box">

            <div className="content-box-1">

               <div className="content-1">
                   
                  <div className="box-img">
                      <img src={dash3} alt="#"/>
                   </div>

                  <div className="box-item">
                      <h3>Pending Chat Requests</h3>
                      <h1>{chatCounts.pending}</h1>
                      <p><span>{chatCounts.lastHourPercent ? parseFloat(chatCounts.lastHourPercent.pending).toFixed(2) : '0'}%</span> in Past Hour</p>
                  </div>  

               </div>

               <div className="content-1">
                   
                   <div className="box-img">
                      <img src={dash5} alt="#"/>
                   </div>

                   <div className="box-item">
                      <h3>Approved Chat Requests</h3>
                      <h1>{chatCounts.approved}</h1>
                      <p><span>{chatCounts.lastHourPercent ? parseFloat(chatCounts.lastHourPercent.approved).toFixed(2) : '0'}%</span> in Past Hour</p>
                  </div> 

               </div>

               <div className="content-1">
                   
                  <div className="box-img">
                     <img src={dash6} alt="#"/>
                  </div>

                  <div className="box-item">
                      <h3>Rejected Chat Requests</h3>
                      <h1>{chatCounts.rejected ? chatCounts.rejected : '0'}</h1>
                      <p><span>{chatCounts.lastHourPercent ? parseFloat(chatCounts.lastHourPercent.rejected).toFixed(2) : '0'}%</span> in Past Hour</p>
                  </div> 

              </div>
            </div>

            {pagePermission.some(el => el === 'dashboardBtnChatRequests') &&
            <div className="content-box-2">
                <div className="content-button">
                    <button onClick={()=>{navigate('/ChatSupport')}}>View Request</button>
                </div>
            </div>}

        </div>
    </div>
</div>


{/* ENQUIRY REQUESTS */}
<div className="container">
    <div className="top-title">
        <h2>Enquiry Response Requests</h2>
    </div>

    <div className="box-container">

        <div className="content-box">

            <div className="content-box-1">

               <div className="content-1">
                   
                  <div className="box-img">
                      <img src={enq1} alt="#"/>
                   </div>

                  <div className="box-item">
                      <h3>Not Replied Enquiry</h3>
                      <h1>{inquiryCounts.withoutReplyCount}</h1>
                      <p><span>{parseFloat(inquiryCounts.lastHourPercent?.withoutReplyCount).toFixed(2)}%</span> in Past Hour</p>
                  </div>  

               </div>

               <div className="content-1">
                   
                   <div className="box-img">
                      <img src={enq2} alt="#"/>
                   </div>

                   <div className="box-item">
                      <h3>Replied Enquiry</h3>
                      <h1>{inquiryCounts.withReplyCount}</h1>
                      <p><span>{parseFloat(inquiryCounts.lastHourPercent?.withReplyCount).toFixed(2)}%</span> in Past Hour</p>
                  </div> 

               </div>

               <div className="content-1">
                   
                  <div className="box-img">
                     <img src={enq3} alt="#"/>
                  </div>

                  <div className="box-item">
                      <h3>Total Enquiry</h3>
                      <h1>{inquiryCounts.totalCount}</h1>
                      <p><span>{parseFloat(inquiryCounts.lastHourPercent?.totalCount).toFixed(2)}%</span> in Past Hour</p>
                  </div> 
              </div>
            </div>

            {pagePermission.some(el => el === 'dashboardBtnEnquiryRequests') &&
            <div className="content-box-2">
                <div className="content-button">
                    <button onClick={()=>{navigate('/enquirymanage')}}>View Request</button>
                </div>
            </div>
            }

        </div>
    </div>
</div>


{/* DISPUTE REQUESTS */}
<div className="container">
    <div className="top-title">
        <h2>Dispute Requests</h2>
    </div>

    <div className="box-container">

        <div className="content-box">

            <div className="content-box-1">

               <div className="content-1">
                   
                  <div className="box-img">
                      <img src={dash3} alt="#"/>
                   </div>

                  <div className="box-item">
                      <h3>Pending Dispute Requests</h3>
                      <h1>{disputeCounts.pending}</h1>
                      <p><span>{parseFloat(disputeCounts.lastHourPercent?.pending).toFixed(2)}%</span> in Past Hour</p>
                  </div>  

               </div>

               <div className="content-1">
                   
                   <div className="box-img">
                      <img src={dash5} alt="#"/>
                   </div>

                   <div className="box-item">
                      <h3>Approved Dispute Requests</h3>
                      <h1>{disputeCounts.approved}</h1>
                      <p><span>{parseFloat(disputeCounts.lastHourPercent?.approved).toFixed(2)}%</span> in Past Hour</p>
                  </div> 

               </div>

               <div className="content-1">
                   
                  <div className="box-img">
                     <img src={dash6} alt="#"/>
                  </div>

                  <div className="box-item">
                      <h3>Rejected Dispute Requests</h3>
                      <h1>{disputeCounts.rejected}</h1>
                      <p><span>{parseFloat(disputeCounts.lastHourPercent?.rejected).toFixed(2)}%</span> in Past Hour</p>
                  </div> 

              </div>
            </div>

            {pagePermission.some(el => el === 'dashboardBtnDisputeRequests') &&
            <div className="content-box-2">
                <div className="content-button">
                    <button onClick={()=>{navigate('/disputesattlement')}}>View Request</button>
                </div>
            </div>}

        </div>
    </div>
</div>


{/* PURCHASE REPORTS */}
<div className="container">

    <div className="top-title">
        <h2>Purchase Reports</h2>
    </div>

    <div className="box-container">

        <div className="content-box">

            <div className="content-box-1">

               <div className="content-1">
                   
                  <div className="box-img">
                      <img src={coin1} alt="#"/>
                   </div>

                  <div className="box-item">
                      <h3>Private VTCN</h3>
                      <h1>{parseFloat(purchaseCounts.privateVtcn).toFixed(2)}</h1>
                  </div>  

               </div>

               <div className="content-1">
                   
                   <div className="box-img">
                      <img src={coin2} alt="#"/>
                   </div>

                   <div className="box-item">
                      <h3>Public VTCN</h3>
                      <h1>{parseFloat(purchaseCounts.publicVtcn).toFixed(2)}</h1>
                  </div> 

               </div>

               <div className="content-1">
                  <div className="box-img">
                     <img src={coin3} alt="#"/>
                  </div>
                  <div className="box-item">
                      <h3>Private Stable</h3>
                      <h1>{parseFloat(purchaseCounts.privateStable).toFixed(2)}</h1>
                  </div> 
              </div>

              <div className="content-1">
                  <div className="box-img">
                     <img src={coin4} alt="#"/>
                  </div>
                  <div className="box-item">
                      <h3>Public Stable</h3>
                      <h1>{parseFloat(purchaseCounts.publicStable).toFixed(2)}</h1>
                  </div> 
              </div>
            </div>

            {pagePermission.some(el => el === 'dashboardBtnPurchaseReports') &&
            <div className="content-box-2">
                <div className="content-button">
                    <button onClick={()=>{navigate('/purchaseReport')}}>View Report</button>
                </div>
            </div>}

        </div>
    </div>
</div>


{/* UTR STATUS REPORTS */}
<div className="container">
    <div className="top-title">
        <h2>UTR Status Reports</h2>
    </div>

    <div className="box-container">

        <div className="content-box">

            <div className="content-box-1">

               <div className="content-1">
                   
                  <div className="box-img">
                      <img src={dash3} alt="#"/>
                   </div>

                  <div className="box-item">
                      <h3>Update</h3>
                      <h1>{utrCounts.totalCount}</h1>
                      <p><span>{parseFloat(utrCounts.lastHourPercent?.totalCount).toFixed(2)}%</span> in Past Hour</p>
                  </div>  

               </div>

               <div className="content-1">
                   
                   <div className="box-img">
                      <img src={dash5} alt="#"/>
                   </div>

                   <div className="box-item">
                      <h3>Used</h3>
                      <h1>{utrCounts.usedCount}</h1>
                      <p><span>{parseFloat(utrCounts.lastHourPercent?.usedCount).toFixed(2)}%</span> in Past Hour</p>
                  </div> 

               </div>

               <div className="content-1">
                   
                  <div className="box-img">
                     <img src={dash6} alt="#"/>
                  </div>

                  <div className="box-item">
                      <h3>Un-Used</h3>
                      <h1>{utrCounts.unusedCount}</h1>
                      <p><span>{parseFloat(utrCounts.lastHourPercent?.unusedCount).toFixed(2)}%</span> in Past Hour</p>
                  </div> 

              </div>
            </div>

            {pagePermission.some(el => el === 'dashboardBtnUtrReports') &&
            <div className="content-box-2">
                <div className="content-button">
                  <button onClick={()=>{navigate('/utrreport')}}>View Report</button>
                </div>
            </div>}

        </div>
    </div>
</div>



</div>         
</main>
</section>

{timeoutPop && <TimeoutPop 
  setTimeoutPop={setTimeoutPop}
/>}

</>
);
};

export default Dashboard;