import React from 'react';
import '../Exchange-Trade/Exchange-Trade.css';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Pagination from '../../Pagination/Pagination';
import moment from 'moment';

const ExchangeTrade = ({listExchange, loading, pagingCounter}) => {
    return (
        <div className="Exchange-Trade-TABLE-Table-updated">
          <table className="Exchange-Trade-TABLE-Tpage-table">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Exchange Crypto</th>
                <th>Recived</th>
                <th>Date</th>
                <th>Time</th>
                <th>Status</th> 
              </tr>
            </thead>

            <tbody>
              {loading && <LoadingSpinner />}
              {listExchange.map((data, index) => {
              return(
              <tr key={index}>
                <td>{index+pagingCounter}</td>
                <td>{parseFloat(data.fromAmount).toFixed(2)} {data.fromSymbol}</td>
                <td>{parseFloat(data.toAmount).toFixed(2)} {data.toSymbol}</td>
                <td>{moment(data.updatedAt).format('DD-MM-YYYY')}</td>
                <td>{moment(data.updatedAt).format('hh:mm:ss')}</td>
                <td>Complete</td>
              </tr>
              )})}

            </tbody>
          </table>
        </div>
    );
  }


export default ExchangeTrade;
