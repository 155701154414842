import React, { useState } from 'react';
import '../Docoments/Trade_Document.css';
import tickCircle from '../../../Assets/Dashboard-img/tickCircle.png'; 

import API from '../../../api/Api';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

const Tradedocuments = ({user, kyc, userId, accountStatement}) => {
    const [receiptPopup, setReceiptPopup] = useState(false);
    const [receiptImg, setReceiptImg] = useState(null);
    const [pdfPopup, setPdfPopup] = useState(false);
    const [verifyNowPopup, setVerifyNowPopup] = useState(false);
    const [rejectPopup, setRejectPopup] = useState(false); 
    const [rejectReason, setRejectReason] = useState('--'); 
    const [isUserVerified, setIsUserVerified] = useState(user.isTraderProfileVerified);

    const closeReceiptPopup = () => {
        setReceiptImg(null); 
        setReceiptPopup(false);  
    }

    const verifyMemberMethod = () => {
        API.post("/staff/market/trader/profile/verify", {userId: userId}).then((res) => {
            setIsUserVerified(true); 
            setVerifyNowPopup(false);
          toast.success(res.data.message, {position:"top-center"}); 
        }).catch((err) => {
          setVerifyNowPopup(false);
          toast.error(err.response.data.message, {position:"top-center"}); 
        });  
    }

    const rejectMemberMethod = () => {
        const body = {
            userId: userId, 
            accountStatementNote: rejectReason
        };

        API.post("/staff/market/trader/profile/reject", body).then((res) => {
            setIsUserVerified(false); 
            setRejectPopup(false);
          toast.success(res.data.message, {position:"top-center"}); 
        }).catch((err) => {
            setRejectPopup(false);
          toast.error(err.response.data.message, {position:"top-center"}); 
        });  
    }

  return (
   <>
      <div class="Tab-INFO-Container">     
        <div class="docoment-info-1">
            <div class="doc-info">
                <div class="items">
                    <label for="name">Tax Document title</label>
                    <input type="name" value="Pan Card" readOnly/>
                </div>

                <div class="items">
                    <label for="number">Tax Document Number</label>
                    <input type="tel" value={kyc.panDocumentNumber} readOnly/>
                </div>

            </div> 

             <div class="doc-info">  
                 <div class="items">
                    <label for="name">ID Title</label>
                    <input type="name" value="Aadhar Card" readOnly/>
                 </div>

                <div class="items">
                    <label for="name">ID Number</label>
                    <input type="tel" value={kyc.documentNumber} readOnly/>
                </div>
             </div>
              
               <div class="addres-info">
                   <span>Address Proof</span>
                   <a href="#" onClick={()=> {setReceiptImg(kyc.addressProof); setReceiptPopup(true);}}>View</a>

                   <span className="acc-statement-pdf">
                        <span>Account Statement PDF</span>
                        <a href="#" onClick={()=> setPdfPopup(true)}>View</a>
                   </span>

                   {!isUserVerified &&
                   <span className="acc-verify-btn">
                        <a className='veryfybtnnow' onClick={()=> {setVerifyNowPopup(true);}}>Verify Now</a>
                   </span>
                   }

                   {isUserVerified &&
                   <span className="acc-verify-btn">
                        <img className='veryfy-circle' src={tickCircle} alt="verified" />
                   </span>
                   }


               </div>
        </div>

        <div class="docoment-info-2">
            
            <div class="title-buttons">

                <div class="doc-buton">
                    <span>Front Doc.</span>
                    <a href="#" onClick={()=> {setReceiptImg(kyc.panDocumentName); setReceiptPopup(true);}}>View</a>
                </div>

                <div class="doc-buton">
                    <span>Back Doc.</span>
                    <a href="#" onClick={()=> {setReceiptImg(kyc.panDocumentImageBack); setReceiptPopup(true);}}>View</a>
                </div>

            </div>

            <div class="title-buttons">

                <div class="doc-buton">
                    <span>ID Front</span>
                    <a href="#" onClick={()=> {setReceiptImg(kyc.documentImage); setReceiptPopup(true);}}>View</a>
                </div>

                <div class="doc-buton">
                    <span>ID Back</span>
                    <a href="#" onClick={()=> {setReceiptImg(kyc.documentImageBack); setReceiptPopup(true);}}>View</a>
                </div>

            </div>

        </div>
    </div>

{receiptPopup && (
 <>
  <div className="Dispute-overlay"></div>
  <div className="Dispute-popup">
    <div className="popup-content">
      <h2>Doc. Image</h2>

      <img src={receiptImg && receiptImg} alt="doc" width="300" height="200" />

      <button className="Dispute-close-btn" onClick={closeReceiptPopup}>
        <i className="fas fa-times"></i>
      </button>
     
    </div>
    <button className="done-btn" onClick={closeReceiptPopup}>
        Done
      </button>
  </div>
</>
)}

{/* ACCOUNT STATEMENT POPUP */}
{pdfPopup && (
 <>
  <div className="Dispute-overlay"></div>
  <div className="Dispute-popup">
    <div className="popup-content">
      <h2>Account Statement</h2>

      <iframe 
        src={`https://docs.google.com/viewer?url=${accountStatement}&embedded=true`} 
        frameborder="0" height="500px" width="100%"
      ></iframe>

      <button className="Dispute-close-btn" onClick={()=> setPdfPopup(false)}>
        <i className="fas fa-times"></i>
      </button>
     
    </div>
    <button className="done-btn" onClick={()=> setPdfPopup(false)}>
        Done
      </button>
  </div>
</>
)}

{/* VERIFY NOW POPUP */}
{verifyNowPopup && (
 <>
  <div className="Dispute-overlay"></div>
  <div className="Dispute-popup">
    <div className="popup-content">
      <h2 className='veri-h2'>Confirmation</h2>
      <p className='veri-p'>Are you sure want to Verify?</p>

      <button className="Dispute-close-btn" onClick={()=> setVerifyNowPopup(false)}>
        <i className="fas fa-times"></i>
      </button>
     
    </div>

    <div className="verify-pop-btns">
        <button className="done-btn verify-accpt" onClick={()=> verifyMemberMethod()}
        >Accept</button>

        <button className="done-btn verify-rejct" onClick={()=> {setVerifyNowPopup(false); setRejectPopup(true);}}
        >Reject</button>
    </div>

  </div>
</>
)}


{/* REJECT REASON POPUP */}
{rejectPopup && (
 <>
  <div className="Dispute-overlay"></div>
  <div className="Dispute-popup">
    <div className="popup-content">
      <h2 className='veri-rej-h2'>Reason For Rejection !</h2>
      <textarea 
        placeholder='Type Here...'
        onChange={(e)=> {setRejectReason(e.target.value)}} 
      ></textarea>

      <button className="Dispute-close-btn" onClick={()=> setRejectPopup(false)}>
        <i className="fas fa-times"></i>
      </button>
     
    </div>

    <div className="verify-pop-btns">
        <button className="done-btn verify-accpt" onClick={()=> rejectMemberMethod()}
        >Submit</button>

        <button className="done-btn verify-rejct" onClick={()=> setRejectPopup(false)}
        >Cancel</button>
    </div>

  </div>
</>
)}



</>
);
};

export default Tradedocuments;
