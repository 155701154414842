import React, { useState } from 'react';
import './IncomeLockerPop.css'; 
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';

const IncomeLockerPop = ({setLockerPop, blockDetail, onBlockSenior, onBlockAdmin, onUnblock}) => {
  const [seniorReferral, setSeniorReferral] = useState(''); 
  const [contactInfo, setContactInfo] = useState(''); 

  const blockSeniorSubmit = () => {
    if(seniorReferral === '') {
      toast.warning("Please Enter VT Address.", {position:"top-center"});  
    }else {
      onBlockSenior(seniorReferral);
    }
  }

  const blockAdminSubmit = () => {
    if(contactInfo === '') {
      toast.warning("Please Enter Admin ID.", {position:"top-center"}); 
    }else {
      onBlockAdmin(contactInfo);
    }
  }

  const unBlockSeniorAdminSubmit = () => {
    onUnblock();
  }

  return (
    <article className='IncomeLockerPop'>
        <h6 className='pop-title'>Total Income Locker</h6>
        <span className='banner-cross' onClick={()=> {setLockerPop(false);}}><i class="fa fa-times"></i></span>

        <form className='form-main'>
            <h4>Request By</h4> 
            <div className="form-div">
                <h5>Senior</h5>
                <input 
                  type="tel" 
                  className='pop-input-field' 
                  placeholder='Enter VT Address'
                  onChange={(e)=> {setSeniorReferral(e.target.value)}}
                /> 
                {blockDetail.blockSource != 'SENIOR' &&
                  <button 
                    type='button' 
                    className='pop-btn-red'
                    onClick={()=> {blockSeniorSubmit()}}
                  >Block</button>
                }
                {blockDetail.blockSource === 'SENIOR' && 
                  <button 
                    type='button' 
                    className='pop-btn-green'
                    onClick={()=> {unBlockSeniorAdminSubmit()}}
                  >Un-Block</button>
                }
            </div>

            <div className="form-div">
                <h5>System Admin</h5>
                <input 
                  type="tel" 
                  className='pop-input-field' 
                  placeholder='Enter Admin ID'
                  onChange={(e)=> {setContactInfo(e.target.value)}}
                />
                {blockDetail.blockSource != 'ADMIN' &&
                  <button 
                    type='button' 
                    className='pop-btn-red'
                    onClick={()=> {blockAdminSubmit()}}
                  >Block</button>
                }
                {blockDetail.blockSource === 'ADMIN' && 
                  <button 
                    type='button' 
                    className='pop-btn-green'
                    onClick={()=> {unBlockSeniorAdminSubmit()}}
                  >Un-Block</button>
                }
            </div>
        </form>
    </article>
  )
}

export default IncomeLockerPop;