import React from 'react';
import '../Dispute-Open/Dispute-Open.css'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import moment from 'moment';

const Disputeopen = ({listOrderDispute, loading, pagingCounter}) => {
    return (
        <div className="Dispute-Open-TABLE-Table-updated">
          <table className="Dispute-Open-TABLE-Tpage-table">
            <thead>
              <tr>
                <th>Sl.No.</th>
                <th>Crypto</th>
                <th>Transaction</th>
                <th>Reason</th>
                <th>Benificiary</th>
                <th>Date</th>
                <th>Time</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {loading && <LoadingSpinner />}
              {listOrderDispute.map((data, index) => {
              return(
              <tr key={index}>
                <td>{index+pagingCounter}</td>
                <td>{parseFloat(data.amount).toFixed(2)} {data.cryptoSymbol}</td>
                <td>{data.transaction}</td>
                <td>{data.reason ? data.reason : '--'}</td>
                <td>{data.beneficiary}</td>
                <td>{moment(data.createdAt).format('DD-MM-YYYY')}</td>
                <td>{moment(data.createdAt).format('hh:mm:ss')}</td>
                <td>Pending</td>
              </tr>
              )})}

            </tbody>
          </table>
        </div> 
    );
  }


export default Disputeopen;
