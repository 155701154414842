import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Assets/All-Css/Dispute_Sattlement/Dispute_Report.css';
import '../../Assets/style.css'; 
import './TraderReport.css';
import './PartnerReport.css'; 
import '../../Assets/All-Css/Recharge/Recharge.css';

import profilepng from '../../Assets/Login-img/Component 1.png';
import SwitchRed from '../../Assets/Member-img/SwitchRed.png';
import SwitchGreen from '../../Assets/Member-img/SwitchGreen.png';

import { useEffect } from 'react'; 
import API from '../../api/Api';
import LoadingSpinner from '../../Componets/LoadingSpinner/LoadingSpinner';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '../../Componets/Pagination/Pagination'; 
import moment from 'moment';
import { SessionTimer } from '../../util/UtilMethod';
import { getAuthToken, getTokenDuration } from '../../util/Auth';
import TimeoutPop from '../../Componets/TimeoutPop/TimeoutPop';


const PartnerReport = () => {
  const navigate = useNavigate(); 

    // TOKEN EXPIRATION 
    const [timeoutPop, setTimeoutPop] = useState(false);    
    const token = getAuthToken();
    useEffect(() => {
      if(!token) { 
        navigate('/loginform');
        return; 
      }
      if (token === 'EXPIRED') {
        navigate('/loginform');
        return; 
      }
      const tokenDuration = getTokenDuration(); 
      setTimeout(() => {
        navigate('/loginform'); 
      }, tokenDuration);     // 1*60*60*1000   
    }, [token]);  
  
    useEffect(() => {
      const tokenDuration = getTokenDuration(); 
      const timer = setTimeout(() => {
        setTimeoutPop(true);
      }, (tokenDuration - 15*60*1000));
      return () => clearTimeout(timer);
    }, []);
    // TOKEN EXPIRATION END

     // table popups buttons
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');



   // date filtter
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };


  const [sidebarHidden, setSidebarHidden] = useState(false); 
  const [allDropdown, setAllDropdown] = useState([]);

  // State to manage session time
  const [sessionTime, setSessionTime] = useState(0);

  const nav = useNavigate ();

  useEffect(() => {
    setAllDropdown(document.querySelectorAll('#sidebar .side-dropdown'));
  }, []);



  useEffect(() => {
    // Attach event listeners for dropdown elements
    allDropdown.forEach((dropdown) => {
      const parentLink = dropdown.closest('li');
      parentLink.addEventListener('click', (e) => handleMenuClick(e, dropdown));
    });

    // Set an interval to update the session time every second
    const timerInterval = setInterval(() => {
      setSessionTime((prevTime) => prevTime + 1);
    }, 1000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(timerInterval);

      // Remove event listeners
      allDropdown.forEach((dropdown) => {
        const parentLink = dropdown.closest('li');
        parentLink.removeEventListener('click', (e) => handleMenuClick(e, dropdown));
      });
    };
  }, [allDropdown]);

  const handleDropdownClick = (e, dropdown) => {
    e.preventDefault();

    const isDropdownActive = e.currentTarget.classList.contains('active');

    // Close all dropdowns
    allDropdown.forEach((item) => {
      const aLink = item.parentElement.querySelector('a:first-child');
      aLink.classList.remove('active');
      item.classList.remove('show');
    });

    // Toggle the clicked dropdown
    e.currentTarget.classList.toggle('active', !isDropdownActive);
    dropdown.classList.toggle('show', !isDropdownActive);
  };

  const handleToggleSidebar = () => {
    setSidebarHidden((prevSidebarHidden) => !prevSidebarHidden);
  };

  const handleProfileClick = () => {
    const profileLink = document.querySelector('.profile-link');
    profileLink.classList.toggle('show');
  };

  const handleMenuClick = (e, dropdown) => {
    e.preventDefault();
    dropdown.classList.toggle('show');
  };

  // Convert seconds to HH:MM:SS format
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const pad = (num) => (num < 10 ? '0' + num : num);

    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  };


  // API DATA //////////////////////////////////////
  const sessionTimer = SessionTimer();
  const [showRemarkPop, setShowRemarkPop] = useState(false);
  const [showStatementPop, setShowStatementPop] = useState(false);
  const [loading, setLoading] = useState(false);

  const [listTotalPartner, setListTotalPartner] = useState([]);
  const [listInvestedPartner, setListInvestedPartner] = useState([]);
  const [listNonInvestedPartner, setListNonInvestedPartner] = useState([]);

  const [singleDispute, setSingleDispute] = useState([]);
  const [receipt, setReceipt] = useState(null);

  const [tab, setTab] = useState(1);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10); 
  const [pagingCounter, setPagingCounter] = useState(1);

  // const [fromDate, setFromDate] = useState(null);
  // const [toDate, setToDate] = useState(null);
  
  const [search, setSearch] = useState(null);
  const [searchData, setSearchData] = useState(null);

  const [pagePermission, setPagePermission] = useState([]); 
  const pagePermissionMethod = () => { 
    API.get("/staff/account/role/allow-pages").then((res) => {
      setPagePermission(res.data.data); 
    }).catch((err) => { 
    }).finally(() => {
    });  
  }

  const listTotalPartnerMethod = () => {
    setLoading(true);
    API.get("/staff/report/partners", {
      params: {
        page: currentPage+1,
        limit: itemsPerPage,
        search: searchData,
        // fromDate: fromDate,
        // toDate: toDate,
      }
    }).then((res) => {
      setListTotalPartner(res.data.data.docs); 
      setTotalRecords(res.data.data.totalDocs); 
      setPagingCounter(res.data.data.pagingCounter);
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }).finally(() => {
      setLoading(false);
    });  
  }

  const listInvestedPartnerMethod = () => {
    setLoading(true);
    API.get("/staff/report/partners/referred/invested", {
      params: {
        page: currentPage+1,
        limit: itemsPerPage,
        search: searchData,
        // fromDate: fromDate,
        // toDate: toDate,
      }
    }).then((res) => {
      setListInvestedPartner(res.data.data.docs); 
      setTotalRecords(res.data.data.totalDocs); 
      setPagingCounter(res.data.data.pagingCounter);
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }).finally(() => {
      setLoading(false);
    });  
  }

  const listNonInvestedPartnerMethod = () => {
    setLoading(true);
    API.get("/staff/report/partners/referred/non-invested", {
      params: {
        page: currentPage+1,
        limit: itemsPerPage,
        search: searchData,
        // fromDate: fromDate,
        // toDate: toDate,
      }
    }).then((res) => {
      setListNonInvestedPartner(res.data.data.docs); 
      setTotalRecords(res.data.data.totalDocs); 
      setPagingCounter(res.data.data.pagingCounter);
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }).finally(() => {
      setLoading(false);
    });  
  }

  const handlePageChange = ({ selected }) => { 
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(totalRecords / itemsPerPage);

  // const onClickApplyFilter = () => {
  //   setFromDate(startDate);
  //   setToDate(endDate); 
  // }

  function inputSearchHandler(event) {
    setSearch(event.target.value);  
  }
  const onSearchClicked = () => {
    setSearchData(search);
    setSearch(null);
  } 

  const blockUnblockMethod = (isBlocked, id) => {
    if(isBlocked) {
      API.post("/admin/partner/code/block", {id: id}).then((res) => {
        listTotalPartnerMethod();
        toast.success(res.data.message, {position:"top-center"}); 
      }).catch((err) => { 
        toast.error(err.response.data.message, {position:"top-center"}); 
      }).finally(() => {
      });

    } else {
      API.post("/admin/partner/code/unblock", {id: id}).then((res) => {
        listTotalPartnerMethod();
        toast.success(res.data.message, {position:"top-center"}); 
      }).catch((err) => { 
        toast.error(err.response.data.message, {position:"top-center"}); 
      }).finally(() => {
      });
    }
  }

  const deleteRestoreMethod1 = (isDeleted, referralId) => {
    if(isDeleted) {
      API.delete("/staff/user/delete/permanent", {search: referralId}).then((res) => {
        setTab(2);
        listInvestedPartnerMethod();
        toast.success(res.data.message, {position:"top-center"}); 
      }).catch((err) => { 
        toast.error(err.response.data.message, {position:"top-center"}); 
      }).finally(() => {
      });

    } else {
      API.put("/staff/user/restore/permanent", {search: referralId}).then((res) => {
        setTab(2);
        listInvestedPartnerMethod();
        toast.success(res.data.message, {position:"top-center"}); 
      }).catch((err) => { 
        toast.error(err.response.data.message, {position:"top-center"}); 
      }).finally(() => {
      });
    }
  }

  const deleteRestoreMethod2 = (isDeleted, referralId) => {
    if(isDeleted) {
      API.delete("/staff/user/delete/permanent", {search: referralId}).then((res) => {
        setTab(3);
        listNonInvestedPartnerMethod();
        toast.success(res.data.message, {position:"top-center"}); 
      }).catch((err) => { 
        toast.error(err.response.data.message, {position:"top-center"}); 
      }).finally(() => {
      });

    } else {
      API.put("/staff/user/restore/permanent", {search: referralId}).then((res) => {
        setTab(3);
        listNonInvestedPartnerMethod();
        toast.success(res.data.message, {position:"top-center"}); 
      }).catch((err) => { 
        toast.error(err.response.data.message, {position:"top-center"}); 
      }).finally(() => {
      });
    }
  }


  useEffect(() => {
    pagePermissionMethod(); 
  }, []); 

  useEffect(() => {
    {tab === 1 && listTotalPartnerMethod()}
  }, [currentPage, searchData, tab === 1]); 

  useEffect(() => {
    {tab === 2 && listInvestedPartnerMethod()}
  }, [currentPage, searchData, tab === 2]); 

  useEffect(() => {
    {tab === 3 && listNonInvestedPartnerMethod()}
  }, [currentPage, searchData, tab === 3]); 

  return (
    <>
  <section id="sidebar" className={sidebarHidden ? 'hide' : ''}> 
    <a href="#" className="brand">
      <img src={profilepng} alt="Profile Image" className="profile-image" />
      Support
    </a> 

    <ul className="side-menu">
      {pagePermission.some(el => el === 'dashboard') && 
      <li>
        <a href='#' className="active" onClick={()=>{navigate('/dashboard')}} >
          <i className="fas fa-tachometer-alt icon"></i> Dashboard
        </a>
      </li>}

      <li className="divider" data-text="main">
        Main
      </li>

       {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Manage Request----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'ManageRequests') && 
        <a href="#" >
          <i className="fas fa-folder icon fa-fw"></i> Manage Requests
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverification')}}>
              <i className="fas fa-id-card"></i> KYC Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderverification')}}>
              <i className="fas fa-briefcase"></i> Trader Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'recharge') && 
          <li>
            <a href="#" onClick={()=>{navigate('/recharge')}}>
              <i className="fas fa-phone"></i> Recharge Request
            </a> 
          </li>}

          {pagePermission.some(el => el === 'c2c') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2c')}}>
              <i className="fas fa-exchange-alt"></i> C2C Request
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputesattlement') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputesattlement')}}> 
              <i className="fas fa-ban"></i> Dispute Settlement
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'investotp') &&
      <li>
        <a href='#' onClick={()=>{navigate('/investotp')}}>
          <i className="fas fa-fingerprint icon fa-fw"></i>Invest Via OTP
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {/* --@@@@@@@@@@@@@@@@@@@@@@@@---View Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

     
      <li>
        {pagePermission.some(el => el === 'ViewReports') && 
        <a href="#" >
          <i className="fas fa-file-alt icon fa-fw"></i> View Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverifyedreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverifyedreport')}}>
              <i className="fas fa-id-card"></i> KYC Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderreport')}}>
              <i className="fas fa-briefcase"></i> Trader Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'rechargereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/rechargereport')}}>
              <i className="fas fa-donate"></i> Recharge Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'c2creport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2creport')}}>
              <i className="fas fa-money-check-alt"></i> C2C Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputereport')}}>
              <i className="fas fa-handshake"></i> Dispute Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'utrreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/utrreport')}}>
              <i className="fas fa-money-check"></i> UTR Status Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'purchaseReport') && 
          <li>
            <a href="#"onClick={()=>{navigate('/purchaseReport')}}>
              <i className="fas fa-gift"></i> Purchase Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'partnerreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/partnerreport')}}>
              <i className="fas fa-handshake"></i> Partner Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'ViewReports') && 
          <li>
            <a href="#" onClick={()=>{navigate('/noninvestedreport')}}>
              <i className="fas fa-file-alt"></i> Non Invested Report
            </a>
          </li>}

        </ul>
      </li>


    {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Send Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'SendReports') && 
        <a href="#" >
          <i className="fas fa-paper-plane icon fa-fw"></i> Send Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'sendecharge') && 
          <li>
            <a href='#' onClick={()=>{navigate('/sendecharge')}}>
              <i className="fas fa-file-alt"></i> Coin2Cash
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'memberManage') &&
      <li>
        <a href="#" onClick={()=>{nav('/memberManage')}} >
          <i className="fas fa-users icon fa-fw"></i> Manage Members
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'blockuser') &&
      <li>
        <a href="#" onClick={()=>{nav('/blockuser')}} >
          <i className="fas fa-user-circle icon fa-fw"></i> Block User
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'enquirymanage') &&
      <li>
        <a href="#" onClick={()=>{navigate('/enquirymanage')}}>
          <i className="fas fa-briefcase icon fa-fw"></i> Manage Enquiry
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'update') &&
      <li>
        <a href="#" onClick={()=>{navigate('/update')}}>
          <i className="fas fa-tools icon fa-fw"></i> Update Manager
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}
      
      {pagePermission.some(el => el === 'ChatSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/ChatSupport')}}>
          <i className="fas fa-comment-alt icon fa-fw"></i>Chat Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'TicketSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/TicketSupport')}}>
          <i className="fas fa-chalkboard-teacher icon fa-fw"></i>Ticket Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'feedback') &&
      <li>
        <a href='#' onClick={()=>{navigate('/feedback')}}> 
          <i className="fas fa-comment-medical icon fa-fw"></i>Feedbacks
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      <li className="divider" data-text="Exit">
        Exit
      </li>

      <div className="ads">
        <button className="Btn" onClick={()=>{navigate('/loginform')}}>
          <div className="sign">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text">Logout</div>
        </button>
      </div>
    </ul> 
  </section> 


{/* MAIN CONTENT */}
  <section id="content">
    <nav>
      <i className="bx bx-menu toggle-sidebar" onClick={handleToggleSidebar}></i>
      <form action="#">
        <div className="form-group">
          <span>Session Time: {formatTime(sessionTimer)}</span>
        </div>
      </form>
       
      <div className='notify'>
       <i className="fas fa-bell"></i>
     </div>


      <span className="divider"></span>
{/* --@@@@@@@@@@@@@@@@@@@@@@@@---Profile----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 
      <div className="profile">
        <img
          src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-File.png"
          onClick={handleProfileClick}
          alt="Profile"
        />
        <ul className="profile-link">
          <li>
            <a href="" onClick={()=>{navigate('/selfprofile')}}>
              <i className="bx bxs-user-circle icon"></i> Profile
            </a>
          </li>
 {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Logout----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 

          <li>
            <a href="#" onClick={()=>{navigate('/loginform')}}>
              <i className="bx bxs-log-out-circle"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>

        <main>
          {/* {props.passedComponent} */}
 
          
    <section className="Dispute-rep-Management-container">

{/* @@@@@@@@@@@@@@@@@@@@_____top Heading____@@@@@@@@@@@@@@@@@@@@@ */}

    <div className="Dispute-rep-mange-top-heading">
      <h1><i class='fas fa-handshake'></i> &nbsp;Partner Report</h1>
    </div>


{/* @@@@@@@@@@@@@@@@@@@@_____top filler search, back btn, date____@@@@@@@@@@@@@@@@@@@@@ */}


 {/* Back Button */}
 <div className="Dispute-mange-Fillter-bar">
    {/* <button className="Dispute-rep-back-btn "  onClick={()=>{navigate('/traderverification')}} >
      <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1024 1024"><path d="M874.690416 495.52477c0 11.2973-9.168824 20.466124-20.466124 20.466124l-604.773963 0 188.083679 188.083679c7.992021 7.992021 7.992021 20.947078 0 28.939099-4.001127 3.990894-9.240455 5.996574-14.46955 5.996574-5.239328 0-10.478655-1.995447-14.479783-5.996574l-223.00912-223.00912c-3.837398-3.837398-5.996574-9.046027-5.996574-14.46955 0-5.433756 2.159176-10.632151 5.996574-14.46955l223.019353-223.029586c7.992021-7.992021 20.957311-7.992021 28.949332 0 7.992021 8.002254 7.992021 20.957311 0 28.949332l-188.073446 188.073446 604.753497 0C865.521592 475.058646 874.690416 484.217237 874.690416 495.52477z"></path></svg>
      <span>Back</span>
    </button> */}

  {/* Date fillter */}
  {/* <div className="Dispute-rep-Date-Filter">
    <label htmlFor="start-date">From Date:</label>
    <input 
      type="date" 
      id="start-date" 
      value={moment(startDate).format('YYYY-MM-DD')}
      onChange={handleStartDateChange} 
      />

    <label htmlFor="end-date">To Date:</label>
    <input 
      type="date" 
      id="end-date" 
      value={moment(endDate).format('YYYY-MM-DD')}
      onChange={handleEndDateChange} 
    />

      <button className="Filter-Button" onClick={()=> {onClickApplyFilter()}}>
        Apply Filter
    </button>
  </div> */}

  <div className="Dispute-rep-Date-Filter prt-report-div">
    <button 
      className={`Dispute-rep-back-btn prt-tab 
        ${tab === 1 && 'prt-tab-active'}
      `}
      onClick={()=> {setTab(1); setCurrentPage(0);}}
    >
      Total Partners
    </button> 

    <button 
      className={`Dispute-rep-back-btn prt-tab 
        ${tab === 2 && 'prt-tab-active'}
      `}
      onClick={()=> {setTab(2); setCurrentPage(0);}}
    >
      Invested
    </button> 

    <button 
      className={`Dispute-rep-back-btn prt-tab 
        ${tab === 3 && 'prt-tab-active'}
      `}
      onClick={()=> {setTab(3); setCurrentPage(0);}}
    >
      Non-Invested
    </button> 
  </div>



 {/* Search Bar */}
<form className="Dispute-rep-Search-form">
    <button type='button' onClick={() => {onSearchClicked()}}>
      <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
        <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
    </button>

    <input 
      className="Dispute-rep-input" 
      placeholder="Search Member ID/Email ID/ Mobile No." 
      required 
      type="text" 
      value={search}
      onChange={inputSearchHandler}
      onKeyDown={onSearchClicked}
    />

    <button className="Dispute-rep-Search-reset" type="button" onClick={()=>setSearch('')}>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
      </svg>
    </button>
  </form>
</div>

    {/* TAB MENU 1 */}
    {tab === 1 && 
    <table>
      <thead>
        <tr>
          <th>Sl.No.</th>
          <th>Member ID</th>
          <th>Member Name</th>
          <th>Partner Code</th>
          <th>Usage</th>
          <th>Action</th>
        </tr>
      </thead>

      <tbody>
      {loading && <LoadingSpinner />}
      {listTotalPartner?.map((data, index) => {
      return(
        <tr key={index}>
          <td>{index+pagingCounter}</td>
          <td>{data.user.referralCode}</td>
          <td>{data.user.name}</td>
          <td>{data.code}</td>
          <td>{data.useCounter}</td>
          <td>
            {pagePermission.some(el => el === 'partnerreportBtnAction1') && 
              <img src={data.blocked ? SwitchRed : SwitchGreen} alt="switch" className='switch-btn' onClick={()=> {blockUnblockMethod(!data.blocked, data._id)}}/>
            }
          </td>
        </tr>
      )})}
      </tbody>
    </table>}


    {/* TAB MENU 2 */}
    {tab === 2 && 
    <table>
      <thead>
        <tr>
          <th>Sl.No.</th>
          <th>Member ID</th>
          <th>Member Name</th>
          <th>Invested Quantity</th>
          <th>Parent ID</th>
          <th>Status<br/>D&harr;A</th>
        </tr>
      </thead>

      <tbody>
      {loading && <LoadingSpinner />}
      {listInvestedPartner?.map((data, index) => {
      return(
        <tr key={index}>
          <td>{index+pagingCounter}</td>
          <td>{data.referralCode}</td>
          <td>{data.name}</td>
          <td>{data.investedBalance.toFixed()}</td>
          <td>{data.referredBy?.partnerReferCode}</td>
          <td>
            {pagePermission.some(el => el === 'partnerreportBtnAction2') && 
              <img src={data.isPermanentlyDeleted ? SwitchRed : SwitchGreen} alt="switch" className='switch-btn' onClick={()=> {deleteRestoreMethod1(!data.isPermanentlyDeleted, data.referralCode)}}/>
            }
          </td> 
        </tr>
      )})}
      </tbody>
    </table>}


    {/* TAB MENU 3 */}
    {tab === 3 && 
    <table>
      <thead>
        <tr>
          <th>Sl.No.</th>
          <th>Member ID</th>
          <th>Member Name</th>
          <th>Expired Days</th>
          <th>Parent ID</th>
          <th>Status<br/>D&harr;A</th>
        </tr>
      </thead>

      <tbody>
      {loading && <LoadingSpinner />}
      {listNonInvestedPartner?.map((data, index) => {
      return(
        <tr key={index}>
          <td>{index+pagingCounter}</td>
          <td>{data.referralCode}</td>
          <td>{data.name}</td>
          <td>{data.expiredDays}</td>
          <td>{data.referredBy?.partnerReferCode}</td>
          <td>
            {pagePermission.some(el => el === 'partnerreportBtnAction3') && 
             <img src={data.isPermanentlyDeleted ? SwitchRed : SwitchGreen} alt="switch" className='switch-btn' onClick={()=> {deleteRestoreMethod2(!data.isPermanentlyDeleted, data.referralCode)}} />
            }
          </td>
        </tr>
      )})}
      </tbody>
    </table>}


    <Pagination 
      pageCount={pageCount} 
      onPageChange={handlePageChange}    
    />

 
{/*--@@@@@@@@@@@@@@ REASON VIEW POPUP @@@@@@@@@--*/}
{/* {showRemarkPop && (
 <>
  <div className="Recharge-rep-overlay"></div>
  <div className="Recharge-rep-popup">
    <div className="Recharge-rep-popup-content">
      <h2>Reason For Rejection!</h2>
      
      <textarea readOnly>{singleDispute.accountStatementNote ? singleDispute.accountStatementNote : 'Reason is not mentioned.'}</textarea>

      <button className="close-btn" onClick={()=> setShowRemarkPop(false)}>
        <i className="fas fa-times"></i>
      </button>
     
    </div>
      <button className="Recharge-rep-done-btn" onClick={()=> setShowRemarkPop(false)}>
        OK
      </button>
  </div>
</>
)} */}

{/* STATEMENT PDF VIEW POPUP */}


</section>
</main>
</section>

    {timeoutPop && <TimeoutPop
       setTimeoutPop={setTimeoutPop}
     />}

</>



  );
};

export default PartnerReport;
