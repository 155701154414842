import React, { useState } from 'react';
import '../../Assets/All-Css/Send/Charge_Send.css';

import Reciptimg from '../../Assets/Login-img/Recharge-recipt.png';

import '../../Assets/style.css';
import profilepng from '../../Assets/Login-img/Component 1.png';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 

import API from '../../api/Api';
import LoadingSpinner from '../../Componets/LoadingSpinner/LoadingSpinner';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import moment, { duration } from 'moment';
import Pagination from '../../Componets/Pagination/Pagination';
import { SessionTimer, getRemainingTime } from '../../util/UtilMethod';
import { getAuthToken, getTokenDuration } from '../../util/Auth';
import TimeoutPop from '../../Componets/TimeoutPop/TimeoutPop';


const Sendecharge = () => {
  const navigate = useNavigate(); 

    // TOKEN EXPIRATION   
    const [timeoutPop, setTimeoutPop] = useState(false);  
    const token = getAuthToken();
    useEffect(() => {
      if(!token) { 
        navigate('/loginform');
        return; 
      }
      if (token === 'EXPIRED') {
        navigate('/loginform');
        return; 
      }
      const tokenDuration = getTokenDuration(); 
      setTimeout(() => {
        navigate('/loginform'); 
      }, tokenDuration);     // 1*60*60*1000   
    }, [token]);  
  
    useEffect(() => {
      const tokenDuration = getTokenDuration(); 
      const timer = setTimeout(() => {
        setTimeoutPop(true);
      }, (tokenDuration - 15*60*1000));
      return () => clearTimeout(timer);
    }, []);
    // TOKEN EXPIRATION END

   // table popups buttons
   const [isReceiptPopupOpen, setReceiptPopupOpen] = useState(false);
   const [isPurchasePopupOpen, setPurchasePopupOpen] = useState(false);

  // popup buttons
  const [isAcceptPopupOpen, setAcceptPopupOpen] = useState(false);
  const [isAcceptConfirmPopupOpen, setAcceptConfirmPopupOpen] = useState(false); 


 

  // enable the button only when the checkbox is checked 
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');




  // receipt close
  const closeReceiptPopup = () => {
    setReceiptPopupOpen(false);
  };



  
  // Accept close
  const closeAcceptPopup = () => {
    setAcceptPopupOpen(false);
  };



   // date filtter
   const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };



  const [sidebarHidden, setSidebarHidden] = useState(false);
  const [allDropdown, setAllDropdown] = useState([]);

  // State to manage session time
  const [sessionTime, setSessionTime] = useState(0);

  const nav = useNavigate ();

  useEffect(() => {
    setAllDropdown(document.querySelectorAll('#sidebar .side-dropdown'));
  }, []);



  useEffect(() => {
    // Attach event listeners for dropdown elements
    allDropdown.forEach((dropdown) => {
      const parentLink = dropdown.closest('li');
      parentLink.addEventListener('click', (e) => handleMenuClick(e, dropdown));
    });

    // Set an interval to update the session time every second
    const timerInterval = setInterval(() => {
      setSessionTime((prevTime) => prevTime + 1);
    }, 1000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(timerInterval);

      // Remove event listeners
      allDropdown.forEach((dropdown) => {
        const parentLink = dropdown.closest('li');
        parentLink.removeEventListener('click', (e) => handleMenuClick(e, dropdown));
      });
    };
  }, [allDropdown]);

  const handleDropdownClick = (e, dropdown) => {
    e.preventDefault();

    const isDropdownActive = e.currentTarget.classList.contains('active');

    // Close all dropdowns
    allDropdown.forEach((item) => {
      const aLink = item.parentElement.querySelector('a:first-child');
      aLink.classList.remove('active');
      item.classList.remove('show');
    });

    // Toggle the clicked dropdown
    e.currentTarget.classList.toggle('active', !isDropdownActive);
    dropdown.classList.toggle('show', !isDropdownActive);
  };

  const handleToggleSidebar = () => {
    setSidebarHidden((prevSidebarHidden) => !prevSidebarHidden);
  };

  const handleProfileClick = () => {
    const profileLink = document.querySelector('.profile-link');
    profileLink.classList.toggle('show');
  };

  const handleMenuClick = (e, dropdown) => {
    e.preventDefault();
    dropdown.classList.toggle('show');
  };

  // Convert seconds to HH:MM:SS format
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const pad = (num) => (num < 10 ? '0' + num : num);

    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  };


  // API DATA //////////////////////////////////////
  const sessionTimer = SessionTimer();
  const [loading, setLoading] = useState(false);
  const [cashRequests, setCashRequests] = useState([]);
  const [singleRequest, setSingleRequest] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10); 

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [search, setSearch] = useState(null);
  const [searchData, setSearchData] = useState(null);
  const [pagingCounter, setPagingCounter] = useState(1);

  const [pagePermission, setPagePermission] = useState([]); 
  const pagePermissionMethod = () => { 
    API.get("/staff/account/role/allow-pages").then((res) => {
      setPagePermission(res.data.data); 
    }).catch((err) => { 
    }).finally(() => {
    });  
  }

  const listCashRequestMethod = () => {
    setLoading(true);
    API.get("/staff/report/transactions/withdraw/verified", {
      params: {
        page: currentPage+1,
        limit: itemsPerPage,
        fromDate: fromDate,
        toDate: toDate,
        search: searchData,
      }
    }).then((res) => { 
      setCashRequests(res.data.data.docs); 
      setTotalRecords(res.data.data.totalDocs); 
      setPagingCounter(res.data.data.pagingCounter);
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }).finally(() => {
      setLoading(false);
    });  
  }

  const handlePageChange = ({ selected }) => { 
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(totalRecords / itemsPerPage);

  const onClickApplyFilter = () => {
    setFromDate(startDate);
    setToDate(endDate); 
  }

  function inputSearchHandler(event) {
    setSearch(event.target.value);  
  }
  const onSearchClicked = () => {
    setSearchData(search);
  }

  // const getRemainingTime = (createdAt, timeMin) => {
  //   const endDate = new Date(createdAt);
  //   endDate.setMinutes(endDate.getMinutes() + timeMin);
  //   const endDateString = endDate.toISOString();

  //   const currentDate = new Date();
  //   const currentDateString = currentDate.toISOString();

  //   const subsTime = Math.abs(new Date(currentDateString).getTime() - new Date(endDateString).getTime()).toString();

  //   const remainTimeMin = Math.round(subsTime / 60000);
  //   return remainTimeMin;
  // }

  useEffect(() => {
    pagePermissionMethod(); 
  }, []); 

  useEffect(() => {
    listCashRequestMethod();
  }, [currentPage, fromDate, toDate, searchData]);  


  return (
    <>  
  <section id="sidebar" className={sidebarHidden ? 'hide' : ''}> 
    <a href="#" className="brand">
      <img src={profilepng} alt="Profile Image" className="profile-image" />
      Support
    </a> 

    <ul className="side-menu">
      {pagePermission.some(el => el === 'dashboard') && 
      <li>
        <a href='#' className="active" onClick={()=>{navigate('/dashboard')}} >
          <i className="fas fa-tachometer-alt icon"></i> Dashboard
        </a>
      </li>}

      <li className="divider" data-text="main">
        Main
      </li>

       {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Manage Request----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'ManageRequests') && 
        <a href="#" >
          <i className="fas fa-folder icon fa-fw"></i> Manage Requests
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverification')}}>
              <i className="fas fa-id-card"></i> KYC Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderverification')}}>
              <i className="fas fa-briefcase"></i> Trader Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'recharge') && 
          <li>
            <a href="#" onClick={()=>{navigate('/recharge')}}>
              <i className="fas fa-phone"></i> Recharge Request
            </a> 
          </li>}

          {pagePermission.some(el => el === 'c2c') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2c')}}>
              <i className="fas fa-exchange-alt"></i> C2C Request
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputesattlement') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputesattlement')}}> 
              <i className="fas fa-ban"></i> Dispute Settlement
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'investotp') &&
      <li>
        <a href='#' onClick={()=>{navigate('/investotp')}}>
          <i className="fas fa-fingerprint icon fa-fw"></i>Invest Via OTP
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {/* --@@@@@@@@@@@@@@@@@@@@@@@@---View Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

     
      <li>
        {pagePermission.some(el => el === 'ViewReports') && 
        <a href="#" >
          <i className="fas fa-file-alt icon fa-fw"></i> View Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverifyedreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverifyedreport')}}>
              <i className="fas fa-id-card"></i> KYC Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderreport')}}>
              <i className="fas fa-briefcase"></i> Trader Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'rechargereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/rechargereport')}}>
              <i className="fas fa-donate"></i> Recharge Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'c2creport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2creport')}}>
              <i className="fas fa-money-check-alt"></i> C2C Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputereport')}}>
              <i className="fas fa-handshake"></i> Dispute Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'utrreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/utrreport')}}>
              <i className="fas fa-money-check"></i> UTR Status Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'purchaseReport') && 
          <li>
            <a href="#"onClick={()=>{navigate('/purchaseReport')}}>
              <i className="fas fa-gift"></i> Purchase Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'partnerreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/partnerreport')}}>
              <i className="fas fa-handshake"></i> Partner Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'ViewReports') && 
          <li>
            <a href="#" onClick={()=>{navigate('/noninvestedreport')}}>
              <i className="fas fa-file-alt"></i> Non Invested Report
            </a>
          </li>}

        </ul>
      </li>


    {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Send Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'SendReports') && 
        <a href="#" >
          <i className="fas fa-paper-plane icon fa-fw"></i> Send Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'sendecharge') && 
          <li>
            <a href='#' onClick={()=>{navigate('/sendecharge')}}>
              <i className="fas fa-file-alt"></i> Coin2Cash
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'memberManage') &&
      <li>
        <a href="#" onClick={()=>{nav('/memberManage')}} >
          <i className="fas fa-users icon fa-fw"></i> Manage Members
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'blockuser') &&
      <li>
        <a href="#" onClick={()=>{nav('/blockuser')}} >
          <i className="fas fa-user-circle icon fa-fw"></i> Block User
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'enquirymanage') &&
      <li>
        <a href="#" onClick={()=>{navigate('/enquirymanage')}}>
          <i className="fas fa-briefcase icon fa-fw"></i> Manage Enquiry
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'update') &&
      <li>
        <a href="#" onClick={()=>{navigate('/update')}}>
          <i className="fas fa-tools icon fa-fw"></i> Update Manager
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}
      
      {pagePermission.some(el => el === 'ChatSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/ChatSupport')}}>
          <i className="fas fa-comment-alt icon fa-fw"></i>Chat Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'TicketSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/TicketSupport')}}>
          <i className="fas fa-chalkboard-teacher icon fa-fw"></i>Ticket Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'feedback') &&
      <li>
        <a href='#' onClick={()=>{navigate('/feedback')}}> 
          <i className="fas fa-comment-medical icon fa-fw"></i>Feedbacks
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      <li className="divider" data-text="Exit">
        Exit
      </li>

      <div className="ads">
        <button className="Btn" onClick={()=>{navigate('/loginform')}}>
          <div className="sign">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text">Logout</div>
        </button>
      </div>
    </ul> 
  </section> 


{/* MAIN CONTENT */}
  <section id="content">
    <nav>
      <i className="bx bx-menu toggle-sidebar" onClick={handleToggleSidebar}></i>
      <form action="#">
        <div className="form-group">
          <span>Session Time: {formatTime(sessionTimer)}</span>
        </div>
      </form>
       
      <div className='notify'>
       <i className="fas fa-bell"></i>
     </div>


      <span className="divider"></span>
{/* --@@@@@@@@@@@@@@@@@@@@@@@@---Profile----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 
      <div className="profile">
        <img
          src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-File.png"
          onClick={handleProfileClick}
          alt="Profile"
        />
        <ul className="profile-link">
          <li>
            <a href="" onClick={()=>{navigate('/selfprofile')}}>
              <i className="bx bxs-user-circle icon"></i> Profile
            </a>
          </li>
 {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Logout----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 

          <li>
            <a href="#" onClick={()=>{navigate('/loginform')}}>
              <i className="bx bxs-log-out-circle"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>

        <main>
          {/* {props.passedComponent} */}
 
          <section className="SendCharge-Management-container">

{/* @@@@@@@@@@@@@@@@@@@@_____top Heading____@@@@@@@@@@@@@@@@@@@@@ */}

    <div className="SendCharge-mange-top-heading">
      <h1><i className='bx bx-link'></i>Send Verified Coin2Cash Request</h1>
    </div>


{/* @@@@@@@@@@@@@@@@@@@@_____top filler search, back btn, date____@@@@@@@@@@@@@@@@@@@@@ */}


    <div className="SendCharge-mange-Fillter-bar">


  
{/* Date fillter */}
<div className="SendCharge-Date-Filter">
   <label htmlFor="start-date">From Date:</label>
   <input 
    type="date" 
    id="start-date" 
    value={moment(startDate).format('YYYY-MM-DD')}
    onChange={handleStartDateChange} 
   />

   <label htmlFor="end-date">To Date:</label>
   <input 
    type="date" 
    id="end-date" 
    value={moment(endDate).format('YYYY-MM-DD')}
    onChange={handleEndDateChange} 
  />

     <button className="SendCharge-Filter-Button" onClick={()=> {onClickApplyFilter()}}>
      Apply Filter
   </button>
</div>



 {/* Search Bar */}
 <form className="SendCharge-form">
    <button type='button' onClick={() => {onSearchClicked()}}>
      <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
        <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
    </button>

    <input 
      className="SendCharge-input" 
      placeholder="Search Member ID/Email ID/ Mobile No." 
      required type="text" 
      value={search}
      onChange={inputSearchHandler}
      onKeyDown={onSearchClicked} 
    />

    <button className="reset" type="button" onClick={()=>setSearch('')}>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
      </svg>
    </button>
  </form>





  {/* enable the button only when the checkbox is checked  */}


{/* <div>
<button className={`SendCharge-custom-btn approve ${!isCheckboxChecked ? 'disabled' : ''}`}
  onClick={() => {
    if (isCheckboxChecked) {
      setAcceptPopupOpen(true);
    }
  }}
  disabled={!isCheckboxChecked}>Send Batch</button>
</div> */}
      
    </div>
    <table>
      <thead>
        <tr>
          {/* <th>* Check All <input type="checkbox"onChange={(e) => setIsCheckboxChecked(e.target.checked)}/></th> */}
          <th>Sl No.</th>
          <th>Member ID </th>
          <th>Priority</th>
          <th>Amount($)</th>
          <th>Coins</th>
          <th>Charges(coins)</th>
          <th>Total Coins</th>
          <th>Remaining Time</th>
          <th>Date</th>
          <th>Time</th>
          <th>Transaction ID</th>
          <th>Receipt</th>
          <th>Status</th>
          <th>Status Date</th>
          <th>Status Time</th>
        </tr>
      </thead>
      <tbody>
      {loading && <LoadingSpinner />}
      {cashRequests.map((data, index) => {
      return(
        <tr key={index}>
          {/* <td>
          <input type="checkbox"onChange={(e) => setIsCheckboxChecked(e.target.checked)}/>
          </td> */}
          <td>{index+pagingCounter}</td>
          <td>{data.user.referralCode}</td>
          <td>{data.convenience.type}</td>
          <td>{parseFloat(data.price).toFixed(2)}</td>
          <td>{parseFloat((data.amount) - (data.convenienceAmount ? data.convenienceAmount : 0)).toFixed(2)}</td>
          <td>{data.convenienceAmount ? parseFloat(data.convenienceAmount).toFixed(2) : '--'}</td>
          <td>{parseFloat(data.amount).toFixed(2)}</td>

          <td>{getRemainingTime(data.createdAt, data.convenience.fulfillmentTime)}</td>

          <td>{moment(data.createdAt).format('DD-MM-YYYY')}</td>
          <td>{moment(data.createdAt).format('hh:mm:ss')}</td>
          <td>{data.transactionId ? data.transactionId : '--'}</td>
          <td>
            {data.fulfillmentTransactionProof &&
              <button className="SendCharge-btn-popup address-btn" onClick={()=>{setReceiptPopupOpen(true); setSingleRequest(data);}}>View</button>
            }
          </td>
          <td>{data.status}</td>
          <td>{moment(data.fulfilledAt).format('DD-MM-YYYY')}</td>
          <td>{moment(data.fulfilledAt).format('hh:mm:ss')}</td>
        </tr>
        )})}
      </tbody>
    </table>
    <Pagination 
      pageCount={pageCount} 
      onPageChange={handlePageChange}    
    />

    {/*----##################-------- All POPUPS HERE -----------################--*/}





{/*--@@@@@@@@@@@@@@ Receipt view @@@@@@@@@--*/}
{isReceiptPopupOpen && (
 <>
  <div className="SendCharge-overlay"></div>
  <div className="SendCharge-popup">
    <div className="SendCharge-popup-content">
      <h2>Recipt</h2>
      <img src={singleRequest.fulfillmentTransactionProof} alt="View Front Image" width="300" height="200" />
      <button className="SendCharge-close-btn" onClick={closeReceiptPopup}>
        <i className="fas fa-times"></i>
      </button>
     
    </div>
    <button className="SendCharge-done-btn" onClick={closeReceiptPopup}> 
        Done
      </button>
  </div>
</>
)}



{/*----##################-------- All Top BUTTONS POPUPS HERE -----------################--*/}



{/*--@@@@@@@@@@@@@@  Accept button Popup @@@@@@@@@--*/}
{isAcceptPopupOpen && (
<>
  <div className="SendCharge-overlay"></div>
  <div className="SendCharge-popup">

    <div class="Accept-SendCharge-popup-content">
        <h2>Batch ID: <span>SPA 0012</span></h2>
        <p>Are you sure you want to Send This Batch ?</p>

        <button class="btn-Accept Accept" onClick={()=>{setAcceptConfirmPopupOpen(true); setAcceptPopupOpen(false)}} >Confirm</button>

        <button class="btn-Accept Cancel" onClick={closeAcceptPopup} >Cancel</button>
       
        <button className="SendCharge-close-btn" onClick={closeAcceptPopup}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>

</>
)}





  </section>

        </main>
      </section>

      {timeoutPop && <TimeoutPop
       setTimeoutPop={setTimeoutPop}
     />}

    </>
  
  );
};

export default Sendecharge ;
