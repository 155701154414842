import React, { useEffect, useState } from 'react';
import './ActivityProfile.css'; 

import API from '../../../api/Api';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Pagination from '../../Pagination/Pagination';
import moment from 'moment';

const ActivityProfile = ({ userId }) => {
    const [listActivity, setListActivity] = useState([]); 
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10); 
    const [startDate, setStartDate] = useState(null);

    const activityProfileMethod = () => {
        setLoading(true);
        API.get("/staff/market/activity/list", {
          params: { 
            userId: userId,
            page: currentPage+1,
            limit: itemsPerPage,
            date: startDate,
        }
        }).then((res) => {
          setListActivity(res.data.data.docs); 
          setTotalRecords(res.data.data.totalDocs);  
        }).catch((err) => {
          // toast.error("Something went wrong.", {position:"top-center"}); 
        }).finally(() => {
            setLoading(false);
        });
    }

    const handlePageChange = ({ selected }) => { 
        setCurrentPage(selected);
    };
    const pageCount = Math.ceil(totalRecords / itemsPerPage);

    useEffect(() => {
        activityProfileMethod();
    }, [currentPage, startDate]); 

  return (
    <div className="activity-table-container">
      <div className='acti-date-div'>
        <label htmlFor="date-filter">Date Filter:</label> 
        <input
            className='acti-input'
            type="date"
            id="date-filter"
            value={moment(startDate).format('YYYY-MM-DD')}
            onChange={e => setStartDate(new Date(e.target.value))} 
        />
     </div>

      <table className="activity-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Login Time</th>
            <th>In Call Time</th>
            <th>Location</th>
            <th>Lat/Long</th>
            <th>Sim Slot 1</th>
            <th>Sim Slot 2</th>
            <th>Device Type</th>
          </tr>
        </thead>
        <tbody>
          {loading && <LoadingSpinner />}
          {listActivity.map((data, index) => {
          return(
          <tr key={index}>
            <td>{moment(data.loginTime).format('DD-MM-YYYY')}</td> 
            <td>{moment(data.loginTime).format('hh:mm:ss')}</td>
            <td>{ moment.utc(moment(data.logoutTime).diff(moment(data.loginTime))).format("hh:mm:ss") }</td>
            <td>
                {/* <a href="https://www.google.com/maps?q=40.7128,-74.0060" target="_blank" rel="noopener noreferrer">New York</a> */}
                {`${data.location.city}, ${data.location.state}, ${data.location.country} ${data.location.pincode}`}
            </td>
            <td>{data.latitude}, {data.longitude}</td>
            <td>
                {/* <img src="sim1.jpg" alt="Sim Slot 1" /> */}
                {data.sim && data.sim[0] && data.sim[0].operator} {data.sim && data.sim[0] && data.sim[0].mobile}
            </td>
            <td>
                {data.sim && data.sim[1] && data.sim[1].operator} {data.sim && data.sim[1] && data.sim[1].mobile}
            </td>
            <td>
                {data.emulator ? 'Emulator' : 'Physical'}
            </td> 
          </tr>
          )})}

        </tbody>
      </table>
      <Pagination 
        pageCount={pageCount} 
        onPageChange={handlePageChange}    
      />
    </div>
    
  );
};

export default ActivityProfile;
