import React, { useState } from 'react'; 
import './InvestSummaryPop.css'; 
import crossBlack from '../../Assets/Member-img/crossBlack.png'; 
import fileIcon from '../../Assets/Member-img/fileIcon.png'; 
import loadingGif from '../../Assets/Member-img/LoadingGif.gif'; 

const InvestSummaryPop = ({setShowInvestSummaryPop, singleTransaction, investData, onFinalInvestSubmit}) => {
  const [isloadingGif, setIsloadingGif] = useState(false);
 
  function addFormSubmit(event) { 
    event.preventDefault();
    setIsloadingGif(true);

    setTimeout(() => {
      onFinalInvestSubmit();
      setShowInvestSummaryPop(false); 
    }, 3000);   
  }

  return (
    <article className='InvestSummaryPop'>
        <h6 className='pop-title sumry-title1'><img src={fileIcon} alt="icon" className='file-icon'/>Summary</h6>
        <img className='pop-cross smm-cros' src={crossBlack} alt="Cross" onClick={()=>setShowInvestSummaryPop(false)} />
        <form onSubmit={(e) => addFormSubmit(e)}>
          <div className='input-main2 smm-main1'>
            <div className='input-div2 input-div3 sm-input-div1'>
                <div className='sumry-div'>
                    <h4>Member ID</h4>
                    <p>{singleTransaction?.referralCode}</p>
                </div>
                <div className='sumry-div'>
                    <h4>Name</h4>
                    <p>{singleTransaction?.name}</p>
                </div>
                <div className='sumry-div'>
                    <h4>Transaction Type</h4>
                    <p>Invest</p>
                </div>
                <div className='sumry-div'>
                    <h4>Coin Quantity</h4>
                    <p>{investData[0]?.amount}</p>
                </div>
            </div>
          </div>

          <div className='input-main sumry-main1'>
            <button type='submit' className='btn-cnf'>Proceed</button>
            <button type='button' className='btn-cncl' onClick={()=>setShowInvestSummaryPop(false)}>Cancel</button>
          </div>
        </form>

        {isloadingGif && <img src={loadingGif} alt="gif" className='spin-gif2'/>}
    </article>
  )
}

export default InvestSummaryPop; 