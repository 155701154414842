import React, { useState } from 'react'; 
import './OtpSentPop.css'; 
import crossIcon from '../../Assets/Member-img/crossIcon.png'; 
import loadingGif from '../../Assets/Member-img/LoadingGif.gif'; 

const OtpSentPop = ({setShowOtpSentPop, setShowInvestSummaryPop, setFinalOtp, finalEmail, finalMobile, onSendEmail, onSendMobile}) => {
  const [otp, setOtp] = useState(null);
  const [isloadingGif, setIsloadingGif] = useState(false);

  const resendOtp = () => {
    if(finalEmail) {
      onSendEmail(finalEmail);
    }
    if(finalMobile) {
      onSendMobile(finalMobile);   
    }
  }
 
  function addFormSubmit(event) { 
    event.preventDefault();
    setIsloadingGif(true);
    setFinalOtp(otp);

    setTimeout(() => {
      setShowOtpSentPop(false);
      setShowInvestSummaryPop(true); 
    }, 3000);  
  }

  return (
    <article className='OtpSentPop'>
        <h6 className='pop-title'>OTP Verification</h6>
        <img className='pop-cross' src={crossIcon} alt="Cross" onClick={()=>setShowOtpSentPop(false)} />
        <form onSubmit={(e) => addFormSubmit(e)}>
          <p className='title-receive'>OTP Sent</p>
          <div className='input-main'>
            <div className='input-div2'>
              <label htmlFor="title" className='sent-text'>OTP sent to <br/>{finalEmail ? finalEmail : finalMobile}</label> 
              <input 
                className='otp-input' 
                type="number" 
                name="title" 
                placeholder='0 - 0 - 0 - 0 - 0 - 0' 
                onChange={(e)=> setOtp(e.target.value)}
                required
              /> 
            </div>
          </div>

          <h5 className='resend-sent' onClick={()=> resendOtp()}>Resend</h5>

          <div className='input-main input-main-sent1'>
            {(otp === null || otp === '') &&
              <button type='button' className='btn-otp' disabled>Confirm</button>
            }
            {(otp && otp !== '') &&
              <button type='submit' className='btn-cnf'>Confirm</button>
            }

            <button type='button' className='btn-cncl' onClick={()=>setShowOtpSentPop(false)}>Cancel</button>
          </div>
        </form>

        {isloadingGif && <img src={loadingGif} alt="gif" className='spin-gif1'/>}
    </article>
  )
}

export default OtpSentPop; 