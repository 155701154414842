import React from 'react';
import '../Buy-Open/Buy-Open.css';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import moment from 'moment';

const Buyopen = ({listOrderBuy, loading, pagingCounter}) => {
    return (
        <div  className="Buy-Open-TABLE-Table-updated">
          <table className="Buy-Open-TABLE-Tpage-table">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Buy Crypto</th>
                <th>Paid</th>
                <th>To</th>
                <th>Payment</th>
                <th>Date</th>
                <th>Time</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {loading && <LoadingSpinner />}
              {listOrderBuy.map((data, index) => {
              return(
              <tr key={index}>
                <td>{index+pagingCounter}</td>
                <td>{parseFloat(data.buyCryptoAmount).toFixed(2)} {data.buyCryptoSymbol}</td>
                <td>{parseFloat(data.paidAmount).toFixed(2)}</td>
                <td>{data.to}</td>
                <td>{data.payment.upi}</td>
                <td>{moment(data.updatedAt).format('DD-MM-YYYY')}</td>
                <td>{moment(data.updatedAt).format('hh:mm:ss')}</td>
                <td>Pending</td>
              </tr>
              )})}
              
            </tbody>
          </table>  
        </div>
    );
  }


export default Buyopen;
