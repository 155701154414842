// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InvestSummaryPop {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    min-width: 380px;
    height: 340px;
    border-radius: 10px; 
    z-index: 1000;
    position: fixed;
    top: 23%;
    left: 38%; 
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.sumry-title1 {
    background: linear-gradient(0deg, #FBF8EB, #FBF8EB) !important;
    color: #000;
}

.smm-cros {
    position: relative;
    left: 26rem;
}

.file-icon {
    width: 22px;
    position: relative;
    top: 5px;
    right: 3px;
}

.smm-main1 {
    padding-left: 20px;
    padding-right: 20px;
}

.sm-input-div1 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.sumry-div {
    display: flex;
    justify-content: space-between;
    gap: 6rem;
    padding: 3px 0px;
}

.sumry-div h4 {
    font-weight: 600;
}

.sumry-main1 {
    margin-top: 40px;
}

.spin-gif2 {
    width: 75px;
    z-index: 2000;
    position: absolute;
    left: 12rem;
    top: 11rem;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Invest_Otp/InvestSummaryPop.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,QAAQ;IACR,SAAS;IACT,mBAAmB;IACnB,qCAAqC;AACzC;;AAEA;IACI,8DAA8D;IAC9D,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,UAAU;AACd","sourcesContent":[".InvestSummaryPop {\r\n    display: flex;\r\n    flex-direction: column;\r\n    max-width: 900px;\r\n    min-width: 380px;\r\n    height: 340px;\r\n    border-radius: 10px; \r\n    z-index: 1000;\r\n    position: fixed;\r\n    top: 23%;\r\n    left: 38%; \r\n    background: #ffffff;\r\n    box-shadow: 0px 4px 4px 0px #00000040;\r\n}\r\n\r\n.sumry-title1 {\r\n    background: linear-gradient(0deg, #FBF8EB, #FBF8EB) !important;\r\n    color: #000;\r\n}\r\n\r\n.smm-cros {\r\n    position: relative;\r\n    left: 26rem;\r\n}\r\n\r\n.file-icon {\r\n    width: 22px;\r\n    position: relative;\r\n    top: 5px;\r\n    right: 3px;\r\n}\r\n\r\n.smm-main1 {\r\n    padding-left: 20px;\r\n    padding-right: 20px;\r\n}\r\n\r\n.sm-input-div1 {\r\n    padding-top: 10px;\r\n    padding-bottom: 10px;\r\n}\r\n\r\n.sumry-div {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    gap: 6rem;\r\n    padding: 3px 0px;\r\n}\r\n\r\n.sumry-div h4 {\r\n    font-weight: 600;\r\n}\r\n\r\n.sumry-main1 {\r\n    margin-top: 40px;\r\n}\r\n\r\n.spin-gif2 {\r\n    width: 75px;\r\n    z-index: 2000;\r\n    position: absolute;\r\n    left: 12rem;\r\n    top: 11rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
