import React from 'react';
import '../My_Team/My_Team_Table.css'; 
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import moment from 'moment';

const myteamtable = ({listTeamRight, loading}) => {
    return (
      <div className="My-Team-Table-TABLE-table-content-2">
        <div className="My-Team-Table-TABLE-Table-updated">
          <table className="My-Team-Table-TABLE-Tpage-table"> 
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Member Name</th>
                <th>Member ID</th>
                <th>Joining Date</th> 
                <th>Hand</th>
                <th>Invested Balance</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {loading && <LoadingSpinner />}
              {listTeamRight?.map((data, index) => {
              return(
              <tr key={index}>
                <td>{index+1}</td>
                <td>{data.name}</td>
                <td>{data.referralCode}</td>
                <td>{moment(data.createdAt).format('DD-MM-YYYY')}</td>
                <td>{data.hand}</td>
                <td>{parseFloat(data.investedBalance).toFixed(2)}</td>
                <td>
                  {data.status === false && <span className="My-Team-Table-TABLE-red-cercle"></span>}
                  {data.status === true && <span className="My-Team-Table-TABLE-green-cercle"></span>}
                </td>
              </tr>
              )})}
            </tbody>
          </table>


          {/* <div className="My-Team-Table-TABLE-Table-buttom">
            <select name="">
              <option value="#">10/Pages</option>
              <option value="#">1</option>
              <option value="#">2</option>
              <option value="#">3</option>
              <option value="#">4</option>
              <option value="#">5</option>
              <option value="#">6</option>
              <option value="#">7</option>
              <option value="#">8</option>
              <option value="#">9</option>
              <option value="#">10</option>
            </select>
            <p>
              <a href="#"><i className="fa fa-chevron-left"></i></a>1 of 1<a href="#"><i className="fa fa-chevron-right"></i></a>
            </p>
          </div> */}


        </div>
      </div>
    );
  }


export default myteamtable;
