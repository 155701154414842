import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../Assets/All-Css/C2C/C2C.css';

import Acceptimg from '../../Assets/Login-img/Accept-pop.png'
import Rejectsub from '../../Assets/Login-img/Reject-submit.png'
import '../../Assets/style.css';
import profilepng from '../../Assets/Login-img/Component 1.png';
import { useEffect } from 'react';

import API from '../../api/Api';
import LoadingSpinner from '../../Componets/LoadingSpinner/LoadingSpinner';
import { toast } from 'react-toastify';  
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Pagination from '../../Componets/Pagination/Pagination';
import { SessionTimer, getRemainingTime } from '../../util/UtilMethod';
import { getAuthToken, getTokenDuration } from '../../util/Auth';
import TimeoutPop from '../../Componets/TimeoutPop/TimeoutPop';


const C2C = () => {
  const [sidebarHidden, setSidebarHidden] = useState(false); 
  const [allDropdown, setAllDropdown] = useState([]);

  // State to manage session time
  const [sessionTime, setSessionTime] = useState(0);

  const nav = useNavigate ();

    // TOKEN EXPIRATION
    const navigate = useNavigate();   
    const [timeoutPop, setTimeoutPop] = useState(false); 
    const token = getAuthToken();
    useEffect(() => {
      if(!token) { 
        navigate('/loginform');
        return; 
      }
      if (token === 'EXPIRED') {
        navigate('/loginform');
        return; 
      }
      const tokenDuration = getTokenDuration();  
      setTimeout(() => {
        navigate('/loginform'); 
      }, tokenDuration);     // 1*60*60*1000   
    }, [token]);  
  
    useEffect(() => {
      const tokenDuration = getTokenDuration(); 
      const timer = setTimeout(() => {
        setTimeoutPop(true);
      }, (tokenDuration - 15*60*1000));
      return () => clearTimeout(timer);
    }, []);
    // TOKEN EXPIRATION END

  useEffect(() => {
    setAllDropdown(document.querySelectorAll('#sidebar .side-dropdown'));
  }, []);



  useEffect(() => {
    // Attach event listeners for dropdown elements
    allDropdown.forEach((dropdown) => {
      const parentLink = dropdown.closest('li');
      parentLink.addEventListener('click', (e) => handleMenuClick(e, dropdown));
    });

    // Set an interval to update the session time every second
    const timerInterval = setInterval(() => {
      setSessionTime((prevTime) => prevTime + 1);
    }, 1000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(timerInterval);

      // Remove event listeners
      allDropdown.forEach((dropdown) => {
        const parentLink = dropdown.closest('li');
        parentLink.removeEventListener('click', (e) => handleMenuClick(e, dropdown));
      });
    };
  }, [allDropdown]);

  const handleDropdownClick = (e, dropdown) => {
    e.preventDefault();

    const isDropdownActive = e.currentTarget.classList.contains('active');

    // Close all dropdowns
    allDropdown.forEach((item) => {
      const aLink = item.parentElement.querySelector('a:first-child');
      aLink.classList.remove('active');
      item.classList.remove('show');
    });

    // Toggle the clicked dropdown
    e.currentTarget.classList.toggle('active', !isDropdownActive);
    dropdown.classList.toggle('show', !isDropdownActive);
  };

  const handleToggleSidebar = () => {
    setSidebarHidden((prevSidebarHidden) => !prevSidebarHidden);
  };

  const handleProfileClick = () => {
    const profileLink = document.querySelector('.profile-link');
    profileLink.classList.toggle('show');
  };

  const handleMenuClick = (e, dropdown) => {
    e.preventDefault();
    dropdown.classList.toggle('show');
  };

  // Convert seconds to HH:MM:SS format
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const pad = (num) => (num < 10 ? '0' + num : num);

    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  };

  const [isC2CPopupOpen, setC2CPopupOpen] = useState(false);

  // popup buttons
  const [isAcceptPopupOpen, setAcceptPopupOpen] = useState(false);
  const [isAcceptConfirmPopupOpen, setAcceptConfirmPopupOpen] = useState(false);

  const [isRejectPopupOpen, setRejectPopupOpen] = useState(false);
  const [isRejectSubmitPopupOpen, setRejectSubmitPopupOpen] = useState(false);

 

  // enable the button only when the checkbox is checked 
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);



 // Accept close
 const closeC2CPopup = () => {
  setC2CPopupOpen(false);
};

  
  // Accept close
  const closeAcceptPopup = () => {
    setAcceptPopupOpen(false);
  };

    // Accept close
    const closeAcceptConfirmPopup = () => {
      setAcceptConfirmPopupOpen(false);
    };
  
  // Rejection close
  const closeRejectionPopup = () => {
    setRejectPopupOpen(false);
  };

    // Rejection close
    const closeRejectSubmitPopup = () => {
      setRejectSubmitPopupOpen(false);
    };


  // API DATA //////////////////////////////////////
  const sessionTimer = SessionTimer();
  const [loading, setLoading] = useState(false);
  const [listC2C, setListC2C] = useState([]);
  const [singleC2C, setSingleC2C] = useState([]);
  const [reason, setReason] = useState(''); 

  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10); 
  const [pagingCounter, setPagingCounter] = useState(1);

  const [pagePermission, setPagePermission] = useState([]); 
  const pagePermissionMethod = () => { 
    API.get("/staff/account/role/allow-pages").then((res) => {
      setPagePermission(res.data.data); 
    }).catch((err) => { 
    }).finally(() => {
    });  
  }

  const listC2CMethod = () => {
    setLoading(true);
    API.get("/staff/withdraw/list/pending", {
      params: {
        page: currentPage+1,
        limit: itemsPerPage,
      }
    }).then((res) => {
      setListC2C(res.data.data.docs); 
      setTotalRecords(res.data.data.totalDocs); 
      setPagingCounter(res.data.data.pagingCounter);
    }).catch((err) => {
      toast.error("Something went wrong.", {position:"top-center"}); 
    }).finally(() => {
      setLoading(false);
    });  
  }

  const handlePageChange = ({ selected }) => { 
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(totalRecords / itemsPerPage);

  const acceptC2CMethod = () => {
    const body = {
      id: singleC2C._id
    };
    API.post("/staff/withdraw/verify/complete", body).then((res) => { 
      listC2CMethod(); 
      toast.success("Withderaw request accepted successfully.", {position:"top-center"});  
    }).catch((err) => {
      toast.error("Something went wrong.", {position:"top-center"}); 
    });   
  }

  const rejectC2CMethod = () => {
    const body = {
      id: singleC2C._id,
      note: reason,
    };
    API.post("/staff/withdraw/verify/reject", body).then((res) => { 
      listC2CMethod(); 
      toast.success("Withderaw request rejected successfully.", {position:"top-center"}); 
    }).catch((err) => {
      toast.error("Something went wrong.", {position:"top-center"});
    });   
  }

  useEffect(() => {
    pagePermissionMethod(); 
  }, []);  

  useEffect(() => {
    listC2CMethod();
  }, [currentPage]);  


return (
<>
<section id="sidebar" className={sidebarHidden ? 'hide' : ''}> 
    <a href="#" className="brand">
      <img src={profilepng} alt="Profile Image" className="profile-image" />
      Support
    </a> 

    <ul className="side-menu">
      {pagePermission.some(el => el === 'dashboard') && 
      <li>
        <a href='#' className="active" onClick={()=>{navigate('/dashboard')}} >
          <i className="fas fa-tachometer-alt icon"></i> Dashboard
        </a>
      </li>}

      <li className="divider" data-text="main">
        Main
      </li>

       {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Manage Request----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'ManageRequests') && 
        <a href="#" >
          <i className="fas fa-folder icon fa-fw"></i> Manage Requests
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverification')}}>
              <i className="fas fa-id-card"></i> KYC Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderverification')}}>
              <i className="fas fa-briefcase"></i> Trader Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'recharge') && 
          <li>
            <a href="#" onClick={()=>{navigate('/recharge')}}>
              <i className="fas fa-phone"></i> Recharge Request
            </a> 
          </li>}

          {pagePermission.some(el => el === 'c2c') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2c')}}>
              <i className="fas fa-exchange-alt"></i> C2C Request
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputesattlement') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputesattlement')}}> 
              <i className="fas fa-ban"></i> Dispute Settlement
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'investotp') &&
      <li>
        <a href='#' onClick={()=>{navigate('/investotp')}}>
          <i className="fas fa-fingerprint icon fa-fw"></i>Invest Via OTP
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {/* --@@@@@@@@@@@@@@@@@@@@@@@@---View Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

     
      <li>
        {pagePermission.some(el => el === 'ViewReports') && 
        <a href="#" >
          <i className="fas fa-file-alt icon fa-fw"></i> View Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverifyedreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverifyedreport')}}>
              <i className="fas fa-id-card"></i> KYC Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderreport')}}>
              <i className="fas fa-briefcase"></i> Trader Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'rechargereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/rechargereport')}}>
              <i className="fas fa-donate"></i> Recharge Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'c2creport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2creport')}}>
              <i className="fas fa-money-check-alt"></i> C2C Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputereport')}}>
              <i className="fas fa-handshake"></i> Dispute Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'utrreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/utrreport')}}>
              <i className="fas fa-money-check"></i> UTR Status Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'purchaseReport') && 
          <li>
            <a href="#"onClick={()=>{navigate('/purchaseReport')}}>
              <i className="fas fa-gift"></i> Purchase Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'partnerreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/partnerreport')}}>
              <i className="fas fa-handshake"></i> Partner Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'ViewReports') && 
          <li>
            <a href="#" onClick={()=>{navigate('/noninvestedreport')}}>
              <i className="fas fa-file-alt"></i> Non Invested Report
            </a>
          </li>}

        </ul>
      </li>


    {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Send Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'SendReports') && 
        <a href="#" >
          <i className="fas fa-paper-plane icon fa-fw"></i> Send Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'sendecharge') && 
          <li>
            <a href='#' onClick={()=>{navigate('/sendecharge')}}>
              <i className="fas fa-file-alt"></i> Coin2Cash
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'memberManage') &&
      <li>
        <a href="#" onClick={()=>{nav('/memberManage')}} >
          <i className="fas fa-users icon fa-fw"></i> Manage Members
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'blockuser') &&
      <li>
        <a href="#" onClick={()=>{nav('/blockuser')}} >
          <i className="fas fa-user-circle icon fa-fw"></i> Block User
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'enquirymanage') &&
      <li>
        <a href="#" onClick={()=>{navigate('/enquirymanage')}}>
          <i className="fas fa-briefcase icon fa-fw"></i> Manage Enquiry
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'update') &&
      <li>
        <a href="#" onClick={()=>{navigate('/update')}}>
          <i className="fas fa-tools icon fa-fw"></i> Update Manager
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}
      
      {pagePermission.some(el => el === 'ChatSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/ChatSupport')}}>
          <i className="fas fa-comment-alt icon fa-fw"></i>Chat Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'TicketSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/TicketSupport')}}>
          <i className="fas fa-chalkboard-teacher icon fa-fw"></i>Ticket Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'feedback') &&
      <li>
        <a href='#' onClick={()=>{navigate('/feedback')}}> 
          <i className="fas fa-comment-medical icon fa-fw"></i>Feedbacks
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      <li className="divider" data-text="Exit">
        Exit
      </li>

      <div className="ads">
        <button className="Btn" onClick={()=>{navigate('/loginform')}}>
          <div className="sign">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text">Logout</div>
        </button>
      </div>
    </ul> 
  </section> 
  

{/* MAIN CONTENT */}
  <section id="content">
    <nav>
      <i className="bx bx-menu toggle-sidebar" onClick={handleToggleSidebar}></i>
      <form action="#">
        <div className="form-group">
          <span>Session Time: {formatTime(sessionTimer)}</span>
        </div>
      </form>
       
      <div className='notify'>
       <i className="fas fa-bell"></i>
     </div>


      <span className="divider"></span>
{/* --@@@@@@@@@@@@@@@@@@@@@@@@---Profile----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 
      <div className="profile">
        <img
          src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-File.png"
          onClick={handleProfileClick}
          alt="Profile"
        />
        <ul className="profile-link">
          <li>
            <a href="" onClick={()=>{navigate('/selfprofile')}}>
              <i className="bx bxs-user-circle icon"></i> Profile
            </a>
          </li>
 {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Logout----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 

          <li>
            <a href="#" onClick={()=>{navigate('/loginform')}}>
              <i className="bx bxs-log-out-circle"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>



{/* --@@@@@@@@@@@@@@@@@@@@@@@@---Main----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 

    <main>
      {/* {props.passedComponent} */}

      <section className="C2C-Management-container">

{/* @@@@@@@@@@@@@@@@@@@@_____top Heading____@@@@@@@@@@@@@@@@@@@@@ */}

    <div className="C2C-mange-top-heading">
    <h1><i className='bx bx-dollar'></i>Coin2Cash Requests</h1>
    </div>


{/* @@@@@@@@@@@@@@@@@@@@_____top filler search, back btn, date____@@@@@@@@@@@@@@@@@@@@@ */}

 {/* Back Button */}
    <div className="C2C-mange-Fillter-bar">


   <a className="c2c-button" href="#"  onClick={()=>{navigate('/c2creport')}}>
    <span className="c2c-button__icon-wrapper">
      <svg width="10" className="c2c-button__icon-svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 15">
        <path fill="currentColor" d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"></path>
      </svg>
      
      <svg className="c2c-button__icon-svg c2c-button__icon-svg--copy" xmlns="http://www.w3.org/2000/svg" width="10" fill="none" viewBox="0 0 14 15">
        <path fill="currentColor" d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"></path>
      </svg>
    </span>
   Report
  </a>



  {/* enable the button only when the checkbox is checked  */}


<div>
{pagePermission.some(el => el === 'c2cBtnAccept') &&
<button className={`c2c-custom-btn approve ${!isCheckboxChecked ? 'disabled' : ''}`}
  onClick={() => {
    if (isCheckboxChecked) {
      setC2CPopupOpen(true);
    }
  }}
  disabled={!isCheckboxChecked}> Accept</button>}


{pagePermission.some(el => el === 'c2cBtnReject') &&
<button
  className={`c2c-custom-btn edit ${!isCheckboxChecked ? 'disabled' : ''}`}
  onClick={() => {
    if (isCheckboxChecked) {
      setRejectPopupOpen(true);
    }
  }}
  disabled={!isCheckboxChecked}> Reject</button>}

</div>
</div>

    <table>
      <thead>
        <tr>
          <th>
            Check
            {/* <input type="checkbox"onChange={(e) => setIsCheckboxChecked(e.target.checked)}/> */}
          </th>
          <th>Sl No.</th>
          <th>Member ID </th>
          <th>Priority</th>
          <th>Date</th>
          <th>Time</th>
          <th>Remaining Time</th>
        </tr>
      </thead>
      <tbody>
      {loading && <LoadingSpinner />}
      {listC2C.map((data, index) => {
      return(
        <tr key={index}>
          <td>
          <input 
            type="checkbox"
            onChange={(e) => setIsCheckboxChecked(e.target.checked)}
            onClick={() => {setSingleC2C(data)}}
          />
          </td>
          <td>{index+pagingCounter}</td>
          <td>{data.user.referralCode}</td>
          <td>{data.convenience.type}</td>
          <td>{moment(data.createdAt).format('DD-MM-YYYY')}</td>
          <td>{moment(data.createdAt).format('hh:mm:ss')}</td>
          <td>{getRemainingTime(data.createdAt, data.convenience.fulfillmentTime)}</td>
        </tr>
      )})}
      </tbody>
    </table>
    
    <Pagination 
      pageCount={pageCount} 
      onPageChange={handlePageChange}    
    />

    {/*----##################-------- All POPUPS HERE -----------################--*/}




{/*--@@@@@@@@@@@@@@  Accept button Popup @@@@@@@@@--*/}
{isC2CPopupOpen && (
<>
  <div className="c2c-overlay"></div>
  <div className="c2c-popup">

    <div class="c2c-Accept-popup-content">
        <h2>Coin2Cash Request Summary</h2>

{/*--01--*/}
    <div className="Coin2Cash-main">
    <div className="c2ccontainer-1">
      <div className="user-info">User Information</div>
      <div className="priority">Priority :<span>{singleC2C.convenience.type}</span></div>
    </div>

    <div className="c2ccontainer-2">
      <div>
        <div className="infotext">Member ID: <span>{singleC2C.user.referralCode}</span></div>
        <div className="infotext">Member Name:<span>{singleC2C.user.name}</span></div>
      </div>
      <div>
        <div className="infotext">Request Date: <span>{moment(singleC2C.createdAt).format('DD-MM-YYYY')}</span></div>
        <div className="infotext">Remaining:<span>{moment.utc().startOf('day').add(singleC2C.convenience.fulfillmentTime, 'minutes').format('H:mm')}</span></div>
      </div>
    </div>
  </div>
{/*--02--*/}

<div className="Coin2Cash-main">
    <div className="c2ccontainer-1">
      <div className="user-info">Beneficiary Information</div>
    </div>

    <div className="c2ccontainer-2">
      <div>
        <div className="infotext">Beneficiary Name: <span>{singleC2C.beneficiary.name}</span></div>
        <div className="infotext">Bank Name:<span>{singleC2C.beneficiary.bankName}</span></div>
      </div>
      <div>
        <div className="infotext">Bank Account No.: <span>{singleC2C.beneficiary.accountNumber}</span></div>
        <div className="infotext">IFSC :<span>{singleC2C.beneficiary.ifsc}</span></div>
      </div>
    </div>
  </div>

  {/*--03--*/}

 <div className="Coin2Cash-main">
    <div className="c2ccontainer-1">
      <div className="user-info">User Coin2Cash Information</div>
    </div>

    <div className="c2ccontainer-2">
      <div>
        <div className="infotext">Amount:<span>₹ {parseFloat(singleC2C.price).toFixed(2)}</span></div>
        <div className="infotext">Coins:<span>{parseFloat(singleC2C.amount).toFixed(2)}</span></div>
        <div className="infotext">Priority Charges (Coins)<span>20.00</span></div>
      </div>
      <div>
        <div className="infotext" style={{color:'red'}}>Remaining Total Balance (Coins)<span style={{color:'red'}}> {parseFloat(singleC2C.user.totalIncome).toFixed(2)}</span></div>
        <div className="infotext" style={{color:'#01702D'}}>Total Invest Balance (Coins)<span style={{color:'#01702D'}}>{parseFloat(singleC2C.user.investedBalance).toFixed(2)}</span></div>
        <div className="infotext" style={{color:'#0527D9'}}>Total Stock Balance (Coins)<span style={{color:'#0527D9'}}> {parseFloat(singleC2C.user.stockBalance).toFixed(2)}</span></div>
      </div>
      <div>
        
    
      </div>
    </div>
    
  </div>

        <button class="c2c-btn-Accept Accept" onClick={()=>{setAcceptPopupOpen(true); setC2CPopupOpen(false)}} >Confirm</button>

        <button class="c2c-btn-Accept Cancel" onClick={closeC2CPopup} >Cancel</button>
       
        <button className="c2c-close-btn" onClick={closeC2CPopup}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>

</>
)}





{/*----##################-------- All Top BUTTONS POPUPS HERE -----------################--*/}


{/*--@@@@@@@@@@@@@@  Accept button Popup @@@@@@@@@--*/}
{isAcceptPopupOpen && (
<>
  <div className="c2c-overlay"></div>
  <div className="c2c-popup">

    <div class="c2c-Accept-popup-content">
        <h2>Confirmation</h2>
        <p>Are you sure want to Accept Purchase Request ?</p>

        <button class="c2c-btn-Accept Accept" onClick={()=>{setAcceptConfirmPopupOpen(true); setAcceptPopupOpen(false); acceptC2CMethod();}} >Confirm</button>

        <button class="c2c-btn-Accept Cancel" onClick={closeAcceptPopup} >Cancel</button>
       
        <button type='button' className="c2c-close-btn" onClick={closeAcceptPopup}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>

</>
)}



{/*--@@@@@@@@@@@@@@  Accept Confirm @@@@@@@@@--*/}

{isAcceptConfirmPopupOpen && (
<>
  <div className="c2c-overlay"></div>
  <div className="c2c-popup">

    <div class="c2c-Accept-popup-content">
    <img src={Acceptimg} alt="View Front Image" width="100" height="100" />
        <h2>Purchase Request Verified</h2>
       
        <button className="c2c-close-btn" onClick={closeAcceptConfirmPopup}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>

</>
)}




{/*--@@@@@@@@@@@@@@ Reject Reason @@@@@@@@@--*/}
{isRejectPopupOpen && (
<>
  <div className="c2c-overlay"></div>
  <div className="c2c-popup">
    <div className="c2c-popup-content">
      <h2>Reason For Rejection</h2>

      <textarea
        className="rejection-reason-textarea"
        placeholder="Type here..........."
        value={reason}
        onChange={(event)=> setReason(event.target.value)}
      ></textarea>

      <button className="c2c-close-btn" onClick={closeRejectionPopup}>
        <i className="fas fa-times"></i>
      </button>
    </div>
    <button type='button' className="c2c-done-btn"onClick={()=>{setRejectSubmitPopupOpen(true); setRejectPopupOpen(false); rejectC2CMethod();}} >
      Submit
    </button>
  </div>
</>
)}



{/*--@@@@@@@@@@@@@@ Reject Submit popup @@@@@@@@@--*/}

{isRejectSubmitPopupOpen && (
<>
  <div className="c2c-overlay"></div>
  <div className="c2c-popup">

    <div class="c2c-Accept-popup-content">
    <img src={Rejectsub} alt="View Front Image" width="100" height="100" />
        <h2 style={{color:'red'}}> Request Rejected</h2>
       
        <button className="c2c-close-btn" onClick={closeRejectSubmitPopup}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>

</>
)}







  </section>
      
    </main>
  </section>

    {timeoutPop && <TimeoutPop
       setTimeoutPop={setTimeoutPop}
     />}


    </>
  );
};

export default C2C ;
