import React from 'react';
// import '../Transfer/Transfer_records.css';
// import '../../Txn-Records/Transfer/Transfer_records.css';
// import '../Wallet_Deposit/Wallet_Deposit.css';
import './Wallet_Deposit.css';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';
import moment from 'moment';

const Depositwallet = ({listWalletDeposit, loading, pagingCounter, setInner2Tab}) => {
  return (
    <>
    <div class="Deposit-table-content">   
             <div class="Deposit-head-button">
                 <button class="Deposit-head-button-1" onClick={()=> setInner2Tab(1)}>Deposit</button>
                 <button class="Deposit-head-button-2" onClick={()=> setInner2Tab(2)}>Transfer</button>
             </div>
    
             <div class="Deposit-table-content-1">
                 <table>
                     <thead>
                         <tr>
                           <th>Sl.</th>
                            <th>Asset</th>
                             <th>Volume</th>
                             <th>USDT</th>
                             <th>Date</th>
                             <th>Time</th>
                            <th>Status</th>
                         </tr>
                     </thead>

                     <tbody>
                        {loading && <LoadingSpinner />}
                        {listWalletDeposit.map((data, index) => {
                        return(
                         <tr key={index}>
                           <td>{index+pagingCounter}</td>  
                           <td>
                             <div class="stock-info">
                                 <img src={data.cryptoSymbolLogo} alt="Stock Image"/>
                                 <span>{data.cryptoName}</span>
                             </div>
                         </td>
                             <td>{parseFloat(data.amount).toFixed(2)}</td>
                             <td>{parseFloat(data.USDTPrice).toFixed(2)}</td>
                             <td>{moment(data.createdAt).format('DD-MM-YYYY')}</td>
                             <td>{moment(data.createdAt).format('hh:mm:ss')}</td>
                             <td>
                              <span 
                                class={`${data.status === 'PENDING' ? 'red-td' : 'Green-td'}`}
                              >{data.status}</span>
                             </td>
                         </tr>
                        )})}
                     </tbody>
                 </table>
             </div>
           </div>
    </>
  );
};

export default Depositwallet;
