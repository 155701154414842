import React from 'react';
// import '../Transfer-Records/Transfer.css'; 
import './Transfer.css';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import moment from 'moment';
import { getRemainingTime } from '../../../util/UtilMethod'; 

const Transfer = ({listTransferRecord, loading, pagingCounter, userDetail}) => {
  return (
    <div className="tsfr-rec-table-content">
              
    <div className="tsfr-rec-head-button">
       <div><h5>Stcok Balance: {parseFloat(userDetail.stockBalance).toFixed(2)}</h5></div>
    </div>

    <div className="tsfr-rec-table-content-1">
        <table>
            <thead>
                <tr>
                  <th>Sl.</th>
                   {/* <th>Beneficiary</th> */}
                    <th>VTCN</th>
                    <th>Conv.Fee%</th>
                    <th>Type</th>
                    <th>Priority</th>
                    <th>Processing Time</th> 
                   <th>Payout($)</th>
                   <th>Txn.ID</th>
                   <th>Date</th>
                   <th>Time</th>
                   <th>Status</th>
                </tr>
            </thead>

            <tbody>
                {loading && <LoadingSpinner />}
                {listTransferRecord.map((data, index) => {
                return(
                <tr key={index}>
                  <td>{index+pagingCounter}</td>  
                    {/* <td>VT-123456</td> */}
                    <td>{parseFloat(data.amount).toFixed(2)}</td>
                    <td>{data.convenience.rate}</td>
                    <td>{data.paymentMethod}</td>
                    <td>{data.convenience.type}</td>
                    <td>{getRemainingTime(data.createdAt, data.convenience.fulfillmentTime)}</td>
                    <td>
                      <span className="red-td">{parseFloat(data.price).toFixed(2)}</span>
                    </td>
                    <td>{data.transactionId ? data.transactionId : '--'}</td>
                    <td>{moment(data.createdAt).format('DD-MM-YYYY')}</td>
                    <td>{moment(data.createdAt).format('hh:mm:ss')}</td>
                    <td>
                      <span 
                      className={`${data.status === 'COMPLETE' ? 'Green-td' : 'red-td'}`}
                    >{data.status}</span>
                    </td>
                </tr>
                )})}       
            </tbody>
        </table>
    </div>

  </div>
  );
};

export default Transfer;
