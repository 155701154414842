import { redirect } from "react-router-dom";

export function getTokenDuration() {
    const storedExpirationDate = localStorage.getItem('expiration');
    const expirationDate = new Date(storedExpirationDate);
    const now = new Date();
    const duration = expirationDate.getTime() - now.getTime(); 
    return duration; 
}

export function getAuthToken() {
    const accessToken = localStorage.getItem('accessToken');   

    if(!accessToken) {
        localStorage.removeItem('loggedIn');
        return; 
    }

    const tokenDuration = getTokenDuration();  
    if(tokenDuration < 0) {
        return 'EXPIRED';
    }

    return accessToken; 
}

export function getLoggedInStatus() {
    const loggedIn = localStorage.getItem('loggedIn');
    return loggedIn; 
} 

export function tokenLoder() {
    return getAuthToken();  
}

export function checkAuthLoader() {
    const token = getAuthToken();
    if(!token) {
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('expiration'); 
        return redirect('/loginform');
    }
    return null;
}
