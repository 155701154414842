import React, { useEffect, useState } from 'react';
import '../Chat_Complete/Complete_Chat.css';
import tableprofile from '../image/profile-2.jpeg';

import API from '../../../api/Api';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from '../../../Componets/LoadingSpinner/LoadingSpinner'; 
import Pagination from '../../../Componets/Pagination/Pagination';
import moment from 'moment';  


const ChatComplete = () => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pagingCounter, setPagingCounter] = useState(1);
    const [listCompleteChat, setListCompleteChat] = useState([]);

    const [search, setSearch] = useState(null);
    const [searchData, setSearchData] = useState(null); 

    const listChatMethod = () => {
        setLoading(true);
        API.get("/staff/chat/approved/list", {
          params: { 
            page: currentPage+1,
            limit: itemsPerPage,
            search: searchData,
          }
        }).then((res) => { 
          setListCompleteChat(res.data.data.docs); 
          setTotalRecords(res.data.data.totalDocs); 
          setPagingCounter(res.data.data.pagingCounter);
        }).catch((err) => {
          // toast.error("Something went wrong.", {position:"top-center"}); 
        }).finally(() => {
          setLoading(false);
        }); 
    }

    const handlePageChange = ({ selected }) => { 
        setCurrentPage(selected);
    };
    const pageCount = Math.ceil(totalRecords / itemsPerPage);

    function inputSearchHandler(event) {
        setSearch(event.target.value);  
    }
    const onSearchClicked = () => {
        setSearchData(search);
        setSearch(null);
    }
  
    useEffect(() => {
        listChatMethod();
    }, [currentPage, searchData]); 

 
return (
<>
<div className="Chat-support-container-2">
                <div className="complete-box-title">
                    <h2>Completed</h2>
                    <div className="complete-search-box">
                        <input 
                            type="search" 
                            name="search" 
                            id="search" 
                            placeholder="Search People"
                            value={search}
                            onChange={inputSearchHandler}
                            onKeyDown={onSearchClicked}
                        />

                        <i 
                            className="fa fa-search" 
                            style={{marginTop: '7px'}}
                            onClick={() => {onSearchClicked()}}
                        ></i>
                    </div>
                </div>

                <div className="complete-table-content">
                    <table>
                        <thead>
                            <tr>
                                <th>SL NO.</th>
                                <th>Profile Pic</th>
                                <th>Name </th>
                                <th>Member ID</th>
                                <th>Email</th>
                                <th>Mobile Number</th>
                                <th>Date & Time</th>
                                <th>Resolved by</th>
                            </tr>
                        </thead>

                        <tbody>
                        {loading && <LoadingSpinner />}
                        {listCompleteChat?.map((data, index) => {
                        return(
                            <tr key={index}>
                                <td>{index+pagingCounter}</td>
                                <td>
                                    <img src={data.user.kyc.userSelfie} alt="photo"/>
                                </td>
                                <td>{data.user.name}</td>
                                <td>{data.user.referralCode}</td>
                                <td>{data.user.email}</td>
                                <td>{data.user.mobile}</td>
                                <td>{moment(data.createdAt).format('DD-MM-YYYY')}&nbsp;&nbsp;{moment(data.createdAt).format('hh:mm:ss')}</td>
                                <td className="green">{data.resolvedBy?.referralCode}</td>
                            </tr>
                        )})}
                        </tbody>
                    </table>
                </div>
                
                <Pagination 
                  pageCount={pageCount} 
                  onPageChange={handlePageChange}    
                />

                {/* <div className="complete-Pagination-box">
                    <a href="#"><i className="fa fa-angle-double-left"></i></a>
                    <a href="#">1</a>
                    <a href="#">2</a>
                    <a href="#">3</a>
                    <a href="#">4</a>
                    <a href="#">5</a>
                    <a href="#"><i className="fa fa-angle-double-right"></i></a>
                </div> */}
            </div>
</>
);
};

export default ChatComplete;