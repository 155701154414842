// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prt-report-div {
    gap: 18px;
}

.prt-tab {
    width: 140px !important;
    font-weight: 600;
    background: none !important;
    border: 1px solid #04356A !important;
    color: #1e2b8f !important;
    box-shadow: 0px 4px 4px 0px #00000040 !important;
}

.prt-tab-active {
    color: #ffffff !important;
    background: #04356A !important;
}

.switch-btn {
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/View_Report/PartnerReport.css"],"names":[],"mappings":"AAAA;IACI,SAAS;AACb;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,2BAA2B;IAC3B,oCAAoC;IACpC,yBAAyB;IACzB,gDAAgD;AACpD;;AAEA;IACI,yBAAyB;IACzB,8BAA8B;AAClC;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".prt-report-div {\r\n    gap: 18px;\r\n}\r\n\r\n.prt-tab {\r\n    width: 140px !important;\r\n    font-weight: 600;\r\n    background: none !important;\r\n    border: 1px solid #04356A !important;\r\n    color: #1e2b8f !important;\r\n    box-shadow: 0px 4px 4px 0px #00000040 !important;\r\n}\r\n\r\n.prt-tab-active {\r\n    color: #ffffff !important;\r\n    background: #04356A !important;\r\n}\r\n\r\n.switch-btn {\r\n    cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
