// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `    .input-file {
      color: #878787;
    }
    .input-file::-webkit-file-upload-button {
      background: #3c4fe0;
      border: 2px solid #3c4fe0;
      border-radius: 4px;
      color: #fff;
      cursor: pointer;
      font-size: 12px;
      outline: none;
      padding: 10px 25px;
      text-transform: uppercase;
      -webkit-transition: all 1s ease;
      transition: all 1s ease;
    }
    
    .input-file::-webkit-file-upload-button:hover {
      background: #1e2b8f;
      border: 2px solid #535353;
      color: #fff;
    }`, "",{"version":3,"sources":["webpack://./src/Pages/BlockUser/BlockUser.css"],"names":[],"mappings":"IAAI;MACE,cAAc;IAChB;IACA;MACE,mBAAmB;MACnB,yBAAyB;MACzB,kBAAkB;MAClB,WAAW;MACX,eAAe;MACf,eAAe;MACf,aAAa;MACb,kBAAkB;MAClB,yBAAyB;MACzB,+BAAuB;MAAvB,uBAAuB;IACzB;;IAEA;MACE,mBAAmB;MACnB,yBAAyB;MACzB,WAAW;IACb","sourcesContent":["    .input-file {\r\n      color: #878787;\r\n    }\r\n    .input-file::-webkit-file-upload-button {\r\n      background: #3c4fe0;\r\n      border: 2px solid #3c4fe0;\r\n      border-radius: 4px;\r\n      color: #fff;\r\n      cursor: pointer;\r\n      font-size: 12px;\r\n      outline: none;\r\n      padding: 10px 25px;\r\n      text-transform: uppercase;\r\n      transition: all 1s ease;\r\n    }\r\n    \r\n    .input-file::-webkit-file-upload-button:hover {\r\n      background: #1e2b8f;\r\n      border: 2px solid #535353;\r\n      color: #fff;\r\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
