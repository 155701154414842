import React from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';

import LoginForm from './Pages/Login';
import Sidenav from './Componets/Sidenav';

import Kycverification from './Pages/Request_Manage/Kycverification'; 
import Kycverifyedreport from './Pages/View_Report/Kycverifyreport';

import C2C from './Pages/Request_Manage/C2C';
import C2Creport from './Pages/View_Report/C2Creport';

import Recharge from './Pages/Request_Manage/Recharge';
import Rechargereport from './Pages/View_Report/Rechargereport';
 
import Purchase from './Pages/Request_Manage/Purchase';
import PurchaseReport from './Pages/View_Report/PurchaseReport';

import Disputesattlement from './Pages/Request_Manage/Disputesattlement'
import Disputereport from './Pages/View_Report/Disputereport'
import Tradeprofile from './Componets/Dispute_Profile/Tradeprofile'


import MemberManage from './Pages/Member_Manage/Membermanage';
import Userinfo from './Pages/Member_Manage/Users-Info/Usersinfo';
import Userprofile from './Componets/Member-Profile/User-Profile/Userprofile'


import Enquirymanage from './Pages/Enquiry_Manage/Enquirymanage';
import Enquiryview from './Pages/Enquiry_Manage/Enquiryview';


import Sendecharge from './Pages/Send_Report/Sendecharge';

import Update from './Pages/Update_Manage/Update';

import Network from './Componets/Member-Profile/Network/Network';

import Investbalance from './Componets/Member-Profile/Network/Invest_Balance/Investbalance'
import Partnerincome from './Componets/Member-Profile/Network/Partner-Income/Partnerincome'
import Princome from './Componets/Member-Profile/Network/PR-Income/Princome'
import Selfroi from './Componets/Member-Profile/Network/Self_ROI/Selfroi'
import Totalincome from './Componets/Member-Profile/Network/Total_Income/Totalincome'
import Workingincome from './Componets/Member-Profile/Network/Working_Income/Workingincome';
import WorkingIncomeTable from './Componets/Member-Profile/Network/Working_Income/WorkingIncomeTable';
import Levelroi from './Componets/Member-Profile/Network/Level_ROI/Levelroi';
import Nettransaction from './Componets/Member-Profile/Network/Network-Transaction/Nettransaction'

import Transaction from './Componets/Transaction/Transaction'
import Myteam from './Componets/My_Team/Myteam';
import Purchaserec from './Componets/Purchase-Record/Purchaserec';
import Foundhistory from './Componets/Found-History/Foundhistory';
import Partners from './Componets/Partners/Partners'
import Marketreport from './Componets/Market_Report/Marketreport'
import Holdingeport  from './Componets/Holding-Report/Holdingreport'
import Openorder from './Componets/Open-Oder/Openorder'
import Tradereport from './Componets/Trade-Report/Tradereport';
import PrivateRoute from './Componets/PrivateRoute';
import LogoutRoute from './Componets/LogoutRoute';
import Dashboard from './Pages/Dashboard';
import SvtcnHistory from './Componets/SVTCN-History/Svtcnhostory';
import UnblockInvest from './Componets/Member-Profile/Network/Unblock-Invest/Unblock';
import Publiczoneincome from './Componets/Member-Profile/Network/Public-Zone/Publiczone';
import UtrReport from './Pages/View_Report/UtrReport';
import TraderReport from './Pages/View_Report/TraderReport';
import TraderVerification from './Pages/Request_Manage/TraderVerification';
import TicketSupport from './Pages/Ticket_Support/Ticketsupport';
import TicketReply from './Pages/Ticket_Support/Ticket_Reply/Ticketreply';
import ChatSupport from './Pages/Chat_Subport/chatsupport';
import StakeReport from './Componets/StakeReport/StakeReport';
import SpotOrder from './Componets/SpotOrder/SpotOrder';
import SelfProfile from './Componets/Member-Profile/SelfProfile/SelfProfile';
import PartnerReport from './Pages/View_Report/PartnerReport';
import InvestOtp from './Pages/Invest_Otp/InvestOtp';
import Feedback from './Pages/Feedback/Feedback';
import NonInvestedReport from './Pages/View_Report/NonInvestedReport';
import BlockUser from './Pages/BlockUser/BlockUser';


function App() {
  return (
    <>
      <div className="App">
      <BrowserRouter> 
          <Routes>
            {/* <Route path="/login" element={<LoginForm/>} /> */} 
            <Route path="/" element={<LoginForm/>} />

            <Route path="/" element={ <PrivateRoute /> }>
              <Route path="dashboard" element={<Dashboard/>}  /> 
              <Route path="kycverification" element={<Kycverification/>}  />   
              {/* <Route path="sidenav" element={<Sidenav/>}  /> */}
              <Route path="kycverifyedreport" element={<Kycverifyedreport/>}  />
              <Route path="recharge" element={<Recharge/>}  />
              <Route path="rechargereport" element={<Rechargereport/>} />
              {/* <Route path="purchase" element={<Purchase/>} /> */}
              <Route path="purchaseReport" element={<PurchaseReport/>} /> 
              <Route path="c2c" element={<C2C/>} />
              <Route path="c2creport" element={<C2Creport/>} /> 
              <Route path="disputesattlement" element={<Disputesattlement/>} />
              <Route path="disputereport" element={ <Disputereport/>} />
              <Route path="tradeprofile" element={ <Tradeprofile/>} />
              <Route path="enquirymanage" element={<Enquirymanage/>} />
              <Route path="enquiryview" element={<Enquiryview/>} />
              <Route path="memberManage" element={<MemberManage/>} />
              <Route path="userinfo" element={<Userinfo/>} />
              <Route path="userprofile" element={<Userprofile/>} /> 
              <Route path="selfprofile" element={<SelfProfile />} /> 
              <Route path="sendecharge" element={<Sendecharge/>} />
              <Route path="update" element={<Update/>} /> 
              <Route path="network" element={<Network/>} /> 
              <Route path="investbalance" element={<Investbalance/>} /> 
              <Route path="partnerincome" element={ <Partnerincome/> } /> 
              <Route path="princome" element={ <Princome/> } /> 
              <Route path="selfroi" element={ <Selfroi/> } /> 
              <Route path="totalincom" element={ <Totalincome/> } /> 
              <Route path="UnblockInvest" element={ <UnblockInvest/> } /> 
              <Route path="Publiczoneincome" element={ <Publiczoneincome/> } /> 
              <Route path="workingincome" element={ <Workingincome/> } /> 
              <Route path="workingIncomeTable" element={ <WorkingIncomeTable/> } /> 
              <Route path="levelroi" element={ <Levelroi/> } /> 
              {/* <Route path="nettransaction" element={ <Nettransaction/> } />  */}
              <Route path="transaction" element={ <Transaction/> } /> 
              <Route path="myteam" element={ <Myteam/> } /> 
              <Route path="purchaserec" element={ <Purchaserec/> } /> 
              <Route path="foundhistory" element={ <Foundhistory/> } />
              <Route path="SvtcnHistory" element={ <SvtcnHistory/> } />
              <Route path="partners" element={ <Partners/> } />
              <Route path="marketreport" element={ <Marketreport/> } />
              <Route path="holdingeport" element={ <Holdingeport/> } />
              <Route path="openorder" element={ <Openorder/> } />
              <Route path="tradereport" element={ <Tradereport/> } />

              <Route path="utrreport" element={ <UtrReport/> } />
              <Route path="traderreport" element={ <TraderReport/> } />
              <Route path="partnerreport" element={ <PartnerReport /> } /> 
              <Route path="noninvestedreport" element={ <NonInvestedReport /> } />  

              <Route path="traderverification" element={ <TraderVerification/> } />  
              <Route path="stakereport" element={ <StakeReport /> } /> 
              <Route path="spotorder" element={ <SpotOrder /> } /> 

              <Route path="ChatSupport" element={ <ChatSupport/> } />
              <Route path="TicketSupport" element={ <TicketSupport/> } />
              <Route path="TicketReply" element={ <TicketReply/> } />
              <Route path="investotp" element={ <InvestOtp /> } />
              <Route path="feedback" element={ <Feedback /> } />
              <Route path="blockuser" element={ <BlockUser /> } />

              <Route path="loginform" element={ <LogoutRoute /> } />
            </Route>


          </Routes>
        </BrowserRouter>  
      </div>
    </>
  );
}

export default App;
   