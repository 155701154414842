import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import '../Dispute_Profile/Trade_Profile.css'
import profileimg from './images/Rectangle 668 (1).png'
import Like from './images/thumb_up.svg'
import Dislike from './images/thumb_down.svg'
import Disputes from './images/swords.svg'
import Buys from './images/shopping_cart.svg'
import Sell  from './images/storefront.svg'
import Exchange from './images/repeat_on.svg'
import Holding from './images/approval_delegation.svg'

import profilepng from '../../Assets/Login-img/Component 1.png';
import '../Purchase-Record/Purchase_Records.css'
import '../../Assets/style.css';
import { useEffect } from 'react';

import Tradedocuments from './Docoments/Tradedocoments'
import Personal from './Personal_Details/Personal';
import ActivityProfile from './ActivityProfile/ActivityProfile'; 
import TdsProfile from './TdsProfile/TdsProfile';

import API from '../../api/Api';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import { SessionTimer } from '../../util/UtilMethod';
import { getAuthToken, getTokenDuration } from '../../util/Auth';
import TimeoutPop from '../TimeoutPop/TimeoutPop';


const Tradeprofile = () => {
const navigate = useNavigate();

  // TOKEN EXPIRATION
  const [timeoutPop, setTimeoutPop] = useState(false); 
  const token = getAuthToken();
  useEffect(() => {
    if(!token) { 
      navigate('/loginform');
      return; 
    }
    if (token === 'EXPIRED') {
      navigate('/loginform');
      return; 
    }
    const tokenDuration = getTokenDuration();  
    setTimeout(() => {
      navigate('/loginform'); 
    }, tokenDuration);     // 1*60*60*1000   
  }, [token]);  

  useEffect(() => {
    const tokenDuration = getTokenDuration(); 
    const timer = setTimeout(() => {
      setTimeoutPop(true);
    }, (tokenDuration - 15*60*1000));
    return () => clearTimeout(timer);
  }, []);
  // TOKEN EXPIRATION END

const location = useLocation(); 
const [userId, setUserId] = useState(location.state?.userId);       


const [sidebarHidden, setSidebarHidden] = useState(false);  
const [allDropdown, setAllDropdown] = useState([]); 

// State to manage session time
const [sessionTime, setSessionTime] = useState(0);

const nav = useNavigate ();

useEffect(() => {
  setAllDropdown(document.querySelectorAll('#sidebar .side-dropdown'));
}, []);



useEffect(() => {
  // Attach event listeners for dropdown elements
  allDropdown.forEach((dropdown) => {
    const parentLink = dropdown.closest('li');
    parentLink.addEventListener('click', (e) => handleMenuClick(e, dropdown));
  });

  // Set an interval to update the session time every second
  const timerInterval = setInterval(() => {
    setSessionTime((prevTime) => prevTime + 1);
  }, 1000);

  // Clean up the interval on component unmount
  return () => {
    clearInterval(timerInterval);

    // Remove event listeners
    allDropdown.forEach((dropdown) => {
      const parentLink = dropdown.closest('li');
      parentLink.removeEventListener('click', (e) => handleMenuClick(e, dropdown));
    });
  };
}, [allDropdown]);

const handleDropdownClick = (e, dropdown) => {
  e.preventDefault();

  const isDropdownActive = e.currentTarget.classList.contains('active');

  // Close all dropdowns
  allDropdown.forEach((item) => {
    const aLink = item.parentElement.querySelector('a:first-child');
    aLink.classList.remove('active');
    item.classList.remove('show');
  });

  // Toggle the clicked dropdown
  e.currentTarget.classList.toggle('active', !isDropdownActive);
  dropdown.classList.toggle('show', !isDropdownActive);
};

const handleToggleSidebar = () => {
  setSidebarHidden((prevSidebarHidden) => !prevSidebarHidden);
};

const handleProfileClick = () => {
  const profileLink = document.querySelector('.profile-link');
  profileLink.classList.toggle('show');
};

const handleMenuClick = (e, dropdown) => {
  e.preventDefault();
  dropdown.classList.toggle('show');
};

// Convert seconds to HH:MM:SS format
const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const pad = (num) => (num < 10 ? '0' + num : num);

  return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
};

  // API DATA //////////////////////////////////////
  const sessionTimer = SessionTimer();
  const [tab, setTab] = useState(1); 
  const [tradeDetail, setTradeDetail] = useState([]);
  const [userDetail, setUserDetail] = useState([]);
  const [kycDetail, setKycDetail] = useState([]);
  const [cryptoUser, setCryptoUser] = useState([]); 
  const [accountStatement, setAccountStatement] = useState(null); 

  const [pagePermission, setPagePermission] = useState([]); 
  const pagePermissionMethod = () => { 
    API.get("/staff/account/role/allow-pages").then((res) => {
      setPagePermission(res.data.data); 
    }).catch((err) => { 
    }).finally(() => {
    });  
  }

  const tradeProfileMethod = () => {
    API.get("/staff/market/profile/get", {
      params: { userId: userId }
    }).then((res) => {
      setTradeDetail(res.data.data.tradeDetails); 
      setUserDetail(res.data.data.user); 
      setKycDetail(res.data.data.user.kyc); 
      setCryptoUser(res.data.data.cryptoUserBeneficiary); 
      setAccountStatement(res.data.data.cryptoUserBeneficiary.accountStatement); 
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }); 
  }

  useEffect(() => {
    tradeProfileMethod();
  }, []); 

  useEffect(() => {
    pagePermissionMethod(); 
  }, []); 

  return (
 <>
  <section id="sidebar" className={sidebarHidden ? 'hide' : ''}> 
    <a href="#" className="brand">
      <img src={profilepng} alt="Profile Image" className="profile-image" />
      Support
    </a> 

    <ul className="side-menu">
      {pagePermission.some(el => el === 'dashboard') && 
      <li>
        <a href='#' className="active" onClick={()=>{navigate('/dashboard')}} >
          <i className="fas fa-tachometer-alt icon"></i> Dashboard
        </a>
      </li>}

      <li className="divider" data-text="main">
        Main
      </li>

       {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Manage Request----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'ManageRequests') && 
        <a href="#" >
          <i className="fas fa-folder icon fa-fw"></i> Manage Requests
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverification')}}>
              <i className="fas fa-id-card"></i> KYC Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderverification')}}>
              <i className="fas fa-briefcase"></i> Trader Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'recharge') && 
          <li>
            <a href="#" onClick={()=>{navigate('/recharge')}}>
              <i className="fas fa-phone"></i> Recharge Request
            </a> 
          </li>}

          {pagePermission.some(el => el === 'c2c') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2c')}}>
              <i className="fas fa-exchange-alt"></i> C2C Request
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputesattlement') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputesattlement')}}> 
              <i className="fas fa-ban"></i> Dispute Settlement
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'investotp') &&
      <li>
        <a href='#' onClick={()=>{navigate('/investotp')}}>
          <i className="fas fa-fingerprint icon fa-fw"></i>Invest Via OTP
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {/* --@@@@@@@@@@@@@@@@@@@@@@@@---View Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

     
      <li>
        {pagePermission.some(el => el === 'ViewReports') && 
        <a href="#" >
          <i className="fas fa-file-alt icon fa-fw"></i> View Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverifyedreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverifyedreport')}}>
              <i className="fas fa-id-card"></i> KYC Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderreport')}}>
              <i className="fas fa-briefcase"></i> Trader Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'rechargereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/rechargereport')}}>
              <i className="fas fa-donate"></i> Recharge Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'c2creport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2creport')}}>
              <i className="fas fa-money-check-alt"></i> C2C Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputereport')}}>
              <i className="fas fa-handshake"></i> Dispute Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'utrreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/utrreport')}}>
              <i className="fas fa-money-check"></i> UTR Status Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'purchaseReport') && 
          <li>
            <a href="#"onClick={()=>{navigate('/purchaseReport')}}>
              <i className="fas fa-gift"></i> Purchase Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'partnerreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/partnerreport')}}>
              <i className="fas fa-handshake"></i> Partner Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'ViewReports') && 
          <li>
            <a href="#" onClick={()=>{navigate('/noninvestedreport')}}>
              <i className="fas fa-file-alt"></i> Non Invested Report
            </a>
          </li>}

        </ul>
      </li>


    {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Send Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'SendReports') && 
        <a href="#" >
          <i className="fas fa-paper-plane icon fa-fw"></i> Send Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'sendecharge') && 
          <li>
            <a href='#' onClick={()=>{navigate('/sendecharge')}}>
              <i className="fas fa-file-alt"></i> Coin2Cash
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'memberManage') &&
      <li>
        <a href="#" onClick={()=>{nav('/memberManage')}} >
          <i className="fas fa-users icon fa-fw"></i> Manage Members
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'blockuser') &&
      <li>
        <a href="#" onClick={()=>{nav('/blockuser')}} >
          <i className="fas fa-user-circle icon fa-fw"></i> Block User
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'enquirymanage') &&
      <li>
        <a href="#" onClick={()=>{navigate('/enquirymanage')}}>
          <i className="fas fa-briefcase icon fa-fw"></i> Manage Enquiry
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'update') &&
      <li>
        <a href="#" onClick={()=>{navigate('/update')}}>
          <i className="fas fa-tools icon fa-fw"></i> Update Manager
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}
      
      {pagePermission.some(el => el === 'ChatSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/ChatSupport')}}>
          <i className="fas fa-comment-alt icon fa-fw"></i>Chat Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'TicketSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/TicketSupport')}}>
          <i className="fas fa-chalkboard-teacher icon fa-fw"></i>Ticket Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'feedback') &&
      <li>
        <a href='#' onClick={()=>{navigate('/feedback')}}> 
          <i className="fas fa-comment-medical icon fa-fw"></i>Feedbacks
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      <li className="divider" data-text="Exit">
        Exit
      </li>

      <div className="ads">
        <button className="Btn" onClick={()=>{navigate('/loginform')}}>
          <div className="sign">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text">Logout</div>
        </button>
      </div>
    </ul> 
  </section> 


{/* MAIN CONTENT */}
  <section id="content">
    <nav>
      <i className="bx bx-menu toggle-sidebar" onClick={handleToggleSidebar}></i>
      <form action="#">
        <div className="form-group">
          <span>Session Time: {formatTime(sessionTimer)}</span>
        </div>
      </form>
       
      <div className='notify'>
       <i className="fas fa-bell"></i>
     </div>


      <span className="divider"></span>
{/* --@@@@@@@@@@@@@@@@@@@@@@@@---Profile----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 
      <div className="profile">
        <img
          src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-File.png"
          onClick={handleProfileClick}
          alt="Profile"
        />
        <ul className="profile-link">
          <li>
            <a href="" onClick={()=>{navigate('/selfprofile')}}>
              <i className="bx bxs-user-circle icon"></i> Profile
            </a>
          </li>
 {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Logout----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 

          <li>
            <a href="#" onClick={()=>{navigate('/loginform')}}>
              <i className="bx bxs-log-out-circle"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>

        <main>
          {/* {props.passedComponent} */}
  
   {/* <!-- @@@@@@@@@@@@@@@@@@____________TOP HEADER/BACK-Btn/Title______________@@@@@@@@@@@@@@@@@@ --> */}

<div className="Trade-Profile-title-head" >
    <a href="#"  onClick={()=>{navigate('/disputesattlement')}}> 
    <i class="fa-solid fa-circle-arrow-left"></i>Back
    </a>
   <h1>Trade Profile</h1>
</div>




{/* <!-- @@@@@@@@@@@@@@@@@@____________TOP Container/profile/Menu______________@@@@@@@@@@@@@@@@@@ --> */}
<div className="Trade-Profile-TOP-container">
  

{/* <!-- ___________Top Left Profile Trade-Profile-container-1________________ --> */}

    <div className="Trade-Profile-nav-section-1">
        
        <div className="Trade-Profile-img-section">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3721428.7645003865!2d87.85113775!3d24.37407435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1701276678607!5m2!1sen!2sin" width="148" height="150" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          <img src={kycDetail.userSelfie} alt="profile"/>   
        </div>

        <div className="Trade-Profile-title-name">  
            <div className="Trade-Profile-title-name-1">
                <label for="name"> <strong>Name:</strong></label>
                <label for="name">{userDetail.name}</label>
            </div>

            <div className="Trade-Profile-title-name-1">
                <label for=""><strong>Member ID:</strong></label>
                <label for="id">{userDetail.referralCode}</label>
            </div>
        </div>
    </div>

{/* <!-- ___________Top Right Menu Trade-Profile-container-2________________ --> */}

    <div className="Trade-Profile-nav-section-2">
        <div className="Trade-Profile-nav-Heading">Trade Details</div>
        <div className="Trade_sec">
            
            <div className="Trade-Profile-content">
                <a href="#">Like <img src={Like} alt="#"/>{tradeDetail.goodCount}</a>
             </div>
             <div className="Trade-Profile-content">
                 <a href="#">Dislike <img src={Dislike} alt="#"/>{tradeDetail.badCount}</a>
             </div>
             <div className="Trade-Profile-content">
                 <a href="#">Disputes <img src={Disputes} alt="#"/>{tradeDetail.disputeCount}</a>
             </div>
             <div className="Trade-Profile-content">
                 <a href="#">Buys <img src={Buys} alt="#"/>{tradeDetail.buyCount}</a>
             </div>
             <div className="Trade-Profile-content">
                 <a href="#">Sell <img src={Sell } alt="#"/>{tradeDetail.sellCount}</a>
             </div>
             <div className="Trade-Profile-content">
                 <a href="#">Exchange <img src={Exchange} alt="#"/>{tradeDetail.exchangeCount}</a>
             </div>
             <div className="Trade-Profile-content">
                 <a href="#">Holding <img src={Holding} alt="#"/>{tradeDetail.holdingCount}</a>
             </div>
        </div>   

    </div>

</div>


{/* <!-- @@@@@@@@@@@@@@@@@@____________ Main Container______________@@@@@@@@@@@@@@@@@@ --> */}

 <div className="Trade-Profile-main-container">

{/* <!-- ___________TAB Left Menu container________________ --> */}
    <div className="Trade-Profile-container-1">
        
        <div className="Trade-Profile-info-content">
            <a href="#" onClick={()=> setTab(1)}>Personal Details</a>
            <a href="#" onClick={()=> setTab(2)}>Documents</a>
            <a href="#" onClick={()=> setTab(3)}>Activity</a>
            <a href="#" onClick={()=> setTab(4)}>TDS Reports</a>
            {/* <a href="#" onClick={()=> setTab(5)}>VDA Reports</a>  */}
        </div>

    </div>
    
{/* <!-- /*___________RIGHT Box________________*/}
<div className="Trade-Profile-container-2">

  {tab === 1 && <Personal
    user={userDetail}
    kyc={kycDetail}
  />}

  {tab === 2 && <Tradedocuments 
    user={userDetail}
    kyc={kycDetail}
    userId={userId}
    accountStatement={accountStatement}
  />}

  {tab === 3 && <ActivityProfile 
    userId = {userId}
  />}

  {tab === 4 && <TdsProfile 
    userId = {userId}
  />}

</div>


</div>     
  </main>
  </section>

  {timeoutPop && <TimeoutPop 
    setTimeoutPop={setTimeoutPop}
  />}

 </>
  );
};

export default Tradeprofile;
