import './Pagination.css'; 
import React, { useState } from 'react'; 
import ReactPaginate from 'react-paginate';

const Pagination = ({ pageCount, onPageChange }) => {
  return (
    <div>
        <ReactPaginate 
        previousLabel="<< "
        nextLabel=" >>"
        pageCount={pageCount}
        breakLabel={'...'}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={onPageChange}
        containerClassName={`pagination`} 
        pageClassName={`page-item`}
        previousClassName={`page-item`}
        nextClassName={`page-item`}
        breakClassName={`page-item`}
        activeClassName={`page-active`} 
        />
    </div>
  )
}

export default Pagination;