import React from 'react';
import { Outlet } from 'react-router-dom';
// import { Navigate, Outlet } from 'react-router-dom';
// import { tokenLoder } from '../util/Auth'; 

const PrivateRoute = () => {
    // return tokenLoder() ? <Outlet /> : <Navigate to={'/login'} />
    // return tokenLoder() ? <Outlet /> : <Navigate to={'/'} />
    return <Outlet />;

    // const elementRef = useRef(null);
    // elementRef.current.requestFullscreen();
    // if (elementRef.current.requestFullscreen) {
    //     elementRef.current.requestFullscreen();
    //   } else if (elementRef.current.mozRequestFullScreen) { // Firefox
    //     elementRef.current.mozRequestFullScreen();
    //   } else if (elementRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
    //     elementRef.current.webkitRequestFullscreen();
    //   } else if (elementRef.current.msRequestFullscreen) { // IE/Edge
    //     elementRef.current.msRequestFullscreen();
    // }

    // useEffect(()=> {
    //   let elementFl = document.getElementById("fsrc");
    //   elementFl.requestFullscreen();
    // }, []);

    // useEffect(() => {
    //     if (screenfull.isEnabled) {
    //     screenfull.request();
    //     }
    // }, []);
}

export default PrivateRoute; 