import React from 'react';
import './LoadingSpinner.css'; 
import { Circles } from 'react-loader-spinner';  

const LoadingSpinner = () => {
  return (
    <div className='loading-sp'> 
        <Circles
            height="50"
            width="50"
            color="#2563EB"
            ariaLabel="Loading"
        />
    </div>
  )
}

export default LoadingSpinner;