// Import necessary libraries and styles
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../../Assets/All-Css/Member/Users_info.css'

import profileimg from '../../../Assets/Member-img/Ellipse 60.svg'
import networkimg from '../../../Assets/Member-img/3 201 (1).png'
import Transactionimg from '../../../Assets/Member-img/Transaction.png'
import MyTeam from '../../../Assets/Member-img/3 201 (1).png'
import PurchaseRecords from '../../../Assets/Member-img/9 1.png'
import FundHistory from '../../../Assets/Member-img/Money bag (1).png'
import Partners from '../../../Assets/Member-img/Vector (1).svg'
import MarketReport from '../../../Assets/Member-img/Trend (1).png'
import Holdings from '../../../Assets/Member-img//Hold.png'
import OpenOrders from '../../../Assets/Member-img/Clock.png'
import TradeReport from '../../../Assets/Member-img/Checklist.png'
import svtcn from '../../../Assets/Member-img/svtcn.png'
import stakeImg from '../../../Assets/Member-img/stakeImg.png';
import spotIcon from '../../../Assets/Member-img/spotIcon.png';

import '../../../Assets/style.css';
import profilepng from '../../../Assets/Login-img/Component 1.png';
import { useEffect } from 'react';

import API from '../../../api/Api';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import { SessionTimer } from '../../../util/UtilMethod';
import IncomeLockerPop from './IncomeLockerPop';
import { getAuthToken, getTokenDuration } from '../../../util/Auth';
import TimeoutPop from '../../../Componets/TimeoutPop/TimeoutPop';
import screenfull from 'screenfull';

// Define the React component
const Userinfo = () => { 
    const navigate = useNavigate(); 

  // TOKEN EXPIRATION  
  const [timeoutPop, setTimeoutPop] = useState(false);  
  const token = getAuthToken();
  useEffect(() => {
    if(!token) { 
      navigate('/loginform');
      return; 
    }
    if (token === 'EXPIRED') {
      navigate('/loginform');
      return; 
    }
    const tokenDuration = getTokenDuration(); 
    setTimeout(() => {
      navigate('/loginform'); 
    }, tokenDuration);     // 1*60*60*1000   
  }, [token]);  

  useEffect(() => {
    const tokenDuration = getTokenDuration();  
    const timer = setTimeout(() => {
      setTimeoutPop(true);
    }, (tokenDuration - 15*60*1000));
    return () => clearTimeout(timer);
  }, []);
  // TOKEN EXPIRATION END

    const location = useLocation(); 
    const [userReference, setUserRefrence] = useState(location.state?.searchId);    
    const [userId, setUserId] = useState(location.state?.userId); 

    // State to track whether the text is blocked or not 
    const [isBlocked, setIsBlocked] = useState(false);

 // Function to toggle the block state
 const toggleBlock = () => {
   setIsBlocked(!isBlocked);
 };


 const [sidebarHidden, setSidebarHidden] = useState(false);
 const [allDropdown, setAllDropdown] = useState([]);

 // State to manage session time
 const [sessionTime, setSessionTime] = useState(0);

 const nav = useNavigate ();

 useEffect(() => {
   setAllDropdown(document.querySelectorAll('#sidebar .side-dropdown'));
 }, []);



 useEffect(() => {
   // Attach event listeners for dropdown elements
   allDropdown.forEach((dropdown) => {
     const parentLink = dropdown.closest('li');
     parentLink.addEventListener('click', (e) => handleMenuClick(e, dropdown));
   });

   // Set an interval to update the session time every second
   const timerInterval = setInterval(() => {
     setSessionTime((prevTime) => prevTime + 1);
   }, 1000);

   // Clean up the interval on component unmount
   return () => {
     clearInterval(timerInterval);

     // Remove event listeners
     allDropdown.forEach((dropdown) => {
       const parentLink = dropdown.closest('li');
       parentLink.removeEventListener('click', (e) => handleMenuClick(e, dropdown));
     });
   };
 }, [allDropdown]);

 const handleDropdownClick = (e, dropdown) => {
   e.preventDefault();

   const isDropdownActive = e.currentTarget.classList.contains('active');

   // Close all dropdowns
   allDropdown.forEach((item) => {
     const aLink = item.parentElement.querySelector('a:first-child');
     aLink.classList.remove('active');
     item.classList.remove('show');
   });

   // Toggle the clicked dropdown
   e.currentTarget.classList.toggle('active', !isDropdownActive);
   dropdown.classList.toggle('show', !isDropdownActive);
 };

 const handleToggleSidebar = () => {
   setSidebarHidden((prevSidebarHidden) => !prevSidebarHidden);
 };

 const handleProfileClick = () => {
   const profileLink = document.querySelector('.profile-link');
   profileLink.classList.toggle('show');
 };

 const handleMenuClick = (e, dropdown) => {
   e.preventDefault();
   dropdown.classList.toggle('show');
 };

 // Convert seconds to HH:MM:SS format
 const formatTime = (seconds) => {
   const hours = Math.floor(seconds / 3600);
   const minutes = Math.floor((seconds % 3600) / 60);
   const remainingSeconds = seconds % 60;

   const pad = (num) => (num < 10 ? '0' + num : num);

   return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
 };


  // API DATA //////////////////////////////////////
  const sessionTimer = SessionTimer();
  const [lockerPop, setLockerPop] = useState(false);
  const [userDetail, setUserDetail] = useState([]);
  const [assets, setAssets] = useState([]);
  const [liquidity, setLiquidity] = useState([]);
  const [liability, setLiability] = useState([]);
  const [accountStatus, setAccountStatus] = useState([]); 
  const [blockDetail, setBlockDetail] = useState([]); 

  const [showUserBlockPopup, setShowUserBlockPopup] = useState(false); 
  const [showUserUnBlockPopup, setShowUserUnBlockPopup] = useState(false); 
  const [showRemarkPopup, setShowRemarkPopup] = useState(false)

  const [blockDeletedStatus, setBlockDeletedStatus] = useState(false);
  const [blockCauseStatus, setBlockCauseStatus] = useState(null); 
  const [blockCheckName, setBlockCheckName] = useState(null);
  const [blockRemark, setBlockRemark] = useState('');
  
  const [pagePermission, setPagePermission] = useState([]);   
  const pagePermissionMethod = () => { 
    API.get("/staff/account/role/allow-pages").then((res) => {
      setPagePermission(res.data.data); 
    }).catch((err) => { 
    }).finally(() => {
    });  
  }

  const userDetailMethod = () => {
    API.get("/staff/user/get", {
      params: { search: userReference }
    }).then((res) => {
      setUserDetail(res.data.data); 
      setBlockDeletedStatus(res.data.data.isDeleted ? res.data.data.isDeleted : false);
      setBlockCauseStatus(res.data.data.blockCause ? res.data.data.blockCause : null);
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }); 
  }

  // const traderDetailMethod = () => {
  //   API.get("/staff/market/trader/verification-request/get", {
  //     params: { userId: userId }
  //   }).then((res) => {
  //     setBlockTraderStatus(res.data.data.status);
  //   }).catch((err) => {
  //   }); 
  // }

  const listAssetsMethod = () => {
    API.get("/staff/crypto/financial-statement", {
      params: { referralCode: userReference }
    }).then((res) => {
      setAssets(res.data.data.assets); 
      setLiquidity(res.data.data.liquidity);
      setLiability(res.data.data.liability);
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }); 
  }

  const accountStatusMethod = () => {
    API.get("/staff/user/account/status-report", {
      params: { search: userReference }
    }).then((res) => {
      setAccountStatus(res.data.data); 
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }); 
  }

  // const userUnblockMethod = () => {
  //   API.put("/staff/user/restore/temporary", { search: userReference }).then((res) => {
  //     userDetailMethod();
  //     toast.success("User Unblocked successfully.", {position:"top-center"});
  //   }).catch((err) => {
  //     toast.error(err.response.data.message, {position:"top-center"}); 
  //   }); 
  // }

  // const userBlockMethod = () => {
  //   API.put("/staff/user/delete/temporary", { search: userReference }).then((res) => {
  //     userDetailMethod();
  //     toast.success("User Blocked successfully.", {position:"top-center"});
  //   }).catch((err) => {
  //     toast.error(err.response.data.message, {position:"top-center"}); 
  //   }); 
  // }


  // MAIN USER BLOCK-UNBLOCK METHOD //////////////////////
  const kycTraderUserBlockMethod = (body) => {
    API.put("/staff/user/delete/temporary", body).then((res) => {
      userDetailMethod();
      toast.success(res.data.message, {position:"top-center"});
    }).catch((err) => {
      toast.error(err.response.data.message, {position:"top-center"}); 
    });
  }

  const kycTraderUserUnBlockMethod = (body) => {
    API.put("/staff/user/restore/temporary", { id: userId }).then((res) => {
      userDetailMethod();
      toast.success(res.data.message, {position:"top-center"});
    }).catch((err) => {
      toast.error(err.response.data.message, {position:"top-center"}); 
    });
  }


  const finalUserBlockMethod = () => {
    if(!blockCheckName) {
      toast.warning("Please choose block user type.", {position:"top-center"});
      return;
    }

    if(blockRemark === '') {
      if(blockCheckName === 'Kyc') {
        const body1 = {
          id: userId,
          blockCause: 'KYC'
        }
        kycTraderUserBlockMethod(body1);
      }

      if(blockCheckName === 'Trader') {
        const body2 = {
          id: userId,
          blockCause: 'TRADER-PROFILE'
        }
        kycTraderUserBlockMethod(body2);
      }

      if(blockCheckName === 'Remark') {
        toast.warning("Please provide reason for block.", {position:"top-center"});
        return;
      }
    
    }else {
      const body3 = {
        id: userId,
        blockCause: 'CUSTOM',
        blockReason: blockRemark
      }
      kycTraderUserBlockMethod(body3);
    }

    setBlockCheckName(null); 
    setBlockRemark(''); 
    setShowUserBlockPopup(false);
    setShowRemarkPopup(false);
  }

  const finalUserUnBlockMethod = () => {
    if(!blockCheckName) {
      toast.warning("Please choose block user type.", {position:"top-center"});
      return;
    }

    kycTraderUserUnBlockMethod();    
    setBlockCheckName(null); 
    setShowUserUnBlockPopup(false);
  }

  // BLOCK UNBLOCK INCOME LOCKER
  const blockDetailMethod = () => {
    API.get("/staff/user/permissions", {
      params: { search: userReference }
    }).then((res) => {
      setBlockDetail(res.data.data.totalIncome); 
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }); 
  }

  const blockSeniorMethod = (seniorReferral) => {
    const body = {
      referralCode: userReference,
      seniorReferralCode: seniorReferral
    }
    API.post("/staff/wallet/income/access/block/senior", body).then((res) => {
      blockDetailMethod();
      toast.success(res.data.message, {position:"top-center"});
    }).catch((err) => {
      toast.error(err.response.data.message, {position:"top-center"}); 
    });
    setLockerPop(false); 
  }

  const blockAdminMethod = (contactInfo) => {
    const body = {
      referralCode: userReference,
      contactInfo: contactInfo
    }
    API.post("/staff/wallet/income/access/block/admin", body).then((res) => {
      blockDetailMethod();
      toast.success(res.data.message, {position:"top-center"});
    }).catch((err) => {
      toast.error(err.response.data.message, {position:"top-center"}); 
    });
    setLockerPop(false); 
  }

  const onUnblockSeniorAdminMethod = () => {
    const body = {
      referralCode: userReference,
    }
    API.post("/staff/wallet/income/access/unblock", body).then((res) => {
      blockDetailMethod();
      toast.success(res.data.message, {position:"top-center"});
    }).catch((err) => {
      toast.error(err.response.data.message, {position:"top-center"}); 
    });
    setLockerPop(false); 
  }

  useEffect(() => {
    pagePermissionMethod(); 
  }, []); 

  useEffect(() => {
    userDetailMethod();
    listAssetsMethod();
    accountStatusMethod();
    blockDetailMethod(); 
  }, []); 


// useEffect(()=> {
//   let elementFl = document.getElementById("fsrc");
//   elementFl.requestFullscreen();
// }, []);

useEffect(() => {
  if (screenfull.isEnabled) {
    screenfull.request();
  }
}, []);


return(
    <>
  <section id="sidebar" className={sidebarHidden ? 'hide' : ''}> 
    <a href="#" className="brand">
      <img src={profilepng} alt="Profile Image" className="profile-image" />
      Support
    </a> 

    <ul className="side-menu">
      {pagePermission.some(el => el === 'dashboard') && 
      <li>
        <a href='#' className="active" onClick={()=>{navigate('/dashboard')}} >
          <i className="fas fa-tachometer-alt icon"></i> Dashboard
        </a>
      </li>}

      <li className="divider" data-text="main">
        Main
      </li>

       {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Manage Request----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'ManageRequests') && 
        <a href="#" >
          <i className="fas fa-folder icon fa-fw"></i> Manage Requests
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverification')}}>
              <i className="fas fa-id-card"></i> KYC Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderverification')}}>
              <i className="fas fa-briefcase"></i> Trader Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'recharge') && 
          <li>
            <a href="#" onClick={()=>{navigate('/recharge')}}>
              <i className="fas fa-phone"></i> Recharge Request
            </a> 
          </li>}

          {pagePermission.some(el => el === 'c2c') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2c')}}>
              <i className="fas fa-exchange-alt"></i> C2C Request
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputesattlement') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputesattlement')}}> 
              <i className="fas fa-ban"></i> Dispute Settlement
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'investotp') &&
      <li>
        <a href='#' onClick={()=>{navigate('/investotp')}}>
          <i className="fas fa-fingerprint icon fa-fw"></i>Invest Via OTP
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {/* --@@@@@@@@@@@@@@@@@@@@@@@@---View Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

     
      <li>
        {pagePermission.some(el => el === 'ViewReports') && 
        <a href="#" >
          <i className="fas fa-file-alt icon fa-fw"></i> View Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverifyedreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverifyedreport')}}>
              <i className="fas fa-id-card"></i> KYC Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderreport')}}>
              <i className="fas fa-briefcase"></i> Trader Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'rechargereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/rechargereport')}}>
              <i className="fas fa-donate"></i> Recharge Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'c2creport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2creport')}}>
              <i className="fas fa-money-check-alt"></i> C2C Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputereport')}}>
              <i className="fas fa-handshake"></i> Dispute Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'utrreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/utrreport')}}>
              <i className="fas fa-money-check"></i> UTR Status Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'purchaseReport') && 
          <li>
            <a href="#"onClick={()=>{navigate('/purchaseReport')}}>
              <i className="fas fa-gift"></i> Purchase Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'partnerreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/partnerreport')}}>
              <i className="fas fa-handshake"></i> Partner Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'ViewReports') && 
          <li>
            <a href="#" onClick={()=>{navigate('/noninvestedreport')}}>
              <i className="fas fa-file-alt"></i> Non Invested Report
            </a>
          </li>}

        </ul>
      </li>


    {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Send Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'SendReports') && 
        <a href="#" >
          <i className="fas fa-paper-plane icon fa-fw"></i> Send Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'sendecharge') && 
          <li>
            <a href='#' onClick={()=>{navigate('/sendecharge')}}>
              <i className="fas fa-file-alt"></i> Coin2Cash
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'memberManage') &&
      <li>
        <a href="#" onClick={()=>{nav('/memberManage')}} >
          <i className="fas fa-users icon fa-fw"></i> Manage Members
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'blockuser') &&
      <li>
        <a href="#" onClick={()=>{nav('/blockuser')}} >
          <i className="fas fa-user-circle icon fa-fw"></i> Block User
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'enquirymanage') &&
      <li>
        <a href="#" onClick={()=>{navigate('/enquirymanage')}}>
          <i className="fas fa-briefcase icon fa-fw"></i> Manage Enquiry
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'update') &&
      <li>
        <a href="#" onClick={()=>{navigate('/update')}}>
          <i className="fas fa-tools icon fa-fw"></i> Update Manager
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}
      
      {pagePermission.some(el => el === 'ChatSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/ChatSupport')}}>
          <i className="fas fa-comment-alt icon fa-fw"></i>Chat Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'TicketSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/TicketSupport')}}>
          <i className="fas fa-chalkboard-teacher icon fa-fw"></i>Ticket Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'feedback') &&
      <li>
        <a href='#' onClick={()=>{navigate('/feedback')}}> 
          <i className="fas fa-comment-medical icon fa-fw"></i>Feedbacks
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      <li className="divider" data-text="Exit">
        Exit
      </li>

      <div className="ads">
        <button className="Btn" onClick={()=>{navigate('/loginform')}}>
          <div className="sign">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text">Logout</div>
        </button>
      </div>
    </ul> 
  </section> 


{/* MAIN CONTENT */}
  <section id="content">
    <nav>
      <i className="bx bx-menu toggle-sidebar" onClick={handleToggleSidebar}></i>
      <form action="#">
        <div className="form-group">
          <span>Session Time: {formatTime(sessionTimer)}</span>
        </div>
      </form>
       
      <div className='notify'>
       <i className="fas fa-bell"></i>
     </div>


      <span className="divider"></span>
{/* --@@@@@@@@@@@@@@@@@@@@@@@@---Profile----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 
      <div className="profile">
        <img
          src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-File.png"
          onClick={handleProfileClick}
          alt="Profile"
        />
        <ul className="profile-link">
          <li>
            <a href="#" onClick={()=>{navigate('/selfprofile')}}>
              <i className="bx bxs-user-circle icon"></i> Profile
            </a>
          </li>
 {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Logout----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 

          <li>
            <a href="#" onClick={()=>{navigate('/loginform')}}>
              <i className="bx bxs-log-out-circle"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>

        <main>
          {/* {props.passedComponent} */}
 
          <h1><i className="fa fa-user"></i> User Info</h1>

<div className="User-info-main-container">
  <div className="User-info-top-container">

  {pagePermission.some(el => el === 'userinfoBtnUserProfile') && 
   <a href="#" className="User-info-top-user-profile-link" 
    onClick={()=>{navigate('/userprofile', {state: {searchId: userReference, userId: userId}} )}}>
      <i className="fa fa-user-circle-o"></i> User Profile
    </a>}

    {/* LOCKER BUTTON */}
    {pagePermission.some(el => el === 'userinfoBtnIncomeLocker') && 
    <button className='inlocker-btn' onClick={()=> {setLockerPop(true);}}>
      <i className="fa fa-lock"></i> Total Income Locker
    </button>}

   {/* Button to toggle block state */}
      {/* {pagePermission.some(el => el === 'userinfoBtnBlock') && 
      <button type='button' onClick={userDetail.isDeleted ? userUnblockMethod : userBlockMethod}
        style={{ backgroundColor: userDetail.isDeleted ? '#e62626' : 'white', color: userDetail.isDeleted ? 'white' : 'rgb(0, 0, 0)',}}>{userDetail.isDeleted ? 'Unblock' : 'Block'}
      </button>} */}


      <div className='radio-div1' style={{marginTop: '25px'}}>
        {/* BLOCK KYC */}
        {(!blockDeletedStatus && pagePermission?.some(el => el === 'userinfoBtnBlockKyc'))&&
        <>
          <input type="radio" className='radio-inn' name='members' value='1' onClick={()=> {setShowUserBlockPopup(true); setBlockCheckName('Kyc');}}/>
          <label htmlFor="name" className='mem-title' style={{color:'red'}}>Block Kyc Verification</label>
        </>}
        {(blockDeletedStatus && blockCauseStatus === 'KYC' && pagePermission?.some(el => el === 'userinfoBtnBlockKyc')) &&
        <>
          <input type="radio" className='radio-inn' name='members' value='1' onClick={()=> {setShowUserUnBlockPopup(true); setBlockCheckName('Kyc');}}/>
          <label htmlFor="name" className='mem-title' style={{color:'green'}}>UnBlock Kyc Verification</label>
        </>}

        {/* BLOCK TRADER */}
        {(!blockDeletedStatus && pagePermission?.some(el => el === 'userinfoBtnBlockTrader')) &&
        <>
          <input type="radio" className='radio-inn'  name='members' value='2' onClick={()=> {setShowUserBlockPopup(true); setBlockCheckName('Trader');}}/>
          <label htmlFor="name" className='mem-title' style={{color:'red'}}>Block Trader Verification</label>
        </>}
        {(blockDeletedStatus && blockCauseStatus === 'TRADER-PROFILE' && pagePermission?.some(el => el === 'userinfoBtnBlockTrader')) &&
        <>
          <input type="radio" className='radio-inn'  name='members' value='2' onClick={()=> {setShowUserUnBlockPopup(true); setBlockCheckName('Trader');}}/>
          <label htmlFor="name" className='mem-title' style={{color:'green'}}>UnBlock Trader Verification</label>
        </>}

      </div>

      {/* BLOCK FOR REASON */}
      {(pagePermission?.some(el => el === 'userinfoBtnBlockReason') && !blockDeletedStatus) && 
      <div className='radio-div1' style={{marginTop: '0px'}}>
        <>
          <input type="radio" className='radio-inn'  name='members' value='2' onClick={()=> {setShowRemarkPopup(true); setBlockCheckName('Remark');}}/>
          <label htmlFor="name" className='mem-title' style={{color:'red'}}>Reason For Block</label>
        </>
      </div>}

      {(pagePermission?.some(el => el === 'userinfoBtnBlockReason') && blockCauseStatus === 'CUSTOM') && 
      <div className='radio-div1' style={{marginTop: '0px'}}>
        <>
          <input type="radio" className='radio-inn'  name='members' value='2' onClick={()=> {setShowUserUnBlockPopup(true); setBlockCheckName('Kyc & Trader');}}/>
          <label htmlFor="name" className='mem-title' style={{color:'green'}}>UnBlock Kyc & Trader</label>
        </>
      </div>}

  </div>



<div className="User-info-middle-container">
<div className="User-info-img">
<img src={userDetail.kyc?.userSelfie} alt="profile pic" style={{width: '150px', height: '120px'}}/>
<h4>{userDetail.name}</h4>
<h5>{userDetail.referralCode}</h5>
</div>
<div className="User-info-side-box">
<h3><i className="fa fa-check"></i> {accountStatus.burnStatus}</h3>
<h3><i className="fa fa-remove "></i> {accountStatus.incomeAccessStatus}</h3>
</div>



</div>
<div className="User-info-middle-low-container">
<div className="User-info-box">

<div className="User-info-box-1">
<i className="fa fa-inr"></i>
<h2>{parseFloat(assets.inr).toFixed(2)}</h2>
<h5>Assest</h5>
</div>

<div className="User-info-box-1">
<i className="fa fa-inr"></i>
<h2>{parseFloat(liability.inr).toFixed(2)}</h2>
<h5>Liability</h5>
</div>


<div className="User-info-box-1">
<i className="fa fa-inr"></i>
<h2>{parseFloat(liquidity.inr).toFixed(2)}</h2>
<h5>Liquidity</h5>
</div>

<div className="User-info-box-2">

<h2>{userDetail.teamCount}</h2>
<h5>Team Count</h5>
</div>
<div className="User-info-box-2">

<h2>{parseFloat(userDetail.investedBalance).toFixed(2)}</h2>
<h5>My Investment</h5>
</div>
<div className="User-info-box-2">

<h2>{parseFloat(userDetail.stockBalance).toFixed(2)}</h2>
<h5>My Stock</h5>
</div>
</div>
</div>


<div className="User-info-middle-low-bottom">
{pagePermission.some(el => el === 'network') &&
<div className="User-info-img-1">
    <a href="#" onClick={()=> { navigate('/network', {state: {searchId: userReference, userId: userId}} )}}>
        <img src={networkimg} alt="image"/>
        <h3>Network</h3>
    </a>
</div>}

{pagePermission.some(el => el === 'transaction') &&
<div className="User-info-img-1">
    <a href="#" onClick={()=>{navigate('/transaction', {state: {searchId: userReference, userId: userId}} )}}>
        <img src={Transactionimg} alt="image"/>
        <h3>Transaction</h3>
    </a>
</div>}

{pagePermission.some(el => el === 'myteam') &&
<div className="User-info-img-1">
    <a href="#" onClick={()=>{navigate('/myteam', {state: {searchId: userReference}} )}}>
    <img src={MyTeam} alt="image"/>
        <h3>My Team</h3>
    </a>
</div>}

{pagePermission.some(el => el === 'purchaserec') &&
<div className="User-info-img-1">
    <a href="#" onClick={()=>{navigate('/purchaserec', {state: {searchId: userReference}} )}}>
        <img src={PurchaseRecords} alt="image"/>
        <h3>Purchase Records</h3>
    </a>
</div>}

{pagePermission.some(el => el === 'Foundhistory') &&
<div className="User-info-img-1">
    <a href="#" onClick={()=>{navigate('/Foundhistory', {state: {searchId: userReference}} )}}>
        <img src={FundHistory} alt="image"/>
        <h3>Add Fund History</h3>
    </a>
</div>}

{pagePermission.some(el => el === 'partners') &&
<div className="User-info-img-1">
    <a href="#" onClick={()=>{navigate('/partners', {state: {searchId: userReference}} )}}>
        <img src={Partners} alt="image"/>
        <h3>Partners</h3>
    </a>
</div>}

</div>

<div className="User-info-last-container">
{pagePermission.some(el => el === 'marketreport') &&
<div className="User-info-img-2">
    <a href="#" onClick={()=>{navigate('/marketreport', {state: {searchId: userReference, userId: userId}} )}}>
        <img src={MarketReport} alt="image"/> 
        <h3>Market Report</h3>
    </a>
 </div>}

 {pagePermission.some(el => el === 'holdingeport') &&
 <div className="User-info-img-2">
    <a href="#" onClick={()=>{navigate('/holdingeport', {state: {searchId: userReference, userId: userId}} )}}>
        <img src={Holdings} alt="image"/> 
        <h3>Holdings</h3>
    </a>
 </div>}

 {pagePermission.some(el => el === 'openorder') &&
 <div className="User-info-img-2">
    <a href="#" onClick={()=>{navigate('/openorder', {state: {searchId: userReference, userId: userId}} )}}>
        <img src={OpenOrders} alt="image"/> 
        <h3>Open Orders</h3>
    </a>
 </div>}

 {pagePermission.some(el => el === 'tradereport') &&
 <div className="User-info-img-2">
    <a href="#" onClick={()=>{navigate('/tradereport', {state: {searchId: userReference, userId: userId}} )}}>
        <img src={TradeReport} alt="image"/> 
        <h3>Trade Report</h3>
    </a>
 </div>}

 {pagePermission.some(el => el === 'SvtcnHistory') &&
 <div className="User-info-img-2">
    <a href="#" onClick={()=>{navigate('/SvtcnHistory', {state: {searchId: userReference, userId: userId}} )}}>
        <img src={svtcn} alt="image"/> 
        <h3>SVTCN History</h3>
    </a>
 </div>}

 {pagePermission.some(el => el === 'stakereport') &&
 <div className="User-info-img-2">
    <a href="#" onClick={()=>{navigate('/stakereport', {state: {searchId: userReference, userId: userId}} )}}>
        <img src={stakeImg} alt="image"/> 
        <h3>Stake Report</h3>
    </a> 
 </div>}

 {pagePermission.some(el => el === 'spotorder') &&
 <div className="User-info-img-2">
    <a href="#" onClick={()=>{navigate('/spotorder', {state: {searchId: userReference, userId: userId}} )}}>
        <img src={spotIcon} alt="image"/> 
        <h3>Spot Order History</h3>
    </a> 
 </div>}

</div>

</div>       
</main>
</section>

  {lockerPop && <IncomeLockerPop 
    setLockerPop = {setLockerPop}
    blockDetail = {blockDetail}
    onBlockSenior = {blockSeniorMethod} 
    onBlockAdmin = {blockAdminMethod} 
    onUnblock = {onUnblockSeniorAdminMethod} 
  />}

    {timeoutPop && <TimeoutPop
       setTimeoutPop={setTimeoutPop}
     />}


    {/*--@@@@@@@@@@@@@@ BLOCK USER POPUP @@@@@@@@@--*/}
    {showUserBlockPopup && (
    <>
      <div className="Update-overlay"></div>
      <div className="Update-popup">
        <div className="Update-popup-content">
          <div className='Update-poptop'>
            <h1>{blockCheckName ? `Block ${blockCheckName} Verification` : 'Block User'}</h1>
          </div>

            {/* <div className='radio-div1' style={{marginTop: '25px'}}>
              <input type="radio" className='radio-inn' name='members' value='1' onClick={()=> setBlockCheckName('KYC')}/>
              <label htmlFor="name" className='mem-title'>Kyc Verification</label>

              <input type="radio" className='radio-inn'  name='members' value='2' onClick={()=> setBlockCheckName('TRADER')}/>
              <label htmlFor="name" className='mem-title'>Trader Verification</label>
            </div> */}
        
            {/* <label htmlFor="additionalInput">Remark:</label>
            <textarea
              className="Update-rejection-reason-textarea"
              placeholder="Type Remark"
              name="remark"
              onChange={(e)=> setBlockRemark(e.target.value)}
            ></textarea> */}

            <div className='radio-div1' style={{marginTop: '25px'}}>
              <h2 style={{fontSize:'17px'}}>Are you sure you want to Block {blockCheckName ? blockCheckName : ''} Verification?</h2>
            </div>

            <button type='button' className="Update-close-btn" onClick={()=> {setBlockCheckName(null); setBlockRemark(''); setShowUserBlockPopup(false);}}>
              <i className="fas fa-times"></i>
            </button>
        </div>

          <button className="Update-done-btn" type='button' onClick={()=> finalUserBlockMethod()}>
            Block
          </button>
      </div>
    </>
  )}


  {/*--@@@@@@@@@@@@@@ UNBLOCK USER POPUP @@@@@@@@@--*/}
  {showUserUnBlockPopup && (
    <>
      <div className="Update-overlay"></div>
      <div className="Update-popup">
          <div className="Update-popup-content">
            <div className='Update-poptop'>
              <h1>Confirmation</h1>
            </div>

            <div className='radio-div1' style={{marginTop: '25px'}}>
              <h2 style={{fontSize:'17px'}}>Are you sure you want to UnBlock {blockCheckName ? blockCheckName : ''} Verification?</h2>
            </div>

            <button type='button' className="Update-close-btn" onClick={()=> {setBlockCheckName(null); setShowUserUnBlockPopup(false);}}>
              <i className="fas fa-times"></i>
            </button>
         </div>

          <button className="Update-done-btn" type='button' onClick={()=> finalUserUnBlockMethod()}>
            UnBlock
          </button>
      </div>
    </>
  )}


  {/* REMARK SEND POPUP ///////////////////////////// */}
  {showRemarkPopup && (
    <>
      <div className="Update-overlay"></div>
      <div className="Update-popup">
        <div className="Update-popup-content">
          <div className='Update-poptop'>
            {/* <h1>{blockCheckName ? `Block ${blockCheckName} Verification` : 'Block User'}</h1> */}
            <h1>Reason For Block</h1>
          </div>

            {/* <div className='radio-div1' style={{marginTop: '25px'}}>
              <input type="radio" className='radio-inn' name='members' value='1' onClick={()=> setBlockCheckName('KYC')}/>
              <label htmlFor="name" className='mem-title'>Kyc Verification</label>

              <input type="radio" className='radio-inn'  name='members' value='2' onClick={()=> setBlockCheckName('TRADER')}/>
              <label htmlFor="name" className='mem-title'>Trader Verification</label>
            </div> */}
        
            <label htmlFor="additionalInput">Remark:</label>
            <textarea
              className="Update-rejection-reason-textarea"
              placeholder="Type Remark"
              name="remark"
              onChange={(e)=> setBlockRemark(e.target.value)}
              style={{height:'250px'}}
            ></textarea>

            <button type='button' className="Update-close-btn" onClick={()=> {setBlockCheckName(null); setBlockRemark(''); setShowRemarkPopup(false);}}>
              <i className="fas fa-times"></i>
            </button>
        </div>

          <button className="Update-done-btn" type='button' onClick={()=> finalUserBlockMethod()}>
            Block
          </button>
      </div>
    </>
  )}




</>
)
};

export default Userinfo;
