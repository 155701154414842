import React, { useState } from 'react';
import './TxnUpdatePop.css'; 
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';

const TxnUpdatePop = ({setShowTxnUpdatePop, ticketStatus, onTicketVerify, onUpdateVerify}) => {
  const [ticketId, setTicketId] = useState('');
  const [txnId, setTxnId] = useState('');

  const verifySubmit = () => {
    if(ticketId === '') {
      toast.warning("Please enter ticket id.", {position:"top-center"}); 
    }else {
      onTicketVerify(ticketId);
    }
  }

  const updateSubmit = () => {
    if(setTxnId === '') {
      toast.warning("Please enter transaction id.", {position:"top-center"}); 
    }else {
      onUpdateVerify(ticketId, txnId);
      setShowTxnUpdatePop(false);
    }
  }


  return (
    <article className='TxnUpdatePop'>
        <h6 className='pop-title'>Transaction Update</h6>
        <span className='banner-cross' onClick={()=> {setShowTxnUpdatePop(false);}}><i class="fa fa-times"></i></span>

        <form className='form-main'>
            <div className="form-div">
                <h4>Ticket ID</h4>
                <input 
                  type="tel" 
                  className='pop-input-field' 
                  placeholder='Enter Ticket ID'
                  onChange={(e)=> {setTicketId(e.target.value)}}
                  readOnly={ticketStatus === true ? true : false}
                />  

                {ticketStatus === true && <span className='text-show-green'>Matched</span>}
                {ticketStatus === false && <span className='text-show-red'>Not Matched</span>}

                {ticketStatus === null && 
                  <button type='button' className='pop-btn-blue ver-blue1' onClick={()=> {verifySubmit()}}>Verify</button>
                }
                {ticketStatus === false && 
                  <button type='button' className='pop-btn-blue ver-blue2' onClick={()=> {verifySubmit()}}>Verify</button>
                }

                {ticketStatus === true && <button type='button' className='pop-btn-green ver-blue3' disabled>Verified</button>}
            </div>

            {ticketStatus === true && <div className="form-div">
                <h4>Transaction ID</h4>

                <input 
                  type="tel" 
                  className='pop-input-field' 
                  placeholder='Enter Transaction ID'
                  onChange={(e)=> {setTxnId(e.target.value)}}
                />

                {/* <span className='text-show-red'>Failed</span> */}
                <button type='button' className='pop-btn-blue ver-blue4' onClick={()=> {updateSubmit()}}>Update</button>
            </div>}
        </form>
    </article>
  )
}

export default TxnUpdatePop;