import React from 'react';
import '../Transfer/Transfer_records.css';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';
import moment from 'moment';

const Transferrecords = ({listTxnTransfer, loading, pagingCounter, setInnerTab}) => {
  return (
    <>
      <div className="transfer-table-content">      
             <div className="transfer-head-button">  
                 <button className="transfer-head-button-1 " onClick={()=> setInnerTab(1)}>Self Transfer</button>
                 <button className="transfer-head-button-2 " onClick={()=> setInnerTab(2)}>Transfer Records</button>
             </div>
     
             <div className="transfer-table-content-1"> 
                 <table>
                     <thead>
                         <tr>
                           <th>Sl.</th>
                            <th>Inv.Amt.</th>
                             <th>Type</th>
                             <th>S R Coin</th>
                             <th>Date</th>
                             <th>Time</th>
                            <th>Txn. ID</th>   
                         </tr>
                     </thead>

                     <tbody>
                        {loading && <LoadingSpinner />}
                        {listTxnTransfer.map((data, index) => {
                        return(
                         <tr key={index}>
                             <td>{index+pagingCounter}</td>  
                             <td>{parseFloat(data.amount).toFixed(2)}</td>
                             <td>{data.type}</td>
                             <td>{parseFloat(data.totalSelfRoi).toFixed(2)}</td>
                             <td>{moment(data.createdAt).format('DD-MM-YYYY')}</td>
                             <td>{moment(data.createdAt).format('hh:mm:ss')}</td>
                             <td>{data._id}</td>
                         </tr>
                        )})}   
                     </tbody>
                 </table>
             </div>
           </div>
    </>
  );
};

export default Transferrecords;
