import { io } from 'socket.io-client';
import { getAuthToken } from '../util/Auth';

// const accessToken = "";
const accessToken = getAuthToken(); 

// https://vtcncoin.com
// http://62.72.58.157:9001

const SocketIO = io("https://vtcncoin.com/staff", {
    extraHeaders: {
        "Authorization": accessToken,
        "Content-Type": "application/json;charset=utf-8", 
        "Accept": "application/json",
	    "Access-Control-Allow-Origin": '*',
    }
});

export default SocketIO; 