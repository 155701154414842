import React from 'react';
import '../Dispute-Trade/Dispute-Trade.css'; 
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Pagination from '../../Pagination/Pagination';
import moment from 'moment';

const DisputeTrade = ({listDispute, loading, pagingCounter}) => {
    return (
        <div className="Dispute-Trade-TABLE-Table-updated">
          <table className="Dispute-Trade-TABLE-Tpage-table">
            <thead>
              <tr>
                <th>Sl.No.</th>
                <th>Exchange Crypto</th>
                <th>Received</th> 
                <th>Benificiary</th>
                <th>Date</th>
                <th>Time</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {loading && <LoadingSpinner />}
              {listDispute.map((data, index) => {
              return(
              <tr key={index}>
                <td>{index+pagingCounter}</td>
                <td>{parseFloat(data.exchangeAmount).toFixed(2)} {data.exchangeSymbol}</td>
                <td>{parseFloat(data.receivedAmount).toFixed(2)} {data.receivedSymbol}</td>
                <td>{data.beneficiary.name}</td>
                <td>{moment(data.updatedAt).format('DD-MM-YYYY')}</td>
                <td>{moment(data.updatedAt).format('hh:mm:ss')}</td>
                <td>Solve</td>
              </tr>
              )})}

            </tbody>
          </table> 
        </div>
    );
  }


export default DisputeTrade;
