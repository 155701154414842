import React from 'react'; 
import './UserProfilePop.css'; 
import crossIcon from '../../Assets/Member-img/crossIcon.png'; 
import profileImg from '../../Assets/Member-img/profileImg.png'; 

const UserProfilePop = ({setShowUserProfilePop, setShowInvestCoinPop, singleTransaction}) => {
 
  function addFormSubmit(event) { 
    event.preventDefault(); 
    setShowUserProfilePop(false);
    setShowInvestCoinPop(true); 
  }

  return (
    <article className='UserProfilePop'>
        <h6 className='pop-title'>User Profile</h6>
        <img className='pop-cross' src={crossIcon} alt="Cross" onClick={()=>setShowUserProfilePop(false)} />
        <form onSubmit={(e) => addFormSubmit(e)}>
          <div className='profile-div'>
            <img src={singleTransaction.kyc?.userSelfie} alt="profile pic" /> 
            <h4>{singleTransaction?.name}</h4>
          </div>

          <div className='input-main'>
            <div className='input-div'>
              <label htmlFor="title">Member ID</label>
              <input type="tel" name="title" placeholder={singleTransaction?.referralCode} readOnly/>
            </div>

            <div className='input-div'>
              <label htmlFor="title">Email ID</label>
              <input type="email" name="title" placeholder={singleTransaction?.email} readOnly/>
            </div>
          </div>

          <div className='input-main'>
            <div className='input-div'>
              <label htmlFor="title">Current Balance</label>
              <input type="number" name="title" placeholder={(singleTransaction?.investedBalance).toFixed(2)} readOnly/>
            </div>

            <div className='input-div'>
              <label htmlFor="title">Mobile Number</label>
              <input type="tel" name="title" placeholder={singleTransaction?.mobile} readOnly/> 
            </div>
          </div>
          <span className='span-title'>VTCN</span>

          <div className='input-main'>
            <button type='submit' className='btn-cnf'>Confirm</button>
            <button type='button' className='btn-cncl' onClick={()=>setShowUserProfilePop(false)}>Cancel</button>
          </div>
        </form>
    </article>
  )
}

export default UserProfilePop; 