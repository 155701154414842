import React from 'react';
import '../Personal_Details/Personal_Deatils.css';

const Personal = ({user, kyc}) => {
  return (
   <>
      <div class="Tab-INFO-Container">
        
        
        <div class="docoment-info-1">
            
            <div class="doc-info">

                <div class="items">
                    <label for="name">Name</label>
                    <input type="name" value={user.name} readOnly/>
                </div>

                <div class="items">
                    <label for="number">Number</label>
                    <input type="number" value={kyc.alternateMobile} readOnly />
                </div>
                <div class="items">
                    <label for="number">Country</label>
                    <input type="text" value={user.country} readOnly />
                </div>


            </div> 

             <div class="doc-info">
             <div class="items">
              <label for="name">Address</label>
              <textarea rows="8" cols="80" disabled value={`${kyc.state}, ${kyc.pincode}, ${user.country}`}>
              </textarea>
            </div>

              
             </div>
              
               

        </div>

       
    </div>

 
   </>
  );
};

export default Personal;
