import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../Assets/All-Css/Member/Member_Manage.css';

import Rejectsub from '../../Assets/Login-img/Reject-submit.png'

import '../../Assets/style.css';
import profilepng from '../../Assets/Login-img/Component 1.png';
import { useEffect } from 'react';

import API from '../../api/Api';
import LoadingSpinner from '../../Componets/LoadingSpinner/LoadingSpinner';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import moment, { duration } from 'moment';
import Pagination from '../../Componets/Pagination/Pagination';  
import { SessionTimer } from '../../util/UtilMethod';
import { getAuthToken, getTokenDuration } from '../../util/Auth';
import TimeoutPop from '../../Componets/TimeoutPop/TimeoutPop';

const MemberManage = () => {
const navigate = useNavigate(); 

  // TOKEN EXPIRATION  
  const [timeoutPop, setTimeoutPop] = useState(false);       
  const token = getAuthToken();
  useEffect(() => {
    if(!token) { 
      navigate('/loginform');
      return; 
    }
    if (token === 'EXPIRED') {
      navigate('/loginform');
      return; 
    }
    const tokenDuration = getTokenDuration(); 
    setTimeout(() => {
      navigate('/loginform'); 
    }, tokenDuration);     // 1*60*60*1000   
  }, [token]);  

  useEffect(() => {
    const tokenDuration = getTokenDuration(); 
    const timer = setTimeout(() => {
      setTimeoutPop(true);
    }, (tokenDuration - (15*60*1000)));
    return () => clearTimeout(timer);
  }, []);
  // TOKEN EXPIRATION END
  
  const [isManualPopupOpen, setManualPopupOpen] = useState(false); 
  const [isAutoPopupOpen, setAutoPopupOpen] = useState(false);

  // popup buttons
  const [isAcceptPopupOpen, setAcceptPopupOpen] = useState(false);
  const [isAcceptConfirmPopupOpen, setAcceptConfirmPopupOpen] = useState(false); 

  const [isRejectPopupOpen, setRejectPopupOpen] = useState(false);
  const [isRejectSubmitPopupOpen, setRejectSubmitPopupOpen] = useState(false);

 

  // enable the button only when the checkbox is checked 
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);





 // manual- close
 const closeManualPopup = () => {
  setManualPopupOpen(false);
};

 // manual- close
 const closeAutoPopup = () => { 
    setAutoPopupOpen(false);
  };
  

  
  // Accept close
  const closeAcceptPopup = () => {
    setAcceptPopupOpen(false);
  };

    // Accept close
    const closeAcceptConfirmPopup = () => {
      setAcceptConfirmPopupOpen(false);
    };
  
  // Rejection close
  const closeRejectionPopup = () => {
    setRejectPopupOpen(false);
  };

    // Rejection close
    const closeRejectSubmitPopup = () => {
      setRejectSubmitPopupOpen(false);
    };



    const [sidebarHidden, setSidebarHidden] = useState(false);
    const [allDropdown, setAllDropdown] = useState([]);
  
    // State to manage session time
    const [sessionTime, setSessionTime] = useState(0);
  
    const nav = useNavigate ();
  
    useEffect(() => {
      setAllDropdown(document.querySelectorAll('#sidebar .side-dropdown'));
    }, []);
  
  
  
    useEffect(() => {
      // Attach event listeners for dropdown elements
      allDropdown.forEach((dropdown) => {
        const parentLink = dropdown.closest('li');
        parentLink.addEventListener('click', (e) => handleMenuClick(e, dropdown));
      });
  
      // Set an interval to update the session time every second
      const timerInterval = setInterval(() => {
        setSessionTime((prevTime) => prevTime + 1);
      }, 1000);
  
      // Clean up the interval on component unmount
      return () => {
        clearInterval(timerInterval);
  
        // Remove event listeners
        allDropdown.forEach((dropdown) => {
          const parentLink = dropdown.closest('li');
          parentLink.removeEventListener('click', (e) => handleMenuClick(e, dropdown));
        });
      };
    }, [allDropdown]);
  
    const handleDropdownClick = (e, dropdown) => {
      e.preventDefault();
  
      const isDropdownActive = e.currentTarget.classList.contains('active');
  
      // Close all dropdowns
      allDropdown.forEach((item) => {
        const aLink = item.parentElement.querySelector('a:first-child');
        aLink.classList.remove('active');
        item.classList.remove('show');
      });
  
      // Toggle the clicked dropdown
      e.currentTarget.classList.toggle('active', !isDropdownActive);
      dropdown.classList.toggle('show', !isDropdownActive);
    };
  
    const handleToggleSidebar = () => {
      setSidebarHidden((prevSidebarHidden) => !prevSidebarHidden);
    };
  
    const handleProfileClick = () => {
      const profileLink = document.querySelector('.profile-link');
      profileLink.classList.toggle('show');
    };
  
    const handleMenuClick = (e, dropdown) => {
      e.preventDefault();
      dropdown.classList.toggle('show');
    };
  
    // Convert seconds to HH:MM:SS format
    const formatTime = (seconds) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
  
      const pad = (num) => (num < 10 ? '0' + num : num); 
  
      return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
    };


    // API DATA //////////////////////////////////////
    const sessionTimer = SessionTimer();
    const [loading, setLoading] = useState(false);
    const [listMembers, setListMembers] = useState([]);
    const [listCountry, setListCountry] = useState([]);
    const [singleMember, setSingleMember] = useState([]);
    const [manualReferal, setManualReferal] = useState(null);
   
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10); 
    const [pagingCounter, setPagingCounter] = useState(1)
    const [search, setSearch] = useState(null);
    const [searchData, setSearchData] = useState(null); 

    const [pagePermission, setPagePermission] = useState([]); 
    const pagePermissionMethod = () => { 
      API.get("/staff/account/role/allow-pages").then((res) => {
        setPagePermission(res.data.data); 
      }).catch((err) => { 
      }).finally(() => {
      });  
    }

    const listMemberMethod = () => {
      setLoading(true);
      API.get("/staff/user/list", {
        params: {
          page: currentPage+1,
          limit: itemsPerPage,
          role: 'SUPER,USER',
          search: searchData,
        }
      }).then((res) => {
        setListMembers(res.data.data.docs); 
        setTotalRecords(res.data.data.totalDocs);  
        setPagingCounter(res.data.data.pagingCounter);
      }).catch((err) => {
        // toast.error("Something went wrong.", {position:"top-center"}); 
      }).finally(() => {
        setLoading(false);
      });  
    }

    const handlePageChange = ({ selected }) => { 
      setCurrentPage(selected);
    };
    const pageCount = Math.ceil(totalRecords / itemsPerPage);

    function inputSearchHandler(event) {
      setSearch(event.target.value);  
    }
    const onSearchClicked = () => {
      setSearchData(search);
    }

    const listCountryMethod = () => {
      API.get("/staff/country/list/active").then((res) => {
        setListCountry(res.data.data); 
      }).catch((err) => {
        // toast.error("Something went wrong.", {position:"top-center"}); 
      }); 
    }

    // AUTO MEMBER ADD
    const [formAuto, setFormAuto] = useState({
      userType: '',
      name: '',
      email: '',
      mobile: '',
      role: '',
      country: '',
      password: '',
      zone: ''
    });

    function inputHandlerAuto(event) {
      const { name, value } = event.target;    
      setFormAuto({
        ...formAuto,
        [name]: value, 
      }); 
    }

    const addAutoMemberMethod = () => {
      if(formAuto.userType === "SELF") {
        var bodyData = {
          ...formAuto
        }
      }

      if(formAuto.userType === "INTRODUCER") {
        var bodyData = {
          ...formAuto,
          referral: manualReferal
        }
      }

      if(formAuto.userType === "PARTNER") {
        var bodyData = {
          ...formAuto,
          fromPartnerReferCode: manualReferal
        }
      }

      API.post("/staff/user/create", bodyData).then((res) => {
        closeAutoPopup();
        listMemberMethod(); 
        toast.success(res.data.message, {position:"top-center"}); 
      }).catch((err) => {
        closeAutoPopup();
        toast.error(err.response.data.message, {position:"top-center"}); 
      });  
    }


    // MANUAL MEMBER ADD
    const [formManual, setFormManual] = useState({
      referralCode: '',
      userType: '',
      name: '',
      email: '',
      mobile: '',
      role: '',
      country: '',
      password: '',
      zone: ''
    });

    function inputHandlerManual(event) {
      const { name, value } = event.target;    
      setFormManual({
        ...formManual,
        [name]: value, 
      }); 
    }

    const addManualMemberMethod = () => {
      if(formManual.userType === "SELF") {
        var bodyData = {
          ...formManual
        }
      }

      if(formManual.userType === "INTRODUCER") {
        var bodyData = {
          ...formManual,
          referral: manualReferal
        }
      }

      if(formManual.userType === "PARTNER") {
        var bodyData = {
          ...formManual,
          fromPartnerReferCode: manualReferal
        }
      } 

      API.post("/staff/user/create", bodyData).then((res) => {
        closeAutoPopup();
        listMemberMethod(); 
        toast.success(res.data.message, {position:"top-center"}); 
      }).catch((err) => {
        closeAutoPopup();
        toast.error(err.response.data.message, {position:"top-center"}); 
      });  

      closeManualPopup();
    }


    // DELETE MEMBER
    const deleteMemberMethod = () => { 
      const id = singleMember.referralCode;
      API.delete("/staff/user/delete/permanent", {data: { search: id }}).then((res) => {
        listMemberMethod(); 
        toast.success(res.data.message, {position:"top-center"}); 
      }).catch((err) => {
        toast.error(err.response.data.message, {position:"top-center"}); 
      });  
    }


    useEffect(() => {
      pagePermissionMethod(); 
      listCountryMethod();
    }, []); 

    useEffect(() => {
      listMemberMethod();
    }, [currentPage, searchData]);


  return (
    <>
  <section id="sidebar" className={sidebarHidden ? 'hide' : ''}> 
    <a href="#" className="brand">
      <img src={profilepng} alt="Profile Image" className="profile-image" />
      Support
    </a> 

    <ul className="side-menu">
      {pagePermission.some(el => el === 'dashboard') && 
      <li>
        <a href='#' className="active" onClick={()=>{navigate('/dashboard')}} >
          <i className="fas fa-tachometer-alt icon"></i> Dashboard
        </a>
      </li>}

      <li className="divider" data-text="main">
        Main
      </li>

       {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Manage Request----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'ManageRequests') && 
        <a href="#" >
          <i className="fas fa-folder icon fa-fw"></i> Manage Requests
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverification')}}>
              <i className="fas fa-id-card"></i> KYC Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderverification')}}>
              <i className="fas fa-briefcase"></i> Trader Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'recharge') && 
          <li>
            <a href="#" onClick={()=>{navigate('/recharge')}}>
              <i className="fas fa-phone"></i> Recharge Request
            </a> 
          </li>}

          {pagePermission.some(el => el === 'c2c') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2c')}}>
              <i className="fas fa-exchange-alt"></i> C2C Request
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputesattlement') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputesattlement')}}> 
              <i className="fas fa-ban"></i> Dispute Settlement
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'investotp') &&
      <li>
        <a href='#' onClick={()=>{navigate('/investotp')}}>
          <i className="fas fa-fingerprint icon fa-fw"></i>Invest Via OTP
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {/* --@@@@@@@@@@@@@@@@@@@@@@@@---View Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

     
      <li>
        {pagePermission.some(el => el === 'ViewReports') && 
        <a href="#" >
          <i className="fas fa-file-alt icon fa-fw"></i> View Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverifyedreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverifyedreport')}}>
              <i className="fas fa-id-card"></i> KYC Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderreport')}}>
              <i className="fas fa-briefcase"></i> Trader Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'rechargereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/rechargereport')}}>
              <i className="fas fa-donate"></i> Recharge Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'c2creport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2creport')}}>
              <i className="fas fa-money-check-alt"></i> C2C Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputereport')}}>
              <i className="fas fa-handshake"></i> Dispute Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'utrreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/utrreport')}}>
              <i className="fas fa-money-check"></i> UTR Status Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'purchaseReport') && 
          <li>
            <a href="#"onClick={()=>{navigate('/purchaseReport')}}>
              <i className="fas fa-gift"></i> Purchase Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'partnerreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/partnerreport')}}>
              <i className="fas fa-handshake"></i> Partner Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'ViewReports') && 
          <li>
            <a href="#" onClick={()=>{navigate('/noninvestedreport')}}>
              <i className="fas fa-file-alt"></i> Non Invested Report
            </a>
          </li>}

        </ul>
      </li>


    {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Send Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'SendReports') && 
        <a href="#" >
          <i className="fas fa-paper-plane icon fa-fw"></i> Send Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'sendecharge') && 
          <li>
            <a href='#' onClick={()=>{navigate('/sendecharge')}}>
              <i className="fas fa-file-alt"></i> Coin2Cash
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'memberManage') &&
      <li>
        <a href="#" onClick={()=>{nav('/memberManage')}} >
          <i className="fas fa-users icon fa-fw"></i> Manage Members
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'blockuser') &&
      <li>
        <a href="#" onClick={()=>{nav('/blockuser')}} >
          <i className="fas fa-user-circle icon fa-fw"></i> Block User
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'enquirymanage') &&
      <li>
        <a href="#" onClick={()=>{navigate('/enquirymanage')}}>
          <i className="fas fa-briefcase icon fa-fw"></i> Manage Enquiry
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'update') &&
      <li>
        <a href="#" onClick={()=>{navigate('/update')}}>
          <i className="fas fa-tools icon fa-fw"></i> Update Manager
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}
      
      {pagePermission.some(el => el === 'ChatSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/ChatSupport')}}>
          <i className="fas fa-comment-alt icon fa-fw"></i>Chat Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'TicketSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/TicketSupport')}}>
          <i className="fas fa-chalkboard-teacher icon fa-fw"></i>Ticket Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'feedback') &&
      <li>
        <a href='#' onClick={()=>{navigate('/feedback')}}> 
          <i className="fas fa-comment-medical icon fa-fw"></i>Feedbacks
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      <li className="divider" data-text="Exit">
        Exit
      </li>

      <div className="ads">
        <button className="Btn" onClick={()=>{navigate('/loginform')}}>
          <div className="sign">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text">Logout</div>
        </button>
      </div>
    </ul> 
  </section> 


{/* MAIN CONTENT */}
  <section id="content">
    <nav>
      <i className="bx bx-menu toggle-sidebar" onClick={handleToggleSidebar}></i>
      <form action="#">
        <div className="form-group">
          <span>Session Time: {formatTime(sessionTimer)}</span>
        </div>
      </form>
       
      <div className='notify'>
       <i className="fas fa-bell"></i>
     </div>


      <span className="divider"></span>
{/* --@@@@@@@@@@@@@@@@@@@@@@@@---Profile----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 
      <div className="profile">
        <img
          src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-File.png"
          onClick={handleProfileClick}
          alt="Profile"
        />
        <ul className="profile-link">
          <li>
            <a href="" onClick={()=>{navigate('/selfprofile')}}>
              <i className="bx bxs-user-circle icon"></i> Profile
            </a>
          </li>
 {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Logout----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 

          <li>
            <a href="#" onClick={()=>{navigate('/loginform')}}>
              <i className="bx bxs-log-out-circle"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>

        <main>
          {/* {props.passedComponent} */}
 
          
    <section className="Manage-Member-Management-container">

{/* @@@@@@@@@@@@@@@@@@@@_____top Heading____@@@@@@@@@@@@@@@@@@@@@ */}

    <div className="Manage-Member-mange-top-heading">
    <h1><i className='bx bx-user'></i>Manage Member</h1>
    </div>


{/* @@@@@@@@@@@@@@@@@@@@_____top filler search, back btn, date____@@@@@@@@@@@@@@@@@@@@@ */}

 {/* Back Button */}
    <div className="Manage-Member-mange-Fillter-bar"> 


{/* Search Bar */}
<form className="Manage-Member-form">
    <button type='button' onClick={() => {onSearchClicked()}}>
      <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
        <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
    </button>

    <input 
      className="Manage-Member-input" 
      placeholder="Search Member ID/Email ID/ Mobile No." 
      Manual-modal-required type="text" 
      value={search}
      onChange={inputSearchHandler}
      onKeyDown={onSearchClicked}
    />

    <button className="reset" type="button" onClick={()=>setSearch('')}>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
      </svg>
    </button>
  </form>



  {/* enable the button only when the checkbox is checked  */}


<div>

{pagePermission.some(el => el === 'memberManageBtnAddVip') &&
  <button className='Manage-Member-common-btn manual-add'onClick={()=>{setManualPopupOpen(true);}}>Add VIP ID</button>
}

{pagePermission.some(el => el === 'memberManageBtnAddNormal') &&
  <button className='Manage-Member-common-btn auto-add'onClick={()=>{setAutoPopupOpen(true);}}>Add Normal ID</button>
}

{pagePermission.some(el => el === 'memberManageBtnView') &&
<button
className={`Manage-Member-custom-btn approve ${!isCheckboxChecked ? 'disabled' : ''}`}
onClick={() => {
  if (isCheckboxChecked) {
    navigate('/userinfo', {state: {searchId: singleMember.referralCode, userId: singleMember._id}}); 
  }
}}
disabled={!isCheckboxChecked}
>
View
</button>}

{pagePermission.some(el => el === 'memberManageBtnDelete') &&
<button
  className={`Manage-Member-custom-btn edit ${!isCheckboxChecked ? 'disabled' : ''}`}
  onClick={() => {
    if (isCheckboxChecked) {
      setRejectPopupOpen(true);
    }
  }}
  disabled={!isCheckboxChecked}>Delete</button>}

</div>
</div>

    <table>
      <thead>
        <tr>
          <th>
            Check 
            {/* <input type="checkbox"onChange={(e) => setIsCheckboxChecked(e.target.checked)}/> */}
          </th>
          <th>Sl No.</th>
          <th>Member ID </th>
          <th>Name</th>
          <th>Email ID</th>
          <th>Mobile</th>
          <th>Country</th>
          <th>Role</th>
          <th>Remaining Time</th>
          <th>Reference ID</th>
        </tr>
      </thead>

      <tbody>
      {loading && <LoadingSpinner />}
      {listMembers.map((data, index) => {
      return(
        <tr key={index}>
          <td>
            <input 
              type="checkbox"
              onChange={(e) => setIsCheckboxChecked(e.target.checked)} 
              onClick={()=> setSingleMember(data)}
            />
          </td>
          <td>{index+pagingCounter}</td>
          <td>{data.referralCode}</td>
          <td>{data.name}</td>
          <td>{data.email}</td>
          <td>{data.mobile}</td>
          <td>{data.country}</td>
          <td>{data.role}</td>
          <td>{data.createdSince ? `${data.createdSince.dayCount} Days`: ''}</td>
          <td>{data.userType}</td>
        </tr>
       )})}

      </tbody>
    </table>
    <Pagination 
      pageCount={pageCount} 
      onPageChange={handlePageChange}    
    />

    {/*----##################-------- All POPUPS HERE -----------################--*/}



  {/*----#####----Manual popup--------#####--*/}

    {isManualPopupOpen && (
<>
  <div id="popupModal" className="Manual-modal">
    <div className="Manual-modal-content">
      <span className="Manual-close" onClick={closeManualPopup}>&times;</span>
      <h2>Add VIP ID</h2>
      <div className="Manual-modal-form-container">
        <form id="Manual-modal-bankForm">
          <div className="Manual-modal-form-row">

            <div className="Manual-modal-left-box">
              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="QUALIFICATION"><i className="fas fa-user Manual-modal-icon"></i> Member ID<span className="Manual-modal-required">*</span></label>
                <input 
                  className="mem-model-input" 
                  type="tel" id="QUALIFICATION" Manual-modal-required 
                  name="referralCode"
                  onChange={inputHandlerManual}
                  required
                />
              </div>
    
              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="membershipType"><i className="fas fa-globe Manual-modal-icon"></i> Country:<span className="Manual-modal-required">*</span></label>
                <select 
                  id="membershipType" 
                  name="country"
                  onChange={inputHandlerManual}
                  required
                >
                  <option value="">--Select Select--</option>
                  {listCountry && listCountry.map((item, idx) => {
                  return(
                  <option key={idx} value={item.country}>{item.country}</option>
                  )})}                 
                </select>
              </div>

              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="membershipType"><i className="fas fa-globe Manual-modal-icon"></i> Zone:<span className="Manual-modal-required">*</span></label>
                <select 
                  id="membershipType" 
                  name="zone"
                  onChange={inputHandlerManual}
                  required
                >
                  <option value="">--Select Zone--</option>
                  <option value="PUBLIC">PUBLIC</option>
                  <option value="PRIVATE">PRIVATE</option>
                </select>
              </div>

              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="membershipType"><i className="fas fa-globe Manual-modal-icon"></i> Role:<span className="Manual-modal-required">*</span></label>
                <select 
                  id="membershipType" 
                  name="role"
                  onChange={inputHandlerManual}
                  required
                >
                  <option value="">--Select Role--</option>
                  <option value="USER">User</option>
                  <option value="SUPER">Super</option>
                </select>
              </div>


              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="membershipType"><i className="fas fa-globe Manual-modal-icon"></i> Refarance:<span className="Manual-modal-required">*</span></label>
                <select 
                  id="membershipType" 
                  name="userType"
                  onChange={inputHandlerManual}
                  required
                >
                  <option value="">--Select --</option>
                  <option value="SELF">Self</option>
                  <option value="INTRODUCER">Introducer</option>
                  <option value="PARTNER">Partner</option>
                </select>
              </div>

            </div>

            {/* RIGHT BOX INPUT FIELDS */}
            <div className="right-box">

              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="OrderNumber"><i className="fas fa-user Manual-modal-icon"></i> Member Name<span className="Manual-modal-required">*</span></label>
                <input 
                  className="mem-model-input" 
                  type="text" id="OrderNumber" Manual-modal-required 
                  name="name"
                  onChange={inputHandlerManual}
                  required
                />
              </div>

             
              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="OrderNumber"><i className="fas fa-phone Manual-modal-icon"></i> Mobile<span className="Manual-modal-required">*</span></label>
                <input 
                  className="mem-model-input" 
                  type="tel" id="OrderNumber" Manual-modal-required 
                  name="mobile"
                  onChange={inputHandlerManual}
                  required
                />
              </div>

              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="slNo"><i className="fas fa-envelope Manual-modal-icon"></i> Email ID:<span className="Manual-modal-required">*</span></label>
                <input 
                  className="mem-model-input" 
                  type="email" id="slNo" Manual-modal-required 
                  name="email"
                  onChange={inputHandlerManual}
                  required
                />
              </div>


              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="DESIGNATION"><i className="fas fa-lock Manual-modal-icon"></i>Password<span className="Manual-modal-required">*</span></label>
                <input 
                  className="mem-model-input" 
                  type="password" id="DESIGNATION" Manual-modal-required 
                  name="password"
                  onChange={inputHandlerManual}
                  required
                />
              </div>
              
              {formManual?.userType === "INTRODUCER" && 
              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="QUALIFICATION"><i className="fas fa-user Manual-modal-icon"></i> Referral Code<span className="Manual-modal-required">*</span></label>
                <input 
                  className="mem-model-input" 
                  type="tel" id="QUALIFICATION" Manual-modal-required 
                  name="referral"
                  onChange={(e)=> setManualReferal(e.target.value)}
                  required
                />
              </div>}

              {formManual?.userType === "PARTNER" && 
              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="QUALIFICATION"><i className="fas fa-user Manual-modal-icon"></i> Partner Code<span className="Manual-modal-required">*</span></label>
                <input 
                  className="mem-model-input" 
                  type="tel" id="QUALIFICATION" Manual-modal-required 
                  name="referral"
                  onChange={(e)=> setManualReferal(e.target.value)}
                  required
                />
              </div>}
            
            </div>
          </div>

          <button 
            type="button" 
            className="Manual-modal-submit-button" 
            // onClick={closeManualPopup}
            onClick={()=> {addManualMemberMethod()}}
          >Add</button>
        </form>
      </div>
    </div>
  </div>
</>
)}


  {/*----#####----Auto popup--------#####--*/}

  {isAutoPopupOpen && (
<>
  <div id="popupModal" className="Manual-modal">
    <div className="Manual-modal-content">
      <span className="Manual-close" onClick={closeAutoPopup}>&times;</span>
      <h2>Add Normal ID</h2>
      <div className="Manual-modal-form-container">
        <form id="Manual-modal-bankForm">
          <div className="Manual-modal-form-row">

            <div className="Manual-modal-left-box">
              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="OrderNumber"><i className="fas fa-user Manual-modal-icon"></i> Member Name<span className="Manual-modal-required">*</span></label>
                <input 
                  className="mem-model-input" 
                  type="text" id="OrderNumber" Manual-modal-required 
                  name="name"
                  onChange={inputHandlerAuto}
                  required
                />
              </div>

              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="slNo"><i className="fas fa-envelope Manual-modal-icon"></i> Email ID:<span className="Manual-modal-required">*</span></label>
                <input 
                  className="mem-model-input" type="email" id="slNo" Manual-modal-required 
                  name="email"
                  onChange={inputHandlerAuto}
                  required
                />
              </div>
             
              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="membershipType"><i className="fas fa-globe Manual-modal-icon"></i> Country:<span className="Manual-modal-required">*</span></label>
                <select 
                  id="membershipType" 
                  name="country"
                  onChange={inputHandlerAuto}
                  required
                >
                  <option value="">--Select Select--</option>
                  {listCountry && listCountry.map((item, idx) => {
                  return(
                  <option key={idx} value={item.country}>{item.country}</option>
                  )})}
                </select>
              </div>

              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="membershipType"><i className="fas fa-globe Manual-modal-icon"></i> Zone:<span className="Manual-modal-required">*</span></label>
                <select 
                  id="membershipType"
                  name="zone"
                  onChange={inputHandlerAuto}
                  required
                >
                  <option value="">--Select Zone--</option>
                  <option value="PUBLIC">PUBLIC</option>
                  <option value="PRIVATE">PRIVATE</option> 
                </select>
              </div>

              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="membershipType"><i className="fas fa-globe Manual-modal-icon"></i> Refarance:<span className="Manual-modal-required">*</span></label>
                <select 
                  id="membershipType" 
                  name="userType"
                  onChange={inputHandlerAuto}
                  required
                >
                  <option value="">--Select --</option>
                  <option value="SELF">Self</option>
                  <option value="INTRODUCER">Introducer</option>
                  <option value="PARTNER">Partner</option>
                </select>
              </div>

            </div>
            
            {/* RIGHT INPUT BOX */}
            <div className="right-box">             
              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="OrderNumber"><i className="fas fa-phone Manual-modal-icon"></i> Mobile<span className="Manual-modal-required">*</span></label>
                <input 
                  className="mem-model-input" type="tel" id="OrderNumber" Manual-modal-required 
                  name="mobile"
                  onChange={inputHandlerAuto}
                  required
                />
              </div>

              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="DESIGNATION"><i className="fas fa-lock Manual-modal-icon"></i>Password<span className="Manual-modal-required">*</span></label>
                <input 
                  className="mem-model-input" 
                  type="Password" id="DESIGNATION" Manual-modal-required 
                  name="password"
                  onChange={inputHandlerAuto}
                  required
                />
              </div>

              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="membershipType"><i className="fas fa-globe Manual-modal-icon"></i> Role:<span className="Manual-modal-required">*</span></label>
                <select 
                  id="membershipType"
                  name="role"
                  onChange={inputHandlerAuto}
                  required
                >
                  <option value="">--Select Role--</option>
                  <option value="USER">User</option>
                  <option value="SUPER">Super</option> 
                </select>
              </div>

              {formAuto?.userType === "INTRODUCER" && 
              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="QUALIFICATION"><i className="fas fa-user Manual-modal-icon"></i> Referral Code<span className="Manual-modal-required">*</span></label>
                <input 
                  className="mem-model-input" 
                  type="tel" id="QUALIFICATION" Manual-modal-required 
                  name="referral"
                  onChange={(e)=> setManualReferal(e.target.value)}
                  required
                />
              </div>}

              {formAuto?.userType === "PARTNER" && 
              <div className="Manual-modal-input-group">
                <label className="Manual-modal-label-element" htmlFor="QUALIFICATION"><i className="fas fa-user Manual-modal-icon"></i> Partner Code<span className="Manual-modal-required">*</span></label>
                <input 
                  className="mem-model-input" 
                  type="tel" id="QUALIFICATION" Manual-modal-required 
                  name="referral"
                  onChange={(e)=> setManualReferal(e.target.value)}
                  required
                />
              </div>}
            
            </div>
          </div>

          <button 
            type="button" className="Manual-modal-submit-button" 
            onClick={()=> {addAutoMemberMethod()}}
          >Add</button>
        </form>
      </div>
    </div>
  </div>
</>
)}



{/*----##################-------- All Top BUTTONS POPUPS HERE -----------################--*/}


{/*--@@@@@@@@@@@@@@  Accept button Popup @@@@@@@@@--*/}









{/*--@@@@@@@@@@@@@@ Reject Reason @@@@@@@@@--*/}
{isRejectPopupOpen && (
<>
  <div className="Manage-Member-overlay"></div>
  <div className="Manage-Member-popup">
    <div className="popup-content">
      <h2>Reason For Rejection</h2>
      {/* Replace the image with a textarea */}
      <textarea
        className="rejection-reason-textarea"
        placeholder="Type here..........."
        // You may want to bind this textarea to a state variable to capture the input
      ></textarea>
      <button className="Manage-Member-close-btn" onClick={closeRejectionPopup}>
        <i className="fas fa-times"></i>
      </button>
    </div>
    <button className="Manage-Member-done-btn"onClick={()=>{setRejectSubmitPopupOpen(true); setRejectPopupOpen(false); deleteMemberMethod();}} >
      Submit
    </button>
  </div>
</>
)}



{/*--@@@@@@@@@@@@@@ Reject Submit popup @@@@@@@@@--*/}

{isRejectSubmitPopupOpen && (
<>
  <div className="Manage-Member-overlay"></div>
  <div className="Manage-Member-popup">

    <div class="Manage-Member-Accept-popup-content">
    <img src={Rejectsub} alt="View Front Image" width="100" height="100" />
        <h2 style={{color:'red'}}> Request Rejected</h2>
       
        <button className="Manage-Member-close-btn" onClick={closeRejectSubmitPopup}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>

</>
)}




  </section>

        </main>
      </section>

      {timeoutPop && <TimeoutPop
       setTimeoutPop={setTimeoutPop}
     />}

    </>

  );
};

export default MemberManage ;
