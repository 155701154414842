import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import '../Chat_Queue/Queue_Chat.css';
import tableprofile from '../image/profile.jpeg';

import API from '../../../api/Api';
import SocketIO from '../../../api/IO';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from '../../../Componets/LoadingSpinner/LoadingSpinner'; 
import Pagination from '../../../Componets/Pagination/Pagination';
import moment from 'moment'; 
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";


const QueueChat = () => {
    const editor = useRef(null);
	const [content, setContent] = useState('');
    const [rightTab, setRightTab] = useState(1); 
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);  
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    // const [listPendingChat, setListPendingChat] = useState([]);
    const [singleChat, setSingleChat] = useState([]);
    // const [singleChat2, setSingleChat2] = useState([]);

    const [search, setSearch] = useState(null);
    const [searchData, setSearchData] = useState(null); 
    const [socketAllChat, setSocketAllChat] = useState([]);
    const queryClient = useQueryClient(); 

    const [pagePermission, setPagePermission] = useState([]); 
    const pagePermissionMethod = () => { 
      API.get("/staff/account/role/allow-pages").then((res) => {
        setPagePermission(res.data.data); 
      }).catch((err) => { 
      }).finally(() => {
      });  
    }

    const listChatMethod = async () => {
        const res = await API.get("/staff/chat/pending/list", {
          params: { 
            page: currentPage+1,
            limit: itemsPerPage,
            search: searchData,
          }
        })
        setTotalRecords(res.data.data.totalDocs); 
        return res.data.data.docs;
    }

    const { isLoading, error, data: listPendingChat } = useQuery({ 
        queryKey: ['listPendingChat', currentPage, searchData],     //singleChat2
        queryFn: listChatMethod,
    });

    const handlePageChange = ({ selected }) => { 
        setCurrentPage(selected);
    };
    const pageCount = Math.ceil(totalRecords / itemsPerPage);

    function inputSearchHandler(event) {
        setSearch(event.target.value);  
    }
    const onSearchClicked = () => {
        setSearchData(search);
        setSearch(null);
    }

    // const listAllChatMethod = (id) => {
    //     setLoading2(true);
    //     SocketIO.on('/chat/support/message/all', (res) => { 
    //       setSocketAllChat(res.data); 
    //       setLoading2(false);
    //     });
    //     SocketIO.emit('/chat/support/message/all', { chatSupportRequestId: id }); 
    //     return () => {
    //         SocketIO.disconnect();
    //     };
    // }

    // useEffect(() => {
    //     console.log(singleChat?._id);
    //     setLoading2(true);
    //     SocketIO.on('/chat/support/message/all', (res) => { 
    //        console.log(res);
    //       setSocketAllChat(res.data); 
    //       setLoading2(false);
    //     });

    //     SocketIO.emit('/chat/support/message/all', { chatSupportRequestId: singleChat?._id }); 
    
    //     return () => {
    //       SocketIO.disconnect();
    //     };
    // }, [singleChat]);


    const listAllChatMutation = useMutation({
        mutationFn: (id) => {
          setLoading2(true);
          SocketIO.on('/chat/support/message/all', (res) => { 
            setSocketAllChat(res.data); 
          });
      
          SocketIO.emit('/chat/support/message/all', { chatSupportRequestId: id }); 
        },
        onSuccess: (res)=> { 
          queryClient.invalidateQueries({ queryKey: ['listPendingChat'] });
          toast.success(res.data.message, {position:"top-center"});
          setLoading2(false);
        },
        onError: (err)=> {
          toast.error(err.response.data.message, {position:"top-center"});  
        }
    });

    const handleListAllChat = (id) => {
        // listAllChatMethod(id);
        listAllChatMutation.mutate(id); 
    };



    const sendChatMethod = (id) => {
        setLoading2(true);
        SocketIO.on('/chat/support/message/send', (res) => { 
          // listAllChatMethod(id); 
          listAllChatMutation.mutate(id); 
          setContent('');
          setLoading2(false); 
        });
    
        SocketIO.emit('/chat/support/message/send', { chatSupportRequestId: id, message: content }); 
    }

    const resolveChatMethod = (id) => {
        const body = {
          id: id,
        }
        API.post("/taff/chat/resolve", body).then((res) => {
          // listAllChatMethod(id);
          listAllChatMutation.mutate(id); 
          toast.success(res.data.message, {position:"top-center"});
        }).catch((err) => {
          toast.error("You do not have the permission to access this resource.", {position:"top-center"}); 
        });
        setRightTab(1);
    }

    // useEffect(() => {
    //     listChatMethod();
    // }, [currentPage, searchData]); 

    useEffect(() => {
        pagePermissionMethod();
    }, []); 


    useEffect(() => {
        SocketIO.on('/chat/support/message/receive', (res) => { 
            // setSingleChat2(res.data);
            // listAllChatMethod(res.data.chatSupportRequestId);
            listAllChatMutation.mutate(res.data.chatSupportRequestId);
        });
        return () => {
          SocketIO.disconnect(); 
        };
    }, []);


return (
<>
<div className="QueueChat-container-2" style={{minHeight: '740px'}}>
                <div className="QueueChat-main-box-1">
                    <div className="QueueChat-box-top-title">
                        <h2>In Queue</h2>
                        <div className="QueueChat-inp-box">
                            <input 
                                type="search" 
                                name="search" 
                                id="search" 
                                placeholder="Search People"
                                value={search}
                                onChange={inputSearchHandler}
                                onKeyDown={onSearchClicked}
                            />

                            <i 
                                className="fa fa-search"
                                style={{marginTop: '7px'}}
                                onClick={() => {onSearchClicked()}}
                            ></i>
                        </div>
                    </div>

                    <div className="QueueChat-main-content-box">
                        {isLoading && <LoadingSpinner />}
                        {listPendingChat?.map((data, index) => {
                        return(
                        <div 
                            className="QueueChat-content-box" 
                            style={{cursor:'pointer'}}
                            key={index} 
                            onClick={()=> {setSingleChat(data); setRightTab(2); handleListAllChat(data._id)}}
                        >
                            <div className="QueueChat-content-timeline-box">
                                <p>{moment(data.createdAt).format('h:mm A')}</p>
                            </div>

                            <div className="QueueChat-content-img-box">
                                <img src={data.user.kyc.userSelfie} alt="photo"/>
                            </div>

                            <div className="QueueChat-content-title-box">
                                <h3>{data.user.name}</h3>
                                {/* <p>{data.chatSupportMessage?.message}</p> */}
                                <div dangerouslySetInnerHTML={{ __html: data.chatSupportMessage?.message}}></div>
                            </div>

                            {data.chatSupportMessage?.sender === 'USER' && 
                            <div className="QueueChat-content-nunber-box" style={{position: 'relative', left:'9.4rem'}}>
                                <span className="QueueChat-span">1</span>
                            </div>}
                        </div>
                        )})}

                        {/* <div className="QueueChat-content-box">
                            <div className="QueueChat-content-timeline-box">
                                <p>2:12 am</p>
                            </div>
                            <div className="QueueChat-content-img-box">
                                <img src={tableprofile}alt="#"/>
                            </div>
                            <div className="QueueChat-content-title-box">
                                <h3>Sam</h3>
                                <p>Lorem ipsum dolor sit amet consectetor.</p>
                            </div>
                            <div className="QueueChat-content-nunber-box">
                                <span className=" QueueChat-span">1</span>
                            </div>
                        </div> */}           
                    </div>
                    
                    <Pagination 
                        pageCount={pageCount} 
                        onPageChange={handlePageChange}    
                    />

                    {/* <div className="QueueChat-Pagination-box">
                        <a href="#"><i className="fa fa-angle-double-left"></i></a>
                        <a href="#">1</a>
                        <a href="#">2</a>
                        <a href="#">3</a>
                        <a href="#">4</a>
                        <a href="#">5</a>
                        <a href="#"><i className="fa fa-angle-double-right"></i></a>
                    </div> */}
                </div>


                {/* RIGHT TAB CHAT */}
                {rightTab === 2 && 
                <div className="QueueChat-main-box-2"> 
                    <div className="chart-header-box">
                        <div className="chart-top-header-box-1">
                            <img src={singleChat.user.kyc.userSelfie} alt="photo"/>
                            <div className="chart-top-title-box">
                                <h3>{singleChat.user.name}</h3>
                                <span><i className="fas fa-envelope"></i> {singleChat.user.email}</span> 
                            </div>
                        </div>
                        <div className="chart-header-box-2">
                            <span><i className="fas fa-address-card"></i> {singleChat.user.referralCode}</span>
                        </div>

                        <div className="chart-header-box-3">
                            {pagePermission.some(el => el === 'ChatSupportBtnResolve') &&
                                <button onClick={()=> {resolveChatMethod(singleChat._id)}}>
                                    <i className="fa fa-check"></i> Resolve
                                </button>
                            }
                        </div>
                    </div>

                    <div className="chart-bottom-header-box">
                        <div className="chart-bottom-header-box-1">
                            <h3>Chat</h3>
                        </div>

                        <div className="chart-bottom-header-box-2">
                            <button onClick={()=> {navigate('/tradeprofile', {state: {searchId: singleChat.user.referralCode, userId: singleChat.user._id}})}}>
                                <i className="fa fa-user-circle"></i> Trader Profile
                            </button>

                            <button onClick={()=>{navigate('/userinfo', {state: {searchId: singleChat.user.referralCode, userId: singleChat.user._id}})}}>
                                <i className="fa fa-user"></i> User info
                            </button>
                        </div>
                    </div>

                    <div className="chart-main-container">
                        {loading2 && <LoadingSpinner />}
                        {socketAllChat?.map((data, index) => {
                        return(
                        <>
                        <div className="chart-main-title-box">
                            <h4 style={{fontSize:'14px'}}>{moment(data.createdAt).format('DD-MM-YYYY')}</h4>
                        </div>

                        {data?.sender === 'USER' &&
                        <div className="left-chart-main-box">
                            <div className="chart-main-img-box">
                                <img src={singleChat.user.kyc?.userSelfie} alt="photo"/>
                                <span 
                                className="QueueChat-span"
                                style={{color: '#444', fontSize:'14px'}}
                                >{moment(data.createdAt).format('h:mm A')}</span> 
                            </div>
                            <div className="chart-main-subtitle-box">
                                {/* <p>{data.message}</p>  */}
                                <div dangerouslySetInnerHTML={{ __html: data.message}}></div>
                                {data.media && <img src={data.media} alt="doc" className='doc-media-img'/>} 
                            </div>
                        </div>}

                        {data?.sender === 'STAFF' &&
                        <div className="right-chart-main-box">
                            <div className="chart-main-subtitle-box">
                                {/* <p>TTTLorem ipsum dolor sit amet consectetur.</p> */}
                                <div dangerouslySetInnerHTML={{ __html: data.message}}></div>
                            </div>
                            <div className="chart-main-img-box">
                                <img src={tableprofile} alt="#"/>
                                <span 
                                className=" QueueChat-span"
                                style={{color: '#444', fontSize:'14px'}}
                                >{moment(data.createdAt).format('h:mm A')}</span>
                            </div>
                        </div>}
                        </>
                        )})}

                        {/* <div className="left-chart-main-box">
                            <div className="chart-main-img-box">
                                <img src={tableprofile}alt="#"/>
                                <span className=" QueueChat-span">2:12</span>
                            </div>
                            <div className="chart-main-subtitle-box">
                                <p>Lorem ipsum dolor sit amet consectetur.</p>
                            </div>
                        </div> */}
                    </div>

                    <div className="card-footer">
                        <div className="message-editor-container">
                            <div className="message-editor">
                                <JoditEditor
                                    ref={editor}
                                    value={content}
                                    tabIndex={1}
                                    onBlur={newContent => setContent(newContent)}
                                    onChange={newContent => {}}
                                />
                            </div>

                            <div className="send-button-container">
                                {pagePermission.some(el => el === 'ChatSupportBtnSend') &&
                                    <button className="input-group-text send_btn" onClick={()=> {sendChatMethod(singleChat._id)}}>
                                        <i className="fas fa-location-arrow"></i>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>}

                {rightTab === 1 && 
                <div className="QueueChat-main-box-2" style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#F5FEFF'}}> 
                    <div style={{position:'relative', bottom:'6.5rem'}}>
                        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                            <i class="fa-regular fa-message" style={{fontSize:'2rem'}}></i>
                            <p style={{fontWeight:'500'}}>No Chat Selected</p>
                            <p style={{fontSize:'14px'}}>Select Chat</p>
                        </div>
                    </div>
                </div>}
</div>
</>
);
};

export default QueueChat;