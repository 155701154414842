import React, { useState } from 'react';
import '../Transaction/Transaction.css'; 
// import './Transaction.css'; 
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import NetwokLogo from '../Member-Profile/img/2 555554565 copy.png'
import TxnRecords from '../Member-Profile/img/Money bag (1).png'
import TransferRecords from '../Member-Profile/img/Transaction.png'
import WalletRecords from '../Member-Profile/img/Money bag (1).png'
import '../../Assets/style.css';
import profilepng from '../../Assets/Login-img/Component 1.png';

import SelfTransfer from '../../Componets/Transaction/Txn-Records/Self-Transfer/Selfransfer';
import TxnTransfer from '../../Componets/Transaction/Txn-Records/Transfer/Transferrecords';
import TransferRecord from '../../Componets/Transaction/Transfer-Records/Transfer';
import WalletDeposit from '../../Componets/Transaction/Wallet-Record/Wallet_Deposit/Depositwallet'; 
import WalletTransfer from '../../Componets/Transaction/Wallet-Record/Wallet_Transfer/Wallettransfer'; 

import API from '../../api/Api';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '../Pagination/Pagination';
import { getAuthToken, getTokenDuration } from '../../util/Auth';
import { SessionTimer } from '../../util/UtilMethod';
import TimeoutPop from '../TimeoutPop/TimeoutPop';


const Transaction = () => {
  const navigate = useNavigate();

    // TOKEN EXPIRATION 
    const [timeoutPop, setTimeoutPop] = useState(false);  
    const token = getAuthToken();
    useEffect(() => {
      if(!token) { 
        navigate('/loginform');
        return; 
      }
      if (token === 'EXPIRED') {
        navigate('/loginform');
        return; 
      }
      const tokenDuration = getTokenDuration(); 
      setTimeout(() => {
        navigate('/loginform'); 
      }, tokenDuration);     // 1*60*60*1000   
    }, [token]);  
  
    useEffect(() => {
      const tokenDuration = getTokenDuration(); 
      const timer = setTimeout(() => {
        setTimeoutPop(true);
      }, (tokenDuration - 15*60*1000));
      return () => clearTimeout(timer);
    }, []);
    // TOKEN EXPIRATION END

  const location = useLocation();  
  const [userReference, setUserRefrence] = useState(location.state?.searchId); 
  const [userId, setUserId] = useState(location.state?.userId); 

  const [sidebarHidden, setSidebarHidden] = useState(false);
  const [allDropdown, setAllDropdown] = useState([]);

  // State to manage session time
  const [sessionTime, setSessionTime] = useState(0);

  const nav = useNavigate ();

  useEffect(() => {
    setAllDropdown(document.querySelectorAll('#sidebar .side-dropdown'));
  }, []);



  useEffect(() => {
    // Attach event listeners for dropdown elements 
    allDropdown.forEach((dropdown) => {
      const parentLink = dropdown.closest('li');
      parentLink.addEventListener('click', (e) => handleMenuClick(e, dropdown));
    });

    // Set an interval to update the session time every second
    const timerInterval = setInterval(() => {
      setSessionTime((prevTime) => prevTime + 1);
    }, 1000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(timerInterval);

      // Remove event listeners
      allDropdown.forEach((dropdown) => {
        const parentLink = dropdown.closest('li');
        parentLink.removeEventListener('click', (e) => handleMenuClick(e, dropdown));
      });
    };
  }, [allDropdown]);

  const handleDropdownClick = (e, dropdown) => {
    e.preventDefault();

    const isDropdownActive = e.currentTarget.classList.contains('active');

    // Close all dropdowns
    allDropdown.forEach((item) => {
      const aLink = item.parentElement.querySelector('a:first-child');
      aLink.classList.remove('active');
      item.classList.remove('show');
    });

    // Toggle the clicked dropdown
    e.currentTarget.classList.toggle('active', !isDropdownActive);
    dropdown.classList.toggle('show', !isDropdownActive);
  };

  const handleToggleSidebar = () => {
    setSidebarHidden((prevSidebarHidden) => !prevSidebarHidden);
  };

  const handleProfileClick = () => {
    const profileLink = document.querySelector('.profile-link');
    profileLink.classList.toggle('show');
  };

  const handleMenuClick = (e, dropdown) => {
    e.preventDefault();
    dropdown.classList.toggle('show');
  };

  // Convert seconds to HH:MM:SS format
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const pad = (num) => (num < 10 ? '0' + num : num);

    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  };


  // API DATA //////////////////////////////////////
  const sessionTimer = SessionTimer();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pagingCounter, setPagingCounter] = useState(1);

  const [mainTab, setMainTab] = useState(1); 
  const [innerTab, setInnerTab] = useState(1);
  const [inner2Tab, setInner2Tab] = useState(1); 
  const [userDetail, setUserDetail] = useState([]);
  const [listTxnSelf, setListTxnSelf] = useState([]); 
  const [listTxnTransfer, setListTxnTransfer] = useState([]); 
  const [listTransferRecord, setListTransferRecord] = useState([]); 
  const [listWalletDeposit, setListWalletDeposit] = useState([]); 
  const [listWalletTransfer, setListWalletTransfer] = useState([]); 

  const [pagePermission, setPagePermission] = useState([]); 
  const pagePermissionMethod = () => { 
    API.get("/staff/account/role/allow-pages").then((res) => {
      setPagePermission(res.data.data); 
    }).catch((err) => { 
    }).finally(() => {
    });  
  }

  const userDetailMethod = () => {
    API.get("/staff/user/get", {
      params: { search: userReference }
    }).then((res) => {
      setUserDetail(res.data.data); 
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }); 
  }

  const listTxnSelfMethod = () => {
    setLoading(true);
    API.get("/staff/statement/list/transfer", {
      params: { 
        search: userReference,
        page: currentPage+1,
        limit: itemsPerPage,
      }
    }).then((res) => {
      setListTxnSelf(res.data.data.docs); 
      setTotalRecords(res.data.data.totalDocs);
      setPagingCounter(res.data.data.pagingCounter);
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }).finally(() => {
      setLoading(false);
    }); 
  }

  const listTxnTransferMethod = () => {
    setLoading(true);
    API.get("/staff/statement/list/investment", {
      params: { 
        search: userReference,
        page: currentPage+1,
        limit: itemsPerPage,
      }
    }).then((res) => {
      setListTxnTransfer(res.data.data.docs); 
      setTotalRecords(res.data.data.totalDocs);
      setPagingCounter(res.data.data.pagingCounter);
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }).finally(() => {
      setLoading(false);
    }); 
  }

  const listTransferRecordMethod = () => {
    setLoading(true);
    API.get("/staff/statement/list/buy", {
      params: { 
        search: userReference,
        page: currentPage+1,
        limit: itemsPerPage,
      }
    }).then((res) => {
      setListTransferRecord(res.data.data.docs);  
      setTotalRecords(res.data.data.totalDocs);
      setPagingCounter(res.data.data.pagingCounter);
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }).finally(() => {
      setLoading(false);
    }); 
  }

  const listWalletDepositMethod = () => {
    setLoading(true);
    API.get("/staff/market/user/crypto/deposit/list", {
      params: { 
        userId: userId,
        page: currentPage+1,
        limit: itemsPerPage,
      }
    }).then((res) => {
      setListWalletDeposit(res.data.data.docs); 
      setTotalRecords(res.data.data.totalDocs);
      setPagingCounter(res.data.data.pagingCounter);
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }).finally(() => {
      setLoading(false);
    }); 
  }

  const listWalletTransferMethod = () => {
    setLoading(true);
    API.get("/staff/market/user/crypto/transfer/list", {
      params: { 
        userId: userId,
        page: currentPage+1,
        limit: itemsPerPage,
      }
    }).then((res) => {
      setListWalletTransfer(res.data.data.docs); 
      setTotalRecords(res.data.data.totalDocs);
      setPagingCounter(res.data.data.pagingCounter);
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }).finally(() => {
      setLoading(false);
    }); 
  }

    const handlePageChange = ({ selected }) => { 
      setCurrentPage(selected);
    };
    const pageCount = Math.ceil(totalRecords / itemsPerPage);

    useEffect(() => {
      pagePermissionMethod(); 
    }, []); 

    useEffect(() => {
      userDetailMethod(); 
    }, []);

    useEffect(() => {
      {mainTab === 1 && innerTab === 1 && listTxnSelfMethod();}
      {mainTab === 1 && innerTab === 2 && listTxnTransferMethod();}
      {mainTab === 2 && listTransferRecordMethod();}
      {mainTab === 3 && inner2Tab === 1 && listWalletDepositMethod();}
      {mainTab === 3 && inner2Tab === 2 && listWalletTransferMethod();}
    }, [currentPage, mainTab, innerTab, inner2Tab]); 
 

  return (
  <>
  <section id="sidebar" className={sidebarHidden ? 'hide' : ''}> 
    <a href="#" className="brand">
      <img src={profilepng} alt="Profile Image" className="profile-image" />
      Support
    </a> 

    <ul className="side-menu">
      {pagePermission.some(el => el === 'dashboard') && 
      <li>
        <a href='#' className="active" onClick={()=>{navigate('/dashboard')}} >
          <i className="fas fa-tachometer-alt icon"></i> Dashboard
        </a>
      </li>}

      <li className="divider" data-text="main">
        Main
      </li>

       {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Manage Request----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'ManageRequests') && 
        <a href="#" >
          <i className="fas fa-folder icon fa-fw"></i> Manage Requests
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverification')}}>
              <i className="fas fa-id-card"></i> KYC Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderverification')}}>
              <i className="fas fa-briefcase"></i> Trader Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'recharge') && 
          <li>
            <a href="#" onClick={()=>{navigate('/recharge')}}>
              <i className="fas fa-phone"></i> Recharge Request
            </a> 
          </li>}

          {pagePermission.some(el => el === 'c2c') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2c')}}>
              <i className="fas fa-exchange-alt"></i> C2C Request
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputesattlement') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputesattlement')}}> 
              <i className="fas fa-ban"></i> Dispute Settlement
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'investotp') &&
      <li>
        <a href='#' onClick={()=>{navigate('/investotp')}}>
          <i className="fas fa-fingerprint icon fa-fw"></i>Invest Via OTP
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {/* --@@@@@@@@@@@@@@@@@@@@@@@@---View Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

     
      <li>
        {pagePermission.some(el => el === 'ViewReports') && 
        <a href="#" >
          <i className="fas fa-file-alt icon fa-fw"></i> View Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverifyedreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverifyedreport')}}>
              <i className="fas fa-id-card"></i> KYC Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderreport')}}>
              <i className="fas fa-briefcase"></i> Trader Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'rechargereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/rechargereport')}}>
              <i className="fas fa-donate"></i> Recharge Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'c2creport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2creport')}}>
              <i className="fas fa-money-check-alt"></i> C2C Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputereport')}}>
              <i className="fas fa-handshake"></i> Dispute Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'utrreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/utrreport')}}>
              <i className="fas fa-money-check"></i> UTR Status Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'purchaseReport') && 
          <li>
            <a href="#"onClick={()=>{navigate('/purchaseReport')}}>
              <i className="fas fa-gift"></i> Purchase Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'partnerreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/partnerreport')}}>
              <i className="fas fa-handshake"></i> Partner Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'ViewReports') && 
          <li>
            <a href="#" onClick={()=>{navigate('/noninvestedreport')}}>
              <i className="fas fa-file-alt"></i> Non Invested Report
            </a>
          </li>}

        </ul>
      </li>


    {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Send Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'SendReports') && 
        <a href="#" >
          <i className="fas fa-paper-plane icon fa-fw"></i> Send Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'sendecharge') && 
          <li>
            <a href='#' onClick={()=>{navigate('/sendecharge')}}>
              <i className="fas fa-file-alt"></i> Coin2Cash
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'memberManage') &&
      <li>
        <a href="#" onClick={()=>{nav('/memberManage')}} >
          <i className="fas fa-users icon fa-fw"></i> Manage Members
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'blockuser') &&
      <li>
        <a href="#" onClick={()=>{nav('/blockuser')}} >
          <i className="fas fa-user-circle icon fa-fw"></i> Block User
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'enquirymanage') &&
      <li>
        <a href="#" onClick={()=>{navigate('/enquirymanage')}}>
          <i className="fas fa-briefcase icon fa-fw"></i> Manage Enquiry
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'update') &&
      <li>
        <a href="#" onClick={()=>{navigate('/update')}}>
          <i className="fas fa-tools icon fa-fw"></i> Update Manager
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}
      
      {pagePermission.some(el => el === 'ChatSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/ChatSupport')}}>
          <i className="fas fa-comment-alt icon fa-fw"></i>Chat Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'TicketSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/TicketSupport')}}>
          <i className="fas fa-chalkboard-teacher icon fa-fw"></i>Ticket Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'feedback') &&
      <li>
        <a href='#' onClick={()=>{navigate('/feedback')}}> 
          <i className="fas fa-comment-medical icon fa-fw"></i>Feedbacks
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      <li className="divider" data-text="Exit">
        Exit
      </li>

      <div className="ads">
        <button className="Btn" onClick={()=>{navigate('/loginform')}}>
          <div className="sign">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text">Logout</div>
        </button>
      </div>
    </ul> 
  </section> 


{/* MAIN CONTENT */}
  <section id="content">
    <nav>
      <i className="bx bx-menu toggle-sidebar" onClick={handleToggleSidebar}></i>
      <form action="#">
        <div className="form-group">
          <span>Session Time: {formatTime(sessionTimer)}</span>
        </div>
      </form>
       
      <div className='notify'>
       <i className="fas fa-bell"></i>
     </div>


      <span className="divider"></span>
{/* --@@@@@@@@@@@@@@@@@@@@@@@@---Profile----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 
      <div className="profile">
        <img
          src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-File.png"
          onClick={handleProfileClick}
          alt="Profile"
        />
        <ul className="profile-link">
          <li>
            <a href="" onClick={()=>{navigate('/selfprofile')}}>
              <i className="bx bxs-user-circle icon"></i> Profile
            </a>
          </li>
 {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Logout----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 

          <li>
            <a href="#" onClick={()=>{navigate('/loginform')}}>
              <i className="bx bxs-log-out-circle"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>

        <main>
          {/* {props.passedComponent} */}
          <div className="Transation-main-container">

{/* <!--@@@@@@@@@@@@@@__________ TOP____________@@@@@@@@@@@@@@@@@@@@@--> */}

<div className="Transation-page-top-updated">
  <span><img src={NetwokLogo} alt="This is an image"/></span><h1>Transation</h1>
</div>

<div className="Transation-page-main-table">


{/* <!--@@@@@@@@@@@@@@@@@@@@@@@_________ BUTTONS ____________@@@@@@@@@@@@@@@@@@@@@@@@@@@-->  */}

  <div className="Transation-page-title-button">

    {/* <!--_________ BACK BUTTON____________--> */}

    <section className="Transation-page-back_btn-updated">
      <div><a href="#" onClick={()=>{navigate('/userinfo', {state: {searchId: userReference, userId: userId}} )}}><span><i className="fa fa-chevron-left"></i></span>Back</a></div>
  </section>

   {/* <!--_________ TOP TAB BUTTON____________--> */}

    <div className="Transation-page-TOP-Transation-TAB-buttons">
        <button className="Transation-TAB-button-1" onClick={()=> setMainTab(1)}><img src={TxnRecords} alt="3"/>Txn. Records</button>
        <button className="Transation-TAB-button-1" onClick={()=> setMainTab(2)}><img src={TransferRecords} alt="#"/>Transfer Records</button>
        <button className="Transation-TAB-button-1" onClick={()=> setMainTab(3)}><img src={WalletRecords} alt="#"/>Wallet Records</button>
    </div>   
  
  </div>

{/* <!--@@@@@@@@@@@@@@@@@@@@@@@_________ MAIN TABLE CONTENT ____________@@@@@@@@@@@@@@@@@@@@@@@@@@@-->  */}

<div className="Transation-table-content">
  {mainTab === 1 && innerTab === 1 && <SelfTransfer  
    listTxnSelf = {listTxnSelf}
    loading = {loading}
    pagingCounter = {pagingCounter}
    setInnerTab = {setInnerTab}
  />}

  {mainTab === 1 && innerTab === 2 && <TxnTransfer
    listTxnTransfer = {listTxnTransfer}
    loading = {loading}
    pagingCounter = {pagingCounter}
    setInnerTab = {setInnerTab}
  />}

  {mainTab === 2 && <TransferRecord 
    listTransferRecord = {listTransferRecord}
    userDetail = {userDetail}
    loading = {loading}
    pagingCounter = {pagingCounter}
  />}

  {mainTab === 3 && inner2Tab === 1 && <WalletDeposit 
    listWalletDeposit = {listWalletDeposit}
    loading = {loading}
    pagingCounter = {pagingCounter}
    setInner2Tab = {setInner2Tab}
  />}

  {mainTab === 3 && inner2Tab === 2 && <WalletTransfer 
    listWalletTransfer = {listWalletTransfer}
    loading = {loading}
    pagingCounter = {pagingCounter}
    setInner2Tab = {setInner2Tab}
  />}

</div>

  <Pagination 
    pageCount={pageCount} 
    onPageChange={handlePageChange}    
  />



{/* <!--@@@@@@@@@@@@@@@@@@@@@@@_________Transation-pagination ____________@@@@@@@@@@@@@@@@@@@@@@@@@@@-->    */}

{/* <div className="Transation-Table-buttom">
<div className="Transation-Tshowing-10-results-of-46" >
 <span>
  <span className="Transation-Tshowing-10-results-of-46-span">Showing </span>
     <span className="Transation-Tshowing-10-results-of-46-span2">10</span>
 <span className="Transation-Tshowing-10-results-of-46-span3"> Results of 46</span>
 </span>
</div>
 
<div className="Transation-pagination">
 <a href="#">«</a>
 <a className="active" href="#">1</a>
 <a href="#">2</a>
 <a href="#">3</a>
 <a href="#">4</a>
 <a href="#">5</a>
 <a href="#">»</a>
</div>
</div> */}

</div>
</div>
          
        </main>
  </section>

    {timeoutPop && <TimeoutPop
       setTimeoutPop={setTimeoutPop}
     />}
   



        </>
    );
}

export default Transaction;
