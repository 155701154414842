// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.up-red {
    color: red !important;
}

.succss-img {
    width: 320px;
    z-index: 2000;
    position: absolute;
    left: 42rem;
    top: 24rem;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Invest_Otp/InvestOtp.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,UAAU;AACd","sourcesContent":[".up-red {\r\n    color: red !important;\r\n}\r\n\r\n.succss-img {\r\n    width: 320px;\r\n    z-index: 2000;\r\n    position: absolute;\r\n    left: 42rem;\r\n    top: 24rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
