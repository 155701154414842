import React from 'react';
import '../Wallet_Transfer/Wallet_Transfer.css'; 
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';
import moment from 'moment';

const Wallettransfer = ({listWalletTransfer, loading, pagingCounter, setInner2Tab}) => { 
  return (
   <>
     <div class="wallet-transfer-table-content">     
             <div class="wallet-transfer-head-button">
                 <button class="wallet-transfer-head-button-1" onClick={()=> setInner2Tab(1)}>Deposit</button>
                 <button class="wallet-transfer-head-button-2" onClick={()=> setInner2Tab(2)}>Transfer</button>
             </div>

             <div class="wallet-transfer-table-content-1">
                 <table>
                     <thead>
                         <tr>
                           <th>Sl.</th>
                            <th>Asset</th>
                             <th>Volume</th>
                             <th>USDT</th>
                             <th>Transaction Hash</th>
                             <th>Date</th>
                             <th>Time</th>
                            <th>Status</th>     
                         </tr>
                     </thead>

                     <tbody>
                        {loading && <LoadingSpinner />}
                        {listWalletTransfer.map((data, index) => {
                        return(
                         <tr key={index}>
                           <td>{index+pagingCounter}</td>  
                           <td>
                             <div class="stock-info">
                                 <img src={data.cryptoSymbolLogo} alt="Stock Image"/>
                                 <span>{data.cryptoName}</span>
                             </div>
                         </td>
                             <td>{parseFloat(data.amount).toFixed(2)}</td>
                             <td>{parseFloat(data.USDTPrice).toFixed(2)}</td>
                             <td>{data.txHash ? data.txHash : '--'}</td>
                             <td>{moment(data.createdAt).format('DD-MM-YYYY')}</td>
                             <td>{moment(data.createdAt).format('hh:mm:ss')}</td>
                             <td>
                                <span 
                                class={`${data.status === 'PENDING' ? 'red-td' : 'Green-td'}`}
                                >{data.status}</span>
                            </td>
                         </tr>
                        )})}    
                     </tbody>
                 </table>
             </div>
     
           </div>
     
           </>
  );
};

export default Wallettransfer;
