import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../User-Profile/User-Profile.css'
import profileepic from '../img/Ellipse 60.svg';

import profilepng from '../../../Assets/Login-img/Component 1.png';
import '../../Purchase-Record/Purchase_Records.css'
import '../../../Assets/style.css';
import { useEffect } from 'react'; 

import API from '../../../api/Api';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Pagination from '../../Pagination/Pagination';
import moment from 'moment';
import { SessionTimer } from '../../../util/UtilMethod';
import { getAuthToken, getTokenDuration } from '../../../util/Auth'; 
import TimeoutPop from '../../TimeoutPop/TimeoutPop';

const Userprofile = () =>{
    const navigate = useNavigate(); 

  // TOKEN EXPIRATION  
  const [timeoutPop, setTimeoutPop] = useState(false);  
  const token = getAuthToken();
  useEffect(() => {
    if(!token) { 
      navigate('/loginform');
      return; 
    }
    if (token === 'EXPIRED') {
      navigate('/loginform');
      return; 
    }
    const tokenDuration = getTokenDuration();  
    setTimeout(() => {
      navigate('/loginform'); 
    }, tokenDuration);     // 1*60*60*1000   
  }, [token]);  

  useEffect(() => {
    const tokenDuration = getTokenDuration(); 
    const timer = setTimeout(() => {
      setTimeoutPop(true);
    }, (tokenDuration - 15*60*1000));
    return () => clearTimeout(timer);
  }, []);
  // TOKEN EXPIRATION END

    const location = useLocation();
    const [userReference, setUserRefrence] = useState(location.state?.searchId); 
    const [userId, setUserId] = useState(location.state?.userId); 

    const [sidebarHidden, setSidebarHidden] = useState(false);
    const [allDropdown, setAllDropdown] = useState([]);
  
    // State to manage session time
    const [sessionTime, setSessionTime] = useState(0);
  
    const nav = useNavigate ();
  
    useEffect(() => {
      setAllDropdown(document.querySelectorAll('#sidebar .side-dropdown'));
    }, []);
  
  
  
    useEffect(() => {
      // Attach event listeners for dropdown elements
      allDropdown.forEach((dropdown) => {
        const parentLink = dropdown.closest('li');
        parentLink.addEventListener('click', (e) => handleMenuClick(e, dropdown));
      });
  
      // Set an interval to update the session time every second
      const timerInterval = setInterval(() => {
        setSessionTime((prevTime) => prevTime + 1);
      }, 1000);
  
      // Clean up the interval on component unmount
      return () => {
        clearInterval(timerInterval);
  
        // Remove event listeners
        allDropdown.forEach((dropdown) => {
          const parentLink = dropdown.closest('li');
          parentLink.removeEventListener('click', (e) => handleMenuClick(e, dropdown));
        });
      };
    }, [allDropdown]);
  
    const handleDropdownClick = (e, dropdown) => {
      e.preventDefault();
  
      const isDropdownActive = e.currentTarget.classList.contains('active');
  
      // Close all dropdowns
      allDropdown.forEach((item) => {
        const aLink = item.parentElement.querySelector('a:first-child');
        aLink.classList.remove('active');
        item.classList.remove('show');
      });
  
      // Toggle the clicked dropdown
      e.currentTarget.classList.toggle('active', !isDropdownActive);
      dropdown.classList.toggle('show', !isDropdownActive);
    };
  
    const handleToggleSidebar = () => {
      setSidebarHidden((prevSidebarHidden) => !prevSidebarHidden);
    };
  
    const handleProfileClick = () => {
      const profileLink = document.querySelector('.profile-link');
      profileLink.classList.toggle('show');
    };
  
    const handleMenuClick = (e, dropdown) => {
      e.preventDefault();
      dropdown.classList.toggle('show');
    };
  
    // Convert seconds to HH:MM:SS format
    const formatTime = (seconds) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
  
      const pad = (num) => (num < 10 ? '0' + num : num);
  
      return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
    };


    // API DATA //////////////////////////////////////
    const sessionTimer = SessionTimer();
    const [userDetail, setUserDetail] = useState([]);
    const [updateData, setUpdateData] = useState([]);
    const [editInput, setEditInput] = useState(null);

    const [pagePermission, setPagePermission] = useState([]); 
    const pagePermissionMethod = () => { 
      API.get("/staff/account/role/allow-pages").then((res) => {
        setPagePermission(res.data.data); 
      }).catch((err) => { 
      }).finally(() => {
      });  
    }

    const userDetailMethod = () => {
      API.get("/staff/user/get", {
        params: { search: userReference }
      }).then((res) => {
        setUserDetail(res.data.data); 
      }).catch((err) => {
        // toast.error("Something went wrong.", {position:"top-center"}); 
      }); 
    }

    useEffect(() => { 
      const data = {
        search: userReference,
        name: userDetail.name,
        email: userDetail.email,
        mobile: userDetail.mobile,
        role: userDetail.role,
        reference: userDetail.userType, 
        country: userDetail.country,
        password: '',
      };
      setUpdateData(data);  
    }, [userDetail]);

    function inputHandler(event) {
      const { name, value } = event.target;    
      setUpdateData({
        ...updateData,
        [name]: value, 
      }); 
    }

    const updateProfileMethod = () => {
      if(updateData.password === '') {
        var body = {
          search: userReference,
          name: updateData.name,
          email: updateData.email,
          mobile: updateData.mobile,
          role: updateData.role,
        }
      }

      if(updateData.password !== '') {
        var body = {
          search: userReference,
          name: updateData.name,
          email: updateData.email,
          mobile: updateData.mobile,
          role: updateData.role,
          password: updateData.password,
        }
      }

      API.put("/staff/user/update", body).then((res) => {  
          toast.success(res.data.messge, {position:"top-center"});
          userDetailMethod(); 
        }).catch((err) => {
          toast.error(err.response.data.message, {position:"top-center"}); 
      });  
      setEditInput(null);
    }

    useEffect(() => {
      pagePermissionMethod(); 
    }, []); 

    useEffect(() => {
      userDetailMethod(); 
    }, []); 

return(
<>
<section id="sidebar" className={sidebarHidden ? 'hide' : ''}> 
    <a href="#" className="brand">
      <img src={profilepng} alt="Profile Image" className="profile-image" />
      Support
    </a> 

    <ul className="side-menu">
      {pagePermission.some(el => el === 'dashboard') && 
      <li>
        <a href='#' className="active" onClick={()=>{navigate('/dashboard')}} >
          <i className="fas fa-tachometer-alt icon"></i> Dashboard
        </a>
      </li>}

      <li className="divider" data-text="main">
        Main
      </li>

       {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Manage Request----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'ManageRequests') && 
        <a href="#" >
          <i className="fas fa-folder icon fa-fw"></i> Manage Requests
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverification')}}>
              <i className="fas fa-id-card"></i> KYC Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderverification')}}>
              <i className="fas fa-briefcase"></i> Trader Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'recharge') && 
          <li>
            <a href="#" onClick={()=>{navigate('/recharge')}}>
              <i className="fas fa-phone"></i> Recharge Request
            </a> 
          </li>}

          {pagePermission.some(el => el === 'c2c') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2c')}}>
              <i className="fas fa-exchange-alt"></i> C2C Request
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputesattlement') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputesattlement')}}> 
              <i className="fas fa-ban"></i> Dispute Settlement
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'investotp') &&
      <li>
        <a href='#' onClick={()=>{navigate('/investotp')}}>
          <i className="fas fa-fingerprint icon fa-fw"></i>Invest Via OTP
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {/* --@@@@@@@@@@@@@@@@@@@@@@@@---View Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

     
      <li>
        {pagePermission.some(el => el === 'ViewReports') && 
        <a href="#" >
          <i className="fas fa-file-alt icon fa-fw"></i> View Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverifyedreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverifyedreport')}}>
              <i className="fas fa-id-card"></i> KYC Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderreport')}}>
              <i className="fas fa-briefcase"></i> Trader Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'rechargereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/rechargereport')}}>
              <i className="fas fa-donate"></i> Recharge Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'c2creport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2creport')}}>
              <i className="fas fa-money-check-alt"></i> C2C Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputereport')}}>
              <i className="fas fa-handshake"></i> Dispute Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'utrreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/utrreport')}}>
              <i className="fas fa-money-check"></i> UTR Status Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'purchaseReport') && 
          <li>
            <a href="#"onClick={()=>{navigate('/purchaseReport')}}>
              <i className="fas fa-gift"></i> Purchase Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'partnerreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/partnerreport')}}>
              <i className="fas fa-handshake"></i> Partner Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'ViewReports') && 
          <li>
            <a href="#" onClick={()=>{navigate('/noninvestedreport')}}>
              <i className="fas fa-file-alt"></i> Non Invested Report
            </a>
          </li>}

        </ul>
      </li>


    {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Send Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'SendReports') && 
        <a href="#" >
          <i className="fas fa-paper-plane icon fa-fw"></i> Send Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'sendecharge') && 
          <li>
            <a href='#' onClick={()=>{navigate('/sendecharge')}}>
              <i className="fas fa-file-alt"></i> Coin2Cash
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'memberManage') &&
      <li>
        <a href="#" onClick={()=>{nav('/memberManage')}} >
          <i className="fas fa-users icon fa-fw"></i> Manage Members
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'blockuser') &&
      <li>
        <a href="#" onClick={()=>{nav('/blockuser')}} >
          <i className="fas fa-user-circle icon fa-fw"></i> Block User
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'enquirymanage') &&
      <li>
        <a href="#" onClick={()=>{navigate('/enquirymanage')}}>
          <i className="fas fa-briefcase icon fa-fw"></i> Manage Enquiry
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'update') &&
      <li>
        <a href="#" onClick={()=>{navigate('/update')}}>
          <i className="fas fa-tools icon fa-fw"></i> Update Manager
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}
      
      {pagePermission.some(el => el === 'ChatSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/ChatSupport')}}>
          <i className="fas fa-comment-alt icon fa-fw"></i>Chat Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'TicketSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/TicketSupport')}}>
          <i className="fas fa-chalkboard-teacher icon fa-fw"></i>Ticket Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'feedback') &&
      <li>
        <a href='#' onClick={()=>{navigate('/feedback')}}> 
          <i className="fas fa-comment-medical icon fa-fw"></i>Feedbacks
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      <li className="divider" data-text="Exit">
        Exit
      </li>

      <div className="ads">
        <button className="Btn" onClick={()=>{navigate('/loginform')}}>
          <div className="sign">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text">Logout</div>
        </button>
      </div>
    </ul> 
  </section> 


{/* MAIN CONTENT */}
  <section id="content">
    <nav>
      <i className="bx bx-menu toggle-sidebar" onClick={handleToggleSidebar}></i>
      <form action="#">
        <div className="form-group">
          <span>Session Time: {formatTime(sessionTimer)}</span>
        </div>
      </form>
       
      <div className='notify'>
       <i className="fas fa-bell"></i>
     </div>


      <span className="divider"></span>
{/* --@@@@@@@@@@@@@@@@@@@@@@@@---Profile----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 
      <div className="profile">
        <img
          src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-File.png"
          onClick={handleProfileClick}
          alt="Profile"
        />
        <ul className="profile-link">
          <li>
            <a href="" onClick={()=>{navigate('/selfprofile')}}>
              <i className="bx bxs-user-circle icon"></i> Profile
            </a>
          </li>
 {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Logout----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 

          <li>
            <a href="#" onClick={()=>{navigate('/loginform')}}>
              <i className="bx bxs-log-out-circle"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>

        <main>
          {/* {props.passedComponent} */}
          <h1><i className="fa fa-user"></i> User Details</h1>

<div className="User-Details-main-container">
    <section className="User-Details-back_btn-updated">
        <div><a href="#" onClick={()=>{navigate('/userinfo', {state: {searchId: userReference, userId: userId}} )}}><span><i className="fa fa-chevron-left"></i></span>Back</a></div>
    </section>

<div className="User-Details-prof-pic">
  <img src={userDetail.kyc?.userSelfie} alt="image" style={{width:'220px', height:'120px'}}/>
</div>

<div className="User-Details-coloum-containers">

<div className="User-Details-sec-1">
    <div className="User-Details-coloum-1">
        <label for="memberId">Member ID</label>
        <div className="User-Details-input-container">
            <input 
              type="text" 
              id="memberId" 
              value={updateData.search} 
              disabled
            />
            {/* <i className="fa fa-pencil edit-icon"></i> */}
        </div>
    </div>

    <div className="User-Details-coloum-1">
        <label for="Mobile">Mobile</label>
        <div className="User-Details-input-container">
            <input 
              type="tel" 
              id="Mobile" 
              name='mobile'
              value={updateData.mobile} 
              onChange={inputHandler}
              disabled={editInput === 1 ? false : true}
            />
            <i className="fa fa-pencil edit-icon" onClick={()=> setEditInput(1)}></i>
        </div>
    </div>

    <div className="User-Details-coloum-1">
        <label for="userRole">Role</label>
        <div className="User-Details-dropdown-container" onClick={()=> setEditInput(5)}>
            <select 
              id="userRole" 
              name="role" 
              onChange={inputHandler}
            >
              {editInput !==5 &&
                <option value={updateData.role}>{updateData.role}</option>
              }

              {editInput === 5 &&
              <>
                <option value="">--Select Role--</option>
                <option value="USER">User</option>
                <option value="SUPER">Super</option>
              </>
              }
            </select>
        </div>
    </div>
</div>


<div className="User-Details-sec-2">


<div className="User-Details-coloum-2">
<label for="MemberName">Member Name</label>
<div className="User-Details-input-container">
    <input 
      type="text" 
      id="MemberName" 
      name='name'
      value={updateData.name} 
      onChange={inputHandler}
      disabled={editInput === 2 ? false : true} 
    />
    <i className="fa fa-pencil edit-icon" onClick={()=> setEditInput(2)}></i>
</div>
</div>


<div className="User-Details-coloum-2">
<label for="Email-ID">Email ID</label>
<div className="User-Details-input-container">
    <input 
      type="email" 
      id="Email-ID" 
      name='email'
      value={updateData.email} 
      onChange={inputHandler}
      disabled={editInput === 3 ? false : true} 
    />
    <i className="fa fa-pencil edit-icon" onClick={()=> setEditInput(3)}></i>
</div>
</div>

<div className="User-Details-coloum-2">
<label for="Password">Password</label>
<div className="User-Details-input-container">
    <input 
      type="Password" 
      id="Password" 
      placeholder='********'
      name='password'
      value={updateData.password}  
      onChange={inputHandler}
      disabled={editInput === 4 ? false : true} 
    />
    <i className="fa fa-pencil edit-icon" onClick={()=> setEditInput(4)}></i>
</div>
</div>

</div>


<div className="sec-3">


 <div className="User-Details-coloum-3">
    <label for="Country">Country</label>
    <div className="User-Details-dropdown-container">
        <select id="Country" name="Country" disabled>
            <option value={updateData.country}>{updateData.country}</option>
            {/* <option value="USA">USA</option> */}
        </select> 
    </div>
</div>

<div className="User-Details-coloum-3">
    <label for="Reference">Reference</label>
    <div className="User-Details-dropdown-container">
        <select id="Reference" name="Reference" disabled>
            <option value={updateData.reference}>{updateData.reference}</option>
            {/* <option value="">Empty</option> */}
        </select> 
    </div>
</div>

</div>

</div>

{pagePermission.some(el => el === 'userprofileBtnSave') && 
<div className="User-Details-buttom-btn">
  <button type="button" onClick={()=> updateProfileMethod()}>Save</button>
  <button type="reset">Cancel</button>
</div>}

</div>
          
        </main>
  </section>

    {timeoutPop && <TimeoutPop 
       setTimeoutPop={setTimeoutPop}
     />}
    

        </>
    );
};

export default Userprofile;