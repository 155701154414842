// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OtpSentPop {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    min-width: 400px;
    height: 365px;
    border-radius: 10px; 
    z-index: 1000;
    position: fixed;
    top: 23%;
    left: 38%; 
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.sent-text {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 12px;
}

.otp-input {
    padding: 0px 80px;
}

.otp-input::placeholder {
    color: #4B5563 !important;
}

.input-main-sent1 {
    margin-top: 70px;
}

.resend-sent {
    text-align: right;
    margin-right: 46px;
    cursor: pointer;
}

.spin-gif1 {
    width: 75px;
    z-index: 2000;
    position: absolute;
    left: 10rem;
    top: 10.3rem;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Invest_Otp/OtpSentPop.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,QAAQ;IACR,SAAS;IACT,mBAAmB;IACnB,qCAAqC;AACzC;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB","sourcesContent":[".OtpSentPop {\r\n    display: flex;\r\n    flex-direction: column;\r\n    max-width: 900px;\r\n    min-width: 400px;\r\n    height: 365px;\r\n    border-radius: 10px; \r\n    z-index: 1000;\r\n    position: fixed;\r\n    top: 23%;\r\n    left: 38%; \r\n    background: #ffffff;\r\n    box-shadow: 0px 4px 4px 0px #00000040;\r\n}\r\n\r\n.sent-text {\r\n    text-align: center;\r\n    margin-top: 25px;\r\n    margin-bottom: 12px;\r\n}\r\n\r\n.otp-input {\r\n    padding: 0px 80px;\r\n}\r\n\r\n.otp-input::placeholder {\r\n    color: #4B5563 !important;\r\n}\r\n\r\n.input-main-sent1 {\r\n    margin-top: 70px;\r\n}\r\n\r\n.resend-sent {\r\n    text-align: right;\r\n    margin-right: 46px;\r\n    cursor: pointer;\r\n}\r\n\r\n.spin-gif1 {\r\n    width: 75px;\r\n    z-index: 2000;\r\n    position: absolute;\r\n    left: 10rem;\r\n    top: 10.3rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
