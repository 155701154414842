import React from 'react';
import '../Level_ROI/Level_Table.css'; 
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner'; 
import moment from 'moment';

const LevelTable = ({listIncomeRight, loading}) => {
    return (
      <div className="Level-table-content-2">
        <div className="Level-Table-updated">
          <table className="Level-page-table"> 
            <thead>
              <tr>
                <th>S.No.</th>  
                <th>Name</th>
                <th>Member ID</th>
                <th>Invst. Amt</th>
                <th>Hand</th>
                <th>Invest Date &Time</th> 
                <th>L-ROI</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {loading && <LoadingSpinner />}
              {listIncomeRight?.map((data, index) => {
              return( 
              <tr key={index}>
                <td>{index+1}</td>
                <td>{data.investedId.user.name}</td> 
                <td>{data.investedId.user.referralCode}</td>
                <td>{parseFloat(data.investedId.amount).toFixed(2)}</td>
                <td>{data.hand}</td>
                <td>{moment(data.createdAt).format('DD-MM-YYYY')} {moment(data.createdAt).format('hh:mm A')}</td>
                <td>{parseFloat(data.incomeAmount).toFixed(2)}</td>
                <td>
                  <span className={`
                    ${data.status === 'ACTIVE' && 'Workin-Income-TABLE-green-cercle'}
                    ${data.status === 'INACTIVE' && 'Workin-Income-TABLE-red-cercle'}
                    ${data.status === 'DELETED' && 'Workin-Income-TABLE-yellows-cercle'} 
                  `}></span> 
                </td>
              </tr>
              )})}

            </tbody>
          </table>

          {/* <div className="Table-buttom">
            <select name="">
              <option value="#">10/Pages</option>
              <option value="#">1</option>
              <option value="#">2</option>
              <option value="#">3</option>
              <option value="#">4</option>
              <option value="#">5</option>
              <option value="#">6</option>
              <option value="#">7</option>
              <option value="#">8</option>
              <option value="#">9</option>
              <option value="#">10</option>
            </select>
            <p>
              <a href="#"><i className="fa fa-chevron-left"></i></a>1 of 1<a href="#"><i className="fa fa-chevron-right"></i></a>
            </p>
          </div> */}


        </div>
      </div>
    );
  }


export default LevelTable;
