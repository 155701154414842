// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
 /*@@@@@@@@@@@@--INSIDE THE TABLE 02 CONTENT---------@@@@@@@@ */
 
 .Sell-Trade-TABLE-Table-updated {
  

     width: 100%;
     position: relative;
     border-radius: 10px;
     /* height: calc(100vh - 380px); */
     
 }
 
 
 
 /* @@@@@@@@@@--------TABLE----------@@@@@@@@@@ */
 .Sell-Trade-TABLE-Tpage-table {
     width: 100%;
     border-collapse: collapse;
     margin-top: 20px;
     
 }
 
 .Sell-Trade-TABLE-Tpage-table th, .Sell-Trade-TABLE-Tpage-table td {
     padding: 12px;
     border: 1px solid #ddd;
 }
 
 .Sell-Trade-TABLE-Tpage-table th {
     background-color: #153361;
     color: #fff;
     position: sticky;
     top: 0;
     z-index: 1; /* Ensure the header stays on top of other elements */
 }
 
 .Sell-Trade-TABLE-Tpage-table tr:nth-child(even) {
     background-color: #E6F7FF;
 }

 .Sell-Trade-TABLE-Tpage-table td:last-child {
    background-color: #efffe6;
    font-weight: bold;
    color: rgb(0, 150, 27);
}
 
 .Sell-Trade-TABLE-Tpage-table tr:hover {
     background-color: #ddd;
 }
 
 .Sell-Trade-TABLE-Tpage-table td {
     text-align: center;
 }
 
 

 
 
 `, "",{"version":3,"sources":["webpack://./src/Componets/Trade-Report/Sell-Trade/Sell-Trade.css"],"names":[],"mappings":";CACC,8DAA8D;;CAE9D;;;KAGI,WAAW;KACX,kBAAkB;KAClB,mBAAmB;KACnB,iCAAiC;;CAErC;;;;CAIA,gDAAgD;CAChD;KACI,WAAW;KACX,yBAAyB;KACzB,gBAAgB;;CAEpB;;CAEA;KACI,aAAa;KACb,sBAAsB;CAC1B;;CAEA;KACI,yBAAyB;KACzB,WAAW;KACX,gBAAgB;KAChB,MAAM;KACN,UAAU,EAAE,qDAAqD;CACrE;;CAEA;KACI,yBAAyB;CAC7B;;CAEA;IACG,yBAAyB;IACzB,iBAAiB;IACjB,sBAAsB;AAC1B;;CAEC;KACI,sBAAsB;CAC1B;;CAEA;KACI,kBAAkB;CACtB","sourcesContent":["\r\n /*@@@@@@@@@@@@--INSIDE THE TABLE 02 CONTENT---------@@@@@@@@ */\r\n \r\n .Sell-Trade-TABLE-Table-updated {\r\n  \r\n\r\n     width: 100%;\r\n     position: relative;\r\n     border-radius: 10px;\r\n     /* height: calc(100vh - 380px); */\r\n     \r\n }\r\n \r\n \r\n \r\n /* @@@@@@@@@@--------TABLE----------@@@@@@@@@@ */\r\n .Sell-Trade-TABLE-Tpage-table {\r\n     width: 100%;\r\n     border-collapse: collapse;\r\n     margin-top: 20px;\r\n     \r\n }\r\n \r\n .Sell-Trade-TABLE-Tpage-table th, .Sell-Trade-TABLE-Tpage-table td {\r\n     padding: 12px;\r\n     border: 1px solid #ddd;\r\n }\r\n \r\n .Sell-Trade-TABLE-Tpage-table th {\r\n     background-color: #153361;\r\n     color: #fff;\r\n     position: sticky;\r\n     top: 0;\r\n     z-index: 1; /* Ensure the header stays on top of other elements */\r\n }\r\n \r\n .Sell-Trade-TABLE-Tpage-table tr:nth-child(even) {\r\n     background-color: #E6F7FF;\r\n }\r\n\r\n .Sell-Trade-TABLE-Tpage-table td:last-child {\r\n    background-color: #efffe6;\r\n    font-weight: bold;\r\n    color: rgb(0, 150, 27);\r\n}\r\n \r\n .Sell-Trade-TABLE-Tpage-table tr:hover {\r\n     background-color: #ddd;\r\n }\r\n \r\n .Sell-Trade-TABLE-Tpage-table td {\r\n     text-align: center;\r\n }\r\n \r\n \r\n\r\n \r\n \r\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
